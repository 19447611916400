import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { IDataWithUnparsedProperties } from "../../../queries/types";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import accountCells from "./accountCells";
import {
  ICellDictionary,
  ICustomProperties,
  IDict,
} from "../Visitor/VisitorModal";
import DialogTitle from "../../DialogTitle";
import { useApi } from "../../../queries/util";
import { getAccountRequest } from "../../../queries/orgRequests";
import { Tab, Tabs, Typography } from "@material-ui/core";
import Loading from "../../Loading";

interface IAccountModal {
  onClose: () => void;
  open: boolean;
  accountID: number;
}

const keyMapping: IDict = {
  customStrings: "Custom Strings",
  customNumbers: "Custom Numbers",
  customBooleans: "Custom Flags",
};

/**
 * A modal that shows a given account's data
 * @param {() => void} onClose method that is invoked when the modal is closed
 * @param {boolean} open Whether or not the modal is currently open
 * @param {number} accountID The accountID of the account who's details will be shown
 */
const AccountModal = (props: IAccountModal) => {
  const [selected, setSelected] = React.useState<number>(0);
  const [tab, setTab] = React.useState<number>(0);
  const {
    isLoading,
    data: accountData,
    error: accountError,
    refetch,
  } = useApi<IDataWithUnparsedProperties>(getAccountRequest(props.accountID));
  const [systemProperties, setSystemProperties] =
    React.useState<ICellDictionary>({});
  const [customProperties, setCustomProperties] =
    React.useState<ICustomProperties>();

  React.useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.accountID]);

  /**
   * Format account data whenever new data is received
   */
  React.useEffect(() => {
    if (accountData) {
      const newSystemProps: ICellDictionary = {};
      const systemProperties = Object.keys(accountCells);
      Object.keys(accountData).forEach((key) => {
        if (systemProperties.includes(key))
          newSystemProps[key] = accountCells[key];
        else if (key === "accountProperties") {
          setCustomProperties(JSON.parse(accountData[key].toString()));
        }
      });
      setSystemProperties(newSystemProps);
    }
  }, [accountData]);

  // Build the component that displays system properties
  const systemPropsComponent = Object.keys(systemProperties).map((key) => {
    if (!accountData) return null;
    const property = systemProperties[key];
    // format dates
    let value = accountData[key];
    if (accountCells[key].property_typeName === "Date")
      value = new Date(value as string).toISOString().split("T")[0];
    return (
      <DialogContentText key={`account_modal_${property.label}`}>
        <strong>
          {property.label}{" "}
          {property.helpText && (
            <Tooltip title={property.helpText}>
              <InfoIcon color="disabled" style={{ marginBottom: -7 }} />
            </Tooltip>
          )}
          :{" "}
        </strong>{" "}
        {value ? value : "N/A"}
      </DialogContentText>
    );
  });

  // Build the component that displays custom properties
  const customPropertiesComponent = customProperties ? (
    <div>
      {customProperties.customDates && (
        <div>
          <Typography variant="subtitle2" color="textSecondary">
            Custom Dates
          </Typography>
          <div style={{ marginLeft: 16, marginTop: 8 }}>
            {Object.keys(customProperties.customDates).map((key) => {
              const value = customProperties.customDates[key];
              return (
                <DialogContentText key={`visitor_modal_${key}`}>
                  <strong>{key}: </strong> {value ? value : "N/A"}
                </DialogContentText>
              );
            })}
          </div>
        </div>
      )}
      {Object.keys(customProperties).map((key) => {
        if (key === "customDates") return null;
        return (
          <div>
            <Typography variant="subtitle2" color="textSecondary">
              {keyMapping[key]}
            </Typography>
            <div style={{ marginLeft: 16, marginTop: 8 }}>
              {customProperties[key] &&
                Object.keys(customProperties[key]).map((property) => (
                  <DialogContentText key={`visitor_modal_${property}`}>
                    <strong>{property}: </strong>{" "}
                    {customProperties[key][property]
                      ? customProperties[key][property]
                      : "N/A"}
                  </DialogContentText>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;

  let currentComponent;
  switch (tab) {
    case 0:
      currentComponent = systemPropsComponent;
      break;
    case 1:
      currentComponent = customPropertiesComponent;
      break;
    default:
      currentComponent = (
        <Typography color="textSecondary" variant="body2">
          <i>Something went wrong.</i>
        </Typography>
      );
  }

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="account-modal-title"
      open={props.open}
      PaperProps={{ style: { minWidth: 400, width: "50vw" } }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DialogTitle onClose={props.onClose} id="account-modal-title">
            {accountData?.accountName} Account Properties
          </DialogTitle>
          <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(Number(newValue))}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Property category tabs"
            style={{
              marginTop: -16,
              marginBottom: 8,
            }}
          >
            <Tab
              label="System Properties"
              id="visitor-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
            <Tab
              label="Custom Properties"
              id="visitor-tab-3"
              aria-controls="full-width-tabpanel-3"
            />
          </Tabs>
          <DialogContent style={{ height: "45vh" }}>
            {currentComponent}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AccountModal;
