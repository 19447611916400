import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useUser } from "../../queries/user";
import { useQueryClient } from "react-query";
import { IUser } from "../../queries/types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1400,
    },
    title: {
      marginLeft: theme.spacing(1),
    },
    tableRow: {
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
      "& > *": {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      },
    },
    restrictionsCell: {
      maxWidth: 360,
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  })
);

interface IUserPermissionData {
  fullName: string;
  email: string;
  lastSeen: string;
  restrictions: string[];
}

const dummyUsers: IUserPermissionData[] = [
  {
    fullName: "Jackson Prowell",
    email: "jacksonprowell@gmail.com",
    lastSeen: "1/1/21 10:06 AM",
    restrictions: ["Create/edit/delete users", "Edit/hide custom properties"],
  },
  {
    fullName: "Greg Atkinson",
    email: "gregatkinson@gmail.com",
    lastSeen: "1/1/21 10:06 AM",
    restrictions: [],
  },
];

const Users = () => {
  // React query hooks to get the current user's permissions
  const { isLoading, data: userData, error: userFetchError } = useUser();
  const user = userData as IUser;
  const queryClient = useQueryClient();
  React.useEffect(() => {
    if (userFetchError) {
      queryClient.setQueryData("error", "Test error");
    }
  }, [userFetchError, queryClient]);

  // TODO: hook this up to react query
  const [userList, setUserList] = React.useState<IUserPermissionData[]>(
    dummyUsers
  );

  const classes = useStyles();

  const { restrictions } = user;

  if (restrictions && restrictions.includes("users")) {
    return (
      <Typography color="textSecondary">
        <em>You don't have permission to view this page.</em>
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h6" className={classes.title}>
            Users
          </Typography>
          <Table aria-label="Users table">
            <TableHead>
              <TableCell component="th">Full Name</TableCell>
              <TableCell component="th">Email</TableCell>
              <TableCell component="th">Last Seen</TableCell>
              <TableCell component="th">Restrictions</TableCell>
              <TableCell component="th" aria-label="Edit column"></TableCell>
            </TableHead>
            <TableBody>
              {userList.map((userData) => (
                <TableRow className={classes.tableRow}>
                  <TableCell>{userData.fullName}</TableCell>
                  <TableCell>{userData.email}</TableCell>
                  <TableCell>{userData.lastSeen}</TableCell>
                  <TableCell className={classes.restrictionsCell}>
                    {userData.restrictions.join(", ")}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit this user's permissions">
                      <IconButton color="secondary">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default Users;
