import React from "react";
import { ISegmentMetadata, ISurvey } from "../../../queries/types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useNumberInput } from "../FormHooks";
import Preview from "../../SurveyPreview";
import CriteriaBuilder from "../../Segments/CriteriaBuilder";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import DialogTitle from "../../DialogTitle";
import { useApi } from "../../../queries/util";
import { segmentsRequest } from "../../../queries/npsRequests";
import Loading from "../../Loading";

interface ICriteriaModal {
  onClose: () => void;
  open: boolean;
  criteriaID: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "95vw",
    },
  })
);

const CriteriaModal = (props: ICriteriaModal) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      classes={{ paper: classes.root }}
    >
      <DialogTitle onClose={props.onClose}>Criteria Editor</DialogTitle>
      <DialogContent>
        <CriteriaBuilder
          criteria={null}
          setLastSaved={() => null}
          showCard={false}
          disableAutoSave
          onSave={(criteria) => console.log(criteria)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IEditDisplayCriteria {
  classes: any;
  survey: ISurvey;
  updateSurvey: (survey: ISurvey) => Promise<void>;
}

/**
 * Component for the third step in the survey builder
 * Asks for criteria data
 * @param props CSS classes object and survey data
 */
const SurveyCriteriaEditor = (props: IEditDisplayCriteria) => {
  // TODO: hook this up to React Query
  const survey = props.survey;

  const [frequency, frequencyInput] = useNumberInput(
    props.classes,
    "How often should we show the survey?",
    "Days",
    survey.displayFrequencyDays
  );
  const [daysToWait, daysToWaitInput] = useNumberInput(
    props.classes,
    `How many days should we wait after a visitor selects "remind me later"?`,
    "Days",
    survey.remindMeLaterDays
  );
  const [minPages, minPagesInput] = useNumberInput(
    props.classes,
    "How many pages must a visitor view in a session before we show them the survey?",
    "Pages",
    survey.visitorSessionPages
  );
  const [percentOfUsers, percentOfUsersInput] = useNumberInput(
    props.classes,
    "What percent of your users would you like to sample?",
    "%",
    survey.samplePercent
  );

  const [criteriaRadio, setCriteriaRadio] = React.useState<number>(0);

  const {
    isLoading,
    data: criteriaList,
    error: criteriaListError,
  } = useApi<{
    segmentList: ISegmentMetadata[];
    visitorCount: { [key: string]: null };
  }>(segmentsRequest);

  const [selectedCriteria, setSelectedCriteria] = React.useState<string>(
    survey.criteriaID ? survey.criteriaID.toString() : ""
  );

  const [criteriaModalOpen, setCriteriaModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const timeoutID = setTimeout(() => {
      props.updateSurvey({
        ...survey,
        displayFrequencyDays: frequency,
        remindMeLaterDays: daysToWait,
        visitorSessionPages: minPages,
        samplePercent: percentOfUsers,
        criteriaID: Number(selectedCriteria)
          ? Number(selectedCriteria)
          : survey.criteriaID,
      });
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [frequency, daysToWait, minPages, percentOfUsers, selectedCriteria]);

  const handleNewCriteria = () => {
    setCriteriaModalOpen(true);
  };

  if (isLoading) return <Loading />;

  return (
    <div>
      <div>
        <FormControl className={props.classes.numberInputContainer}>
          <Typography
            className={props.classes.numberInputLabel}
            id="criteria-label"
          >
            Criteria
          </Typography>
          <RadioGroup
            aria-label="Criteria choice"
            name="criteria-choice"
            value={criteriaRadio}
            onChange={(e) =>
              setCriteriaRadio(Number((e.target as HTMLInputElement).value))
            }
          >
            <FormControlLabel
              value={0}
              control={<Radio style={{ alignSelf: "baseline" }} />}
              label={
                <FormControl className={props.classes.radioSelect}>
                  <InputLabel id="theme-select-label">
                    Select a segment
                  </InputLabel>
                  <Select
                    labelId="theme-select-label"
                    id="theme-select"
                    value={selectedCriteria}
                    onChange={(e) =>
                      setSelectedCriteria(e.target.value as string)
                    }
                  >
                    {/* TODO: populate this with react-query data */}
                    {criteriaList?.segmentList.map((criteriaMetadata) => (
                      <MenuItem value={criteriaMetadata.segmentID}>
                        {criteriaMetadata.segmentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              style={{ marginRight: 0 }}
            />
            <FormControlLabel
              value={1}
              control={
                <Radio aria-haspopup="true" aria-label="Create a new segment" />
              }
              label={<Typography>Create new criteria</Typography>}
              onClick={handleNewCriteria}
            />
          </RadioGroup>
        </FormControl>
        {frequencyInput}
        {daysToWaitInput}
        {minPagesInput}
        {percentOfUsersInput}
      </div>
      <Preview
        survey={{
          ...survey,
          displayFrequencyDays: frequency,
          remindMeLaterDays: daysToWait,
          visitorSessionPages: minPages,
          samplePercent: percentOfUsers,
        }}
      />
      <CriteriaModal
        open={criteriaModalOpen}
        onClose={() => setCriteriaModalOpen(false)}
        criteriaID={1}
      />
    </div>
  );
};

export default SurveyCriteriaEditor;
