import { HeadCell } from "../Components/Tables/TableHead";
import { IApi, IVisitorColumn } from "./types";

export const getAccountsRequest = (rows: number, page: number): IApi => ({
  url: `/api/v1/org/accounts?rows=${rows}&page=${page}`,
  defaultError:
    "Something went wrong while fetching accounts. Please try again later.",
  responseProp: "accounts",
  tag: "accounts",
});

export const getAccountRequest = (accountID: number): IApi => ({
  url: `/api/v1/org/accounts/${accountID}`,
  defaultError:
    "Something went wrong while fetching an account's data. Please try again later.",
  responseProp: "account",
  tag: ["account", accountID],
  useQueryParams: {
    enabled: false,
    staleTime: 2 * 60 * 60 * 1000,
  },
});

export const getAccountCountRequest = (days: number): IApi => ({
  url: `/api/v1/org/accountCount?days=${days}`,
  defaultError:
    "Something went wrong while fetching account data. Please try again later.",
  responseProp: "accountCount",
  tag: "accountCount",
});

export const getVisitorsRequest = (
  rows: number,
  page: number,
  columns: IVisitorColumn[]
): IApi => ({
  url: `/api/v1/org/visitors`,
  defaultError:
    "Something went wrong while fetching visitors. Please try again later.",
  responseProp: "visitors",
  tag: "visitors",
  requestParams: {
    method: "post",
    body: JSON.stringify({ rows, page: page + 1, properties: columns }),
    headers: {
      "Content-Type": "application/json",
    },
  },
});

export const getVisitorRequest = (visitorID: number): IApi => ({
  url: `/api/v1/org/visitors/${visitorID}`,
  defaultError:
    "Something went wrong while fetching a visitor's data. Please try again later.",
  responseProp: "visitor",
  tag: ["visitor", visitorID],
  useQueryParams: {
    enabled: false,
    staleTime: 2 * 60 * 60 * 1000,
  },
});

export const getVisitorCountRequest = (days: number): IApi => ({
  url: `/api/v1/org/visitorCount?days=${days}`,
  defaultError:
    "Something went wrong while fetching visitor data. Please try again later.",
  responseProp: "visitorCount",
  tag: "visitorCount",
});

export const orgsRequest = {
  url: "/api/v1/user/orgs",
  defaultError:
    "Something went wrong while fetching organizations. Please try again later.",
  responseProp: "orgs",
  tag: "orgs",
};
