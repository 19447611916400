import React from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SettingsIcon from "@material-ui/icons/Settings";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import MuiTooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import { useLayout } from "../../config/Styles";
import TagTable from "../../Components/NPS/TagTable";
import SegmentTable from "../../Components/NPS/SegmentTable";
import { IAccountProperty, IVisitorProperty } from "../../queries/types";
import Table from "../../Components/Tables/Table";
import { ITheme } from "../../config/Theme";
import SegmentModal from "../../Components/NPS/SegmentModal";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../queries/util";
import { getNpsScoreRequest } from "../../queries/npsRequests";
import ErrorSnackbar from "../../Components/ErrorSnackbar";
import { ResponseContext } from "../../Context/ResponseContext";
import {
  accountPropertiesRequest,
  visitorPropertiesRequest,
} from "../../queries/settingsRequests";
import { IFormattedProps } from "../../Components/Tables/ColumnEditor";
import responseCells from "../../Components/Tables/NPS/npsResponseCells";
import visitorCells from "../../Components/Tables/Visitor/visitorCells";
import accountCells from "../../Components/Tables/Account/accountCells";
import ResponseGraph from "./ResponseGraph";
import ResponseOverview from "./ResponseOverview";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.only("sm")]: {
        width: `calc(100vw - ${theme.spacing(6) + theme.drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: `calc(100vw - ${theme.spacing(6)}px)`,
      },
    },
    npsScore: {
      fontWeight: 400,
    },
    npsScoreContainer: {
      display: "flex",
      alignItems: "baseline",
      "&>*": { marginRight: theme.spacing(1) },
    },
    topRow: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: theme.spacing(3),
      maxWidth: 1000,
      [theme.breakpoints.up("xl")]: {
        maxWidth: 1200,
      },
    },
    centeredRow: {
      display: "flex",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flex: 1,
      width: "100%",
      maxWidth: 1000,
      flexDirection: "column",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: 1200,
      },
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        "& > * + *": {
          marginLeft: theme.spacing(3),
          marginTop: 0,
        },
      },
      marginBottom: theme.spacing(3),
    },
    cardTitle: {
      color: theme.palette.contrast.main,
    },
    alternatingTable: {
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
    },
    tagCell: {
      maxWidth: 120,
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    select: {
      marginLeft: theme.spacing(3),
      color: theme.palette.contrast.light,
    },
    popOutButton: {
      float: "right",
      marginTop: theme.spacing(3),
    },
    modal: {
      minWidth: 800,
      maxWidth: "90vw",
      maxHeight: "90vh",
    },
    cardTitleRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dataText: {
      minWidth: theme.spacing(8),
    },
  })
);

export interface IPlotPoint {
  name: string;
  score: number;
}

const NpsResponses = () => {
  const { columns, lastNDays, updateLastNDays } =
    React.useContext(ResponseContext);
  const [segmentModalOpen, setSegmentModalOpen] =
    React.useState<boolean>(false);
  const [timeframe, setTimeframe] = React.useState<number>(
    lastNDays ? lastNDays : 30
  );
  const classes = useStyles();
  const theme = useTheme();
  const layout = useLayout();

  const {
    isLoading: isLoadingScore,
    data: npsScore,
    error: npsScoreError,
  } = useApi<number>(getNpsScoreRequest(timeframe));

  const [error, setError] = React.useState<string | null>(null);

  const {
    isLoading: isLoadingVisitorCustomProperties,
    data: visitorCustomProperties,
    error: visitorCustomPropertiesError,
  } = useApi<IVisitorProperty[]>(visitorPropertiesRequest);
  const {
    isLoading: isLoadingAccountCustomProperties,
    data: accountCustomProperties,
    error: accountCustomPropertiesError,
  } = useApi<IAccountProperty[]>(accountPropertiesRequest);
  const [allColumns, setAllColumns] = React.useState<IFormattedProps>();

  /**
   * Format custom properties and add them to the list of all columns once they're done fetching
   */
  React.useEffect(() => {
    if (
      !isLoadingVisitorCustomProperties &&
      !isLoadingAccountCustomProperties
    ) {
      // use all system properties across all types
      let newAllColumns = {
        ...responseCells,
        ...visitorCells,
        ...accountCells,
      };
      if (visitorCustomProperties) {
        let formattedVisitorProps: IFormattedProps = {};
        visitorCustomProperties.forEach(
          (property) =>
            (formattedVisitorProps[property.visitorPropertyKey] = {
              id: property.visitorPropertyKey,
              label: property.visitorPropertyDisplay,
              property_typeName: property.property_typeName,
              isCustomProperty: true,
              customPropertyType: "visitor",
            })
        );
        newAllColumns = { ...newAllColumns, ...formattedVisitorProps };
      }
      if (accountCustomProperties) {
        let formattedAccountProps: IFormattedProps = {};
        accountCustomProperties.forEach(
          (property) =>
            (formattedAccountProps[property.accountPropertyKey] = {
              id: property.accountPropertyKey,
              label: property.accountPropertyDisplay,
              property_typeName: property.property_typeName,
              isCustomProperty: true,
              customPropertyType: "account",
            })
        );
        newAllColumns = { ...newAllColumns, ...formattedAccountProps };
      }
      setAllColumns(newAllColumns);
    }
  }, [visitorCustomProperties, accountCustomProperties]);

  React.useEffect(() => {
    if (lastNDays && timeframe !== lastNDays && updateLastNDays) {
      updateLastNDays(timeframe);
    }
  }, [timeframe]);

  /**
   * Display any api call errors
   */
  React.useEffect(() => {
    if (npsScoreError) setError(npsScoreError.message);
  }, [npsScoreError]);

  const closeSegmentModal = () => setSegmentModalOpen(false);
  return (
    <div className={classes.root}>
      {/* Options row */}
      <div className={classes.topRow}>
        <div className={classes.npsScoreContainer}>
          <Typography variant="h4" component="h1" className={classes.npsScore}>
            NPS Score:{" "}
          </Typography>
          <Typography variant="h4" className={classes.npsScore}>
            {isLoadingScore ? (
              <Skeleton className={classes.dataText} />
            ) : (
              npsScore
            )}
          </Typography>
        </div>
        <div className={classes.centeredRow}>
          <MuiTooltip title="Update NPS data">
            <IconButton color="primary">
              <RefreshIcon />
            </IconButton>
          </MuiTooltip>
          <MuiTooltip title="Settings">
            <IconButton color="primary">
              <SettingsIcon />
            </IconButton>
          </MuiTooltip>
          <FormControl variant="outlined" className={classes.select}>
            <InputLabel id="select-label">Last</InputLabel>
            <Select
              labelId="select-label"
              id="select-timeframe"
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value as number)}
              label="Last"
            >
              <MenuItem value={7}>7 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={180}>180 Days</MenuItem>
              <MenuItem value={365}>365 Days</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Graph and Overview */}
      <div className={classes.row}>
        <ResponseGraph timeframe={timeframe} />
        <ResponseOverview timeframe={timeframe} />
      </div>

      {/* Segment and Tag tables */}
      <div className={classes.row}>
        <Card className={layout.flex1} style={{ overflow: "visible" }}>
          <CardContent>
            <div className={classes.cardTitleRow}>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Segment Data
              </Typography>
              <Button color="primary">Export</Button>
            </div>
            <SegmentTable classes={classes} days={timeframe} />
          </CardContent>
        </Card>
        <Card className={layout.flex2}>
          <CardContent>
            <div className={classes.cardTitleRow}>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Tag Data
              </Typography>
              <Button color="primary">Export</Button>
            </div>

            <TagTable classes={classes} days={timeframe} />
          </CardContent>
        </Card>
      </div>

      {/* NPS Response table */}
      <div className={classes.row}>
        <Table
          headCells={columns.concat([
            {
              id: "npsTags",
              label: "Tags",
              placeholder: "",
              property_typeName: "String",
            },
          ])}
          allColumns={allColumns}
          title="NPS Responses"
          type="npsResponses"
          context={ResponseContext}
          maxWidth={"100%"}
          forceWidth
        />
      </div>
      <SegmentModal
        onClose={closeSegmentModal}
        open={segmentModalOpen}
        classes={classes}
      />
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default NpsResponses;
