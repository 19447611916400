// Page for editing or creating or editing nps surveys
import React from "react";
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useLinks } from "../config/Styles";
import { ISurvey } from "../queries/types";
import StepContent from "@material-ui/core/StepContent";
import SurveyContentEditor from "../Components/Forms/SurveyForms/SurveyContentEditor";
import SurveyThemeEditor from "../Components/Forms/SurveyForms/SurveyThemeEditor";
import SurveyCriteriaEditor from "../Components/Forms/SurveyForms/SurveyCriteriaEditor";
import { useApi } from "../queries/util";
import { getSurveyRequest } from "../queries/npsRequests";
import Loading from "../Components/Loading";
import ErrorSnackbar from "../Components/ErrorSnackbar";
import { useQueryClient } from "react-query";
import { objectsAreEqual } from "../Components/Forms/SurveyForms/deepComparison";

const steps = [
  "Edit Survey Content",
  "Set Survey Theme",
  "Configure Display Criteria",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 900,
    },
    stepper: {
      minWidth: 700,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    breadcrumbs: {
      marginBottom: theme.spacing(2),
    },
    textInput: {
      marginBottom: theme.spacing(2),
    },
    numberInputContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: theme.spacing(2),
      maxWidth: "75%",
    },
    numberInputLabel: {
      marginTop: 4,
      marginRight: theme.spacing(1),
      flex: 1,
    },
    numberInput: {
      maxWidth: 100,
    },
    rangeInput: {
      maxWidth: "50%",
      minWidth: 150,
      "&:nth-child(even)": {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(2),
    },
    scoreInput: {
      maxWidth: "50%",
      minWidth: 200,
      "&:nth-child(even)": {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(2),
    },
    rangeInputContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    headingContainer: {
      display: "flex",
      alignItems: "center",
    },
    textInputContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },

    radioSelect: {
      width: 300,
      marginRight: 0,
    },
  })
);

const getStepContent = (
  step: number,
  classes: any,
  survey: ISurvey,
  updateSurvey: (survey: ISurvey) => Promise<void>
) => {
  switch (step) {
    case 0:
      return (
        <SurveyContentEditor
          classes={classes}
          survey={survey}
          updateSurvey={updateSurvey}
        />
      );
    case 1:
      return <SurveyThemeEditor survey={survey} updateSurvey={updateSurvey} />;
    case 2:
      return (
        <SurveyCriteriaEditor
          classes={classes}
          survey={survey}
          updateSurvey={updateSurvey}
        />
      );
    default:
      return <p>Unknown step</p>;
  }
};

const EditNpsSurvey = () => {
  const queryClient = useQueryClient();
  const params = useParams<{ surveyID: string | undefined }>();
  const surveyID = Number(params.surveyID);
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  const {
    isLoading,
    data: surveyFetch,
    error: surveyError,
    statusCode,
  } = useApi<ISurvey>(getSurveyRequest(surveyID));
  const [error, setError] = React.useState<string | null>(null);
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [survey, setSurvey] = React.useState<ISurvey>();

  React.useEffect(() => {
    if (surveyError) setError(surveyError.message);
    if (surveyFetch) setSurvey(surveyFetch);
  }, [surveyError, surveyFetch]);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const classes = useStyles();
  const links = useLinks();
  const history = useHistory();

  const handleNextStep = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handlePrevStep = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const updateSurvey = async (newSurveyData: ISurvey) => {
    if (objectsAreEqual(survey, newSurveyData)) return;
    setSurvey(newSurveyData);
    const response = await fetch(`/api/v1/nps/surveys/${surveyID}`, {
      method: "put",
      body: JSON.stringify({ survey: newSurveyData }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    switch (response.status) {
      case 401:
        queryClient.invalidateQueries("user");
        break;
      case 404:
        setRedirect(true);
        break;
      case 422:
        setError(
          "The survey couldn't be saved. Please check for errors and try again."
        );
        break;
      case 200:
        queryClient.refetchQueries("queue");
        break;
      default:
        setError(
          "Something went wrong while trying to save this survey. Please try again later."
        );
    }
  };

  if (redirect || (statusCode && statusCode === 404))
    return <Redirect to="/nps-surveys" />;
  return (
    <div>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        <Link to="/nps-surveys" className={links.root}>
          NPS Surveys
        </Link>
        <Typography color="textPrimary">
          {pathname === "edit-nps-survey"
            ? "Edit NPS Survey"
            : "Create NPS Survey"}
        </Typography>
      </Breadcrumbs>
      {isLoading || !survey ? (
        <Loading />
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h6">{survey.surveyName}</Typography>
            {/* render each form in a seperate step */}
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {/* Dynamically render the survey editor component based on the current step */}
                    {getStepContent(activeStep, classes, survey, updateSurvey)}
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handlePrevStep}
                        color="secondary"
                      >
                        Back
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        // TODO: build a finish api request
                        <Button
                          color="secondary"
                          onClick={() => history.push("/nps-surveys")}
                        >
                          Finish
                        </Button>
                      ) : (
                        <Button color="secondary" onClick={handleNextStep}>
                          Next
                        </Button>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      )}
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default EditNpsSurvey;
