import React from "react";
import Criteria, { ICriteria } from "./Criteria";
import Button from "@material-ui/core/Button";
import CodeIcon from "@material-ui/icons/Code";
import useToggle from "./useToggle";
import { ICriteriaData, ICriteriaGroup } from "./CriteriaBuilder";
import { IProperty } from "./data/properties";

interface ICriteriaGroupComponent {
  classes: any;
  criteria: ICriteriaGroup;
  updateCriteria: (id: string, newCriteria: ICriteriaGroup) => void;
  deletable: boolean;
  id: string;
  delete: () => void;
  customProperties: {
    visitorProperties: IProperty[] | null;
    accountProperties: IProperty[] | null;
  };
  accountMode?: boolean;
}
const CriteriaGroup = (props: ICriteriaGroupComponent) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [deletable, setDeletable] = React.useState<boolean>(false);
  const [criteriaList, setCriteriaList] = React.useState<ICriteriaData[]>(
    props.criteria.rules
  );
  const [modified, setModified] = React.useState<boolean>(false);

  // custom hooks
  const [mainCondition, mainConditionToggle] = useToggle(
    props.classes.buttonRow,
    props.classes.toggleButtonSelected,
    deletable,
    props.delete,
    props.criteria.condition,
    props.accountMode
  );

  // Hook to save whenever the main condition or criteriaList is changed
  React.useEffect(() => {
    // props.save();
    props.updateCriteria(props.id, {
      condition: mainCondition,
      rules: criteriaList,
    });
  }, [mainCondition, criteriaList]);

  // Hook called whenever the page is rerendered
  React.useEffect(() => {
    // Determine whether or not the criteria group can be deleted
    if (criteriaList.length === 0 && props.deletable) setDeletable(true);
    else {
      if (deletable) setDeletable(false);
    }
  });

  // hook to update this group's parent's criteria list whenever a change is made
  React.useEffect(() => {}, [mainCondition, criteriaList]);

  const handleDeleteChild = (id: string) => {
    if (!modified) setModified(true);
    const clone = criteriaList.filter(
      (childCriteria) => childCriteria.id !== id
    );
    setCriteriaList(clone);
  };

  const newCondition = () => {
    if (!modified) setModified(true);
    setAnchorEl(null);
    setCriteriaList(
      criteriaList.concat([
        {
          id: `${props.id}_${Math.random()}`,
          type: "rule",
          criteria: {
            propertyGroup: null,
            property: null,
            type: null,
            rule: null,
            values: null,
          },
        },
      ])
    );
  };

  const newConditionGroup = () => {
    if (!modified) setModified(true);
    setAnchorEl(null);
    setCriteriaList(
      criteriaList.concat([
        {
          id: `${props.id}_${Math.random()}`,
          type: "group",
          criteria: { condition: "All", rules: [] },
        },
      ])
    );
  };

  const updateCriteria =
    <T extends ICriteria | ICriteriaGroup>() =>
    (id: string, newCriteria: T) => {
      const i = criteriaList.findIndex((criteria) => criteria.id === id);
      const clone = [...criteriaList];
      clone[i].criteria = newCriteria;
      setCriteriaList(clone);
    };

  React.useEffect(() => {
    if (criteriaList.length === 0 && !modified)
      setCriteriaList([
        {
          id: `${props.id}_${Math.random()}`,
          type: "rule",
          criteria: {
            propertyGroup: null,
            property: null,
            type: null,
            rule: null,
            values: null,
          },
        },
      ]);
  }, [criteriaList]);

  return (
    <div className={props.classes.builderContainer} key={props.id}>
      {mainConditionToggle}
      <div className={props.classes.conditionContainer}>
        {/* loop through the criteria list and render each criteria's form component */}
        {criteriaList?.map((criteria, i) => (
          <React.Fragment key={`${props.id}_${criteria.id}`}>
            {criteria.type === "group" ? (
              <CriteriaGroup
                classes={props.classes}
                criteria={criteria.criteria as ICriteriaGroup}
                updateCriteria={updateCriteria<ICriteriaGroup>()}
                id={criteria.id}
                deletable={true}
                delete={() => handleDeleteChild(criteria.id)}
                customProperties={props.customProperties}
                accountMode={props.accountMode}
              />
            ) : (
              <Criteria
                classes={props.classes}
                criteria={criteria.criteria as ICriteria}
                updateCriteria={updateCriteria<ICriteria>()}
                id={criteria.id}
                delete={() => handleDeleteChild(criteria.id)}
                customProperties={props.customProperties}
                accountMode={props.accountMode}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div>
        <Button
          color="primary"
          onClick={newCondition}
          variant="contained"
          style={{ color: "white", marginRight: 8, marginTop: 8 }}
        >
          Add Rule
        </Button>
        <Button
          // color=""
          startIcon={<CodeIcon />}
          onClick={newConditionGroup}
          variant="contained"
          style={{ marginTop: 8 }}
        >
          Add Group
        </Button>
      </div>
    </div>
  );
};

export default CriteriaGroup;
