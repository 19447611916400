import React from "react";
import { ISurvey, ITheme, IThemeMetadata } from "../../../queries/types";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Preview from "../../SurveyPreview";
import { useApi } from "../../../queries/util";
import { getThemeRequest, themesRequest } from "../../../queries/npsRequests";
import Loading from "../../Loading";

const useEditThemeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface ISetSurveyTheme {
  survey: ISurvey;
  updateSurvey: (survey: ISurvey) => Promise<void>;
}

/**
 * Component for the second survey editor step
 * Asks the user to select a theme for their survey
 * @param props Survey data
 */
const SurveyThemeEditor = (props: ISetSurveyTheme) => {
  const survey = props.survey;

  const {
    isLoading,
    data: themes,
    error: themesError,
  } = useApi<IThemeMetadata[]>(themesRequest);
  const [theme, setTheme] = React.useState<string>(
    props.survey.themeID !== null ? props.survey.themeID.toString() : ""
  );
  const {
    isLoading: isLoadingThemeData,
    data: themeData,
    error: themeDataError,
    refetch,
  } = useApi<ITheme>({
    ...getThemeRequest(theme),
    useQueryParams: { staleTime: 60 * 1000, enabled: false },
  });

  React.useEffect(() => {
    if (Number(theme)) {
      refetch();
      props.updateSurvey({ ...props.survey, themeID: Number(theme) });
    }
  }, [theme]);

  React.useEffect(() => {
    if (themeData && themeData.themeCSS) {
      (async () => {
        const styleSheet = document.getElementById("preview-stylesheet");
        if (!styleSheet) {
          const head =
            document.head || document.getElementsByTagName("head")[0];
          const style = document.createElement("style");
          style.id = "preview-stylesheet";
          style.innerHTML = themeData.themeCSS;
          head.appendChild(style);
        } else {
          styleSheet.innerHTML = themeData.themeCSS;
        }
      })();
    }
  }, [themeData]);

  const classes = useEditThemeStyles();
  if (isLoading) return <Loading />;
  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <InputLabel id="theme-select-label">Select theme</InputLabel>
        {/* TODO: connect these options to react query */}
        <Select
          labelId="theme-select-label"
          id="theme-select"
          value={theme}
          onChange={(e) => setTheme(e.target.value as string)}
        >
          {themes?.map((themeMetadata) => (
            <MenuItem value={themeMetadata.themeID}>
              {themeMetadata.themeName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Preview survey={{ ...survey, themeID: 1 }} />
    </div>
  );
};

export default SurveyThemeEditor;
