import React from "react";
import { ICssObject } from "./jsonToCss";

const updateCss = (
  css: ICssObject,
  setCss: React.Dispatch<React.SetStateAction<ICssObject>>,
  selector: string,
  property: string,
  newValue: any
) => {
  const clone: ICssObject = { ...css };
  // if the selector doesn't exist, create it
  if (!clone[selector]) clone[selector] = {};
  // update (or set) the given property's value
  clone[selector][property] = newValue;
  setCss(clone);
};

export default updateCss;
