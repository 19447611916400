import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TagCard from "../../Components/Settings/TagCard";
import { useApi } from "../../queries/util";
import { ITag } from "../../queries/types";
import { tagSettingsRequest } from "../../queries/settingsRequests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      maxWidth: 1000,
    },
    pageTitle: {
      fontWeight: 400,
    },
    customTagContainer: {
      marginTop: theme.spacing(3),
    },
    cardTitle: {
      marginBottom: theme.spacing(2),
    },
    alternatingTableRow: {
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
      "& > *": {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      },
    },
    displayNameInput: {
      fontSize: "0.875rem",
      lineHeight: 1.43,
      fontWeight: 400,
    },
  })
);

const TagSettings = () => {
  const {
    isLoading,
    data: tags,
    error: tagError,
  } = useApi<ITag[]>(tagSettingsRequest);
  const classes = useStyles();
  const [systemTags, setSystemTags] = React.useState<ITag[] | null>(null);
  const [customTags, setCustomTags] = React.useState<ITag[] | null>(null);

  React.useEffect(() => {
    if (tags) {
      const newSystemTags = [];
      const newCustomTags = [];
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].systemTag) newSystemTags.push(tags[i]);
        else newCustomTags.push(tags[i]);
      }
      setSystemTags(newSystemTags);
      setCustomTags(newCustomTags);
    }
  }, [tags]);

  console.log(systemTags);

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h4" component="h2">
        NPS Tags
      </Typography>
      <TagCard classes={classes} tagData={customTags} />
      <TagCard classes={classes} tagData={systemTags} isSystemTag />
    </div>
  );
};

export default TagSettings;
