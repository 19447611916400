import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "../Components/Tables/Table";
import { IAccountProperty } from "../queries/types";
import accountCells from "../Components/Tables/Account/accountCells";
import { useApi } from "../queries/util";
import { accountPropertiesRequest } from "../queries/settingsRequests";
import { IFormattedProps } from "../Components/Tables/ColumnEditor";
import { drawerWidth } from "../config/Theme";
import { AccountContext } from "../Context/AccountContex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
  })
);

const Accounts = () => {
  const {
    isLoading: isLoadingAccountCustomProperties,
    data: accountCustomProperties,
    error: accountCustomPropertiesError,
  } = useApi<IAccountProperty[]>(accountPropertiesRequest);
  const { columns } = React.useContext(AccountContext);
  const [allColumns, setAllColumns] = React.useState<IFormattedProps>();
  const classes = useStyles();
  const theme = useTheme();

  /**
   * Format custom properties and add them to the list of all columns once they're done fetching
   */
  React.useEffect(() => {
    if (!isLoadingAccountCustomProperties) {
      let newAllColumns = accountCells;
      if (accountCustomProperties) {
        let formattedAccountProps: IFormattedProps = {};
        accountCustomProperties.forEach(
          (property) =>
            (formattedAccountProps[property.accountPropertyKey] = {
              id: property.accountPropertyKey,
              label: property.accountPropertyDisplay,
              property_typeName: property.property_typeName,
              isCustomProperty: true,
              customPropertyType: "account",
            })
        );
        newAllColumns = { ...newAllColumns, ...formattedAccountProps };
      }
      setAllColumns(newAllColumns);
    }
  }, [accountCustomProperties]);

  return (
    <div className={classes.root}>
      {allColumns && (
        <Table
          headCells={columns}
          allColumns={allColumns}
          title="Accounts"
          type="account"
          maxWidth={`calc(100vw - (${drawerWidth}px + ${theme.spacing(6)}px))`}
          context={AccountContext}
        />
      )}
    </div>
  );
};

export default Accounts;
