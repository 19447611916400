import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import { npsChipColors } from "../../../config/Theme";
import { HeadCell } from "../TableHead";
import { ISegmentMetadata, Visitor } from "../../../queries/types";
import VisitorModal from "./VisitorModal";
import AccountModal from "../Account/AccountModal";
import { Skeleton } from "@material-ui/lab";
import ErrorSnackbar from "../../ErrorSnackbar";
import { IFilter, VisitorContext } from "../../../Context/VisitorContext";
import { useApi } from "../../../queries/util";
import { segmentsRequest } from "../../../queries/npsRequests";

interface IVisitorTableBody {
  cols: HeadCell[];
  page: number;
  classes: any;
  setLoading: (loading: boolean) => void;
  setNextPageDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisitorTableBody = (props: IVisitorTableBody) => {
  const {
    currentFilter,
    lastFilter,
    ignoredNpsScoreThreshold,
    rowsPerPage,
    filterCriteria,
  } = React.useContext(VisitorContext);
  const {
    isLoading: isLoadingSegments,
    data: segments,
    error: segmentError,
  } = useApi<{
    segmentList: ISegmentMetadata[];
    visitorCount: { [key: string]: null };
  }>(segmentsRequest);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [selectedAccount, setSelectedAccount] = React.useState<number>(0);
  const [accountModalOpen, setAccountModalOpen] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [visitors, setVisitors] = React.useState<Visitor[]>();
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    props.setLoading(isLoading);
  }, [isLoading]);

  /**
   * Refetch visitor data whenever an appropriate setting is changed (page, rowsPerPage, filter, sortBy, etc.)
   */
  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      let json;
      try {
        // parse sorting data
        const sortBody: { sortBy: null | IFilter; lastSortBy: null | IFilter } =
          {
            sortBy: null,
            lastSortBy: null,
          };
        if (currentFilter) {
          const currentProp = props.cols.find(
            (col) => col.label === currentFilter.propertyID
          );
          if (currentProp) {
            sortBody.sortBy = {
              propertyID: currentProp.id,
              order: currentFilter.order,
              property_typeName: currentProp.property_typeName,
            };
          }
        }
        if (lastFilter) {
          const lastProp = props.cols.find(
            (col) => col.label === lastFilter.propertyID
          );
          if (lastProp) {
            sortBody.lastSortBy = {
              propertyID: lastProp.id,
              order: lastFilter.order,
              property_typeName: lastProp.property_typeName,
            };
          }
        }

        const response = await fetch("/api/v1/org/visitors", {
          method: "post",
          body: JSON.stringify({
            rows: rowsPerPage,
            page: props.page,
            properties: props.cols.map((col) => ({
              visitorPropertyKey: col.id,
              isCustomProperty: col.isCustomProperty || false,
              property_typeName: col.property_typeName,
              customPropertyType: col.customPropertyType,
            })),
            // tell the server what to sort results by
            ...sortBody,
            ignoredNpsScoreThreshold,
            filterCriteria: {
              ...filterCriteria,
              criteria: JSON.stringify(filterCriteria.criteria),
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        switch (response.status) {
          case 422:
            json = await response.json();
            setError(json.error);
            break;
          case 200:
            json = await response.json();
            if (json.visitors) {
              setVisitors(json.visitors);
            }
            break;
          default:
            setError(
              "An unexpected error occured while trying to fetch visitor data. Please try again later."
            );
        }
      } catch (err) {
        console.error(err);
        setError("An unexpected error occured. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    props.cols,
    rowsPerPage,
    props.page,
    currentFilter,
    ignoredNpsScoreThreshold,
    filterCriteria,
  ]);

  // disable next page button if not enough visitors were returned
  React.useEffect(() => {
    if (visitors && visitors.length < rowsPerPage + 1)
      props.setNextPageDisabled(true);
    else props.setNextPageDisabled(false);
  }, [visitors]);

  // empty rows to keep table height consistent
  const emptyRows = rowsPerPage - (visitors ? visitors.length : 0);

  const handleOpenAccountModal = (accountID: number) => {
    console.log(accountID);
    if (!isNaN(accountID)) {
      setSelectedAccount(accountID);
      setAccountModalOpen(true);
    }
  };

  const handleAccountModalClose = () => {
    setAccountModalOpen(false);
  };

  const handleOpenViewModal = (id: number) => {
    setSelected(id);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  if ((isLoading && !visitors) || isLoadingSegments) {
    return (
      <>
        {[...new Array(rowsPerPage)].map((e: any, i) => (
          <TableRow key={`row_${i}`} hover className={props.classes.row}>
            {props.cols.map((col, j) => (
              <TableCell
                scope="row"
                align="left"
                key={`row_${i}_cell_${j}`}
                className={props.classes.tableCell}
              >
                <Skeleton className={props.classes.tableCell}></Skeleton>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }
  return (
    <>
      {/* Loop through all of the row data in the current page */}
      {visitors?.map(
        (row, i) =>
          i < rowsPerPage && (
            <TableRow key={`row_${i}`} hover className={props.classes.row}>
              <TableCell
                scope="row"
                align="left"
                className={props.classes.tableCell}
              >
                <Tooltip title="View visitor data">
                  <Button
                    color="secondary"
                    onClick={() => handleOpenViewModal(row.visitorID)}
                    size="small"
                  >
                    View
                  </Button>
                </Tooltip>
              </TableCell>
              {props.cols.map((col, j) => {
                let tableBody;
                let formattedValue = row[col.id]
                  ? row[col.id]?.toString()
                  : "\u2014";
                if (col.property_typeName === "Date" && row[col.id]) {
                  try {
                    formattedValue = new Date(row[col.id] as string)
                      .toISOString()
                      .split("T")[0];
                  } catch (err) {
                    // console.error(err);
                  }
                }
                // handles special cases such as displaying links to view visitor or account data
                switch (col.id) {
                  case "accountName":
                    tableBody = (
                      <Link
                        component="button"
                        color="secondary"
                        variant="body1"
                        aria-label="View account detals"
                        aria-haspopup="true"
                        onClick={() =>
                          handleOpenAccountModal(Number(row.accountID))
                        }
                      >
                        <Typography variant="body2" noWrap>
                          {formattedValue ? formattedValue : "--"}
                        </Typography>
                      </Link>
                    );
                    break;
                  case "npsRating":
                    // skip null values
                    if (row[col.id] === null) break;
                    let background;
                    let color;
                    const rating = Number(row[col.id]);
                    if (rating >= 9) {
                      background = npsChipColors.green;
                      color = npsChipColors.darkGreen;
                    } else if (rating >= 7) {
                      background = npsChipColors.yellow;
                      color = npsChipColors.darkYellow;
                    } else {
                      background = npsChipColors.red;
                      color = npsChipColors.darkRed;
                    }

                    tableBody = (
                      <Chip label={row[col.id]} style={{ background, color }} />
                    );
                    break;
                  case "npsScoreDifference":
                  case "npsRatingDifference":
                    // skip null values
                    if (row[col.id] === null) break;
                    const change = Number(row[col.id]);
                    let changeColor;
                    const gtz = change > 0;
                    if (gtz) changeColor = npsChipColors.darkGreen;
                    else if (change < 0) changeColor = npsChipColors.darkRed;
                    tableBody = (
                      <Typography
                        variant="body2"
                        style={{ color: changeColor }}
                      >
                        {gtz ? `+${change}` : change}
                      </Typography>
                    );
                    break;
                  case "segments":
                    let segmentList: string[] = [];
                    if (segments) {
                      segments.segmentList.forEach((segment) => {
                        if (row[segment.segmentName]) {
                          segmentList.push(segment.segmentName);
                        }
                      });
                    }
                    tableBody = (
                      <Typography variant="body2" noWrap>
                        {segmentList.length > 0
                          ? segmentList.join(", ")
                          : "\u2014"}
                      </Typography>
                    );
                    break;
                  default:
                    tableBody = (
                      <Typography variant="body2" noWrap>
                        {formattedValue}
                      </Typography>
                    );
                }
                return (
                  <TableCell
                    component={col.id === "visitorName" ? "th" : "td"}
                    scope="row"
                    align="left"
                    key={`row_${i}_cell_${j}`}
                    className={props.classes.tableCell}
                  >
                    {tableBody}
                  </TableCell>
                );
              })}
            </TableRow>
          )
      )}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={props.cols.length} />
        </TableRow>
      )}
      <VisitorModal
        onClose={handleModalClose}
        open={modalOpen}
        visitorID={selected}
      />
      <AccountModal
        onClose={handleAccountModalClose}
        open={accountModalOpen}
        accountID={selectedAccount}
      />
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </>
  );
};

export default VisitorTableBody;
