import { IApi } from "./types";

export const publishingQueueRequest: IApi = {
  url: "/api/v1/publishing/queue",
  defaultError:
    "Something went wrong while fetching publishing queue. Please try again later.",
  responseProp: "queue",
  tag: "queue",
};

export const publishingHistoryRequest: IApi = {
  url: "/api/v1/publishing/history",
  defaultError:
    "Something went wrong while fetching publishing history. Please try again later.",
  responseProp: "history",
  tag: "history",
};
