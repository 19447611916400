// Visitor properties and corresponding metadata
import { HeadCell } from "../TableHead";

interface IVisitorCells {
  visitorLastSeen: HeadCell;
  visitorFullName: HeadCell;
  firstName: HeadCell;
  lastName: HeadCell;
  visitorEmail: HeadCell;
  accountName: HeadCell;
  segments: HeadCell;
  npsScore: HeadCell;
  npsRating: HeadCell;
  npsScoreDifference: HeadCell;
  npsRatingDifference: HeadCell;
  npsComment: HeadCell;
  lastNpsShown: HeadCell;
  lastNpsResponse: HeadCell;
  created: HeadCell;
  firstSeen: HeadCell;
  mostRecentVisit: HeadCell;
}

// TODO: map these ids with database column names
const visitorCells: { [key: string]: HeadCell } = {
  visitorLastSeen: {
    id: "visitorLastSeen",
    label: "Last Seen",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  visitorFullName: {
    id: "visitorFullName",
    label: "Full Name",
    placeholder: "Jane Doe",
    property_typeName: "String",
  },
  visitorFirstName: {
    id: "visitorFirstName",
    label: "First Name",
    placeholder: "Jane",
    property_typeName: "String",
  },
  visitorLastName: {
    id: "visitorLastName",
    label: "Last Name",
    placeholder: "Doe",
    property_typeName: "String",
  },
  visitorEmail: {
    id: "visitorEmail",
    label: "Email",
    placeholder: "jane.doe@gmail.com",
    property_typeName: "String",
  },
  accountName: {
    id: "accountName",
    label: "Account Name",
    placeholder: "Test account",
    property_typeName: "String",
  },
  segments: {
    id: "segments",
    label: "Segments",
    placeholder: "Free user",
    property_typeName: "String",
  },
  npsScore: {
    id: "npsScore",
    label: "NPS Score",
    alignment: "right",
    helpText: "Most recent NPS score (-100, 0, or +100)",
    placeholder: 100,
    property_typeName: "Number",
  },
  npsRating: {
    id: "npsRating",
    label: "NPS Rating",
    alignment: "right",
    helpText: "Most recent NPS rating (0 - 10)",
    placeholder: 10,
    property_typeName: "Number",
  },
  npsScoreDifference: {
    id: "npsScoreDifference",
    label: "Score +/-",
    alignment: "right",
    helpText:
      "Average increase or decrease from each visitor's previous NPS score",
    placeholder: "+1",
    property_typeName: "Number",
  },
  npsRatingDifference: {
    id: "npsRatingDifference",
    label: "Rating +/-",
    alignment: "right",
    helpText:
      "Average increase or decrease from each visitor's previous NPS rating",
    placeholder: "+4",
    property_typeName: "Number",
  },
  npsComment: {
    id: "npsComment",
    label: "NPS Comment",
    placeholder: "Easy to use!",
    property_typeName: "String",
  },
  visitorCreated: {
    id: "visitorCreated",
    label: "Created",
    helpText: "When this visitor was created in your application",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  visitorFirstSeen: {
    id: "visitorFirstSeen",
    label: "First Seen",
    helpText: "The first time this visitor was seen by Engagify",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  visitorLastNpsDisplay: {
    id: "visitorLastNpsDisplay",
    label: "Last NPS Display",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  visitorLastNpsReminder: {
    id: "visitorLastNpsReminder",
    label: "Last NPS Reminder",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  visitorLastNpsResponse: {
    id: "visitorLastNpsResponse",
    label: "Last NPS Response",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  accountCreated: {
    id: "accountCreated",
    label: "Account Created",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  accountFirstSeen: {
    id: "accountFirstSeen",
    label: "Account First Seen",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  accountLastSeen: {
    id: "accountLastSeen",
    label: "Account Last Seen",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  countryName: {
    id: "countryName",
    label: "Country Name",
    placeholder: "United States",
    property_typeName: "String",
  },
  languageName: {
    id: "languageName",
    label: "Language Name",
    placeholder: "English",
    property_typeName: "String",
  },
  browserName: {
    id: "browserName",
    label: "Browser Name",
    placeholder: "Firefox",
    property_typeName: "String",
  },
  browserVersionName: {
    id: "browserVersionName",
    label: "Browser Version Name",
    placeholder: "Firefox 66",
    property_typeName: "String",
  },
  doNotTrack: {
    id: "doNotTrack",
    label: "Do Not Track",
    placeholder: 0,
    property_typeName: "Flag",
  },
  userAgentName: {
    id: "userAgentName",
    label: "User Agent Name",
    placeholder: "Mozilla/5.0 (IPad)",
    property_typeName: "String",
  },
  deviceTypeName: {
    id: "deviceTypeName",
    label: "Device Type Name",
    placeholder: "Phone",
    property_typeName: "String",
  },
  operatingSystemName: {
    id: "operatingSystemName",
    label: "Operating System Name",
    placeholder: "Linux",
    property_typeName: "String",
  },
  operatingSystemVersionName: {
    id: "operatingSystemVersionName",
    label: "Operating System Version Name",
    placeholder: "Linux Ubuntu 10.04",
    property_typeName: "String",
  },
  previousNpsRating: {
    id: "previousNpsRating",
    label: "Previous NPS Rating",
    placeholder: 10,
    property_typeName: "Number",
  },
  previousNpsScore: {
    id: "previousNpsScore",
    label: "Previous NPS Score",
    placeholder: 1,
    property_typeName: "Number",
  },
};

/**
 * Maps a list of visitor properties to a list of table head cells (columns)
 * @param {(keyof IAccountCells)[]} properties The list of properties to convert into table columns
 * @returns {HeadCell[]} An array of properties and their corresponding metadata to add to the table's header row
 */
export const mapVisitorPropertiesToHeadCells = (
  properties: (keyof IVisitorCells)[]
): HeadCell[] =>
  properties
    .map((property) => visitorCells[property])
    .filter((prop) => prop !== undefined);

export default visitorCells;
