import React, { useState, useEffect, SetStateAction } from "react";
import { useQueryClient } from "react-query";
import { useApi } from "../queries/util";
import { fontsRequest } from "../queries/npsRequests";
import { IFont } from "../queries/types";

interface IResponseError {
  err: string;
  field: "fontName" | "embedCode";
}

const useFonts = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: fontFetch,
    error: fontError,
    refetch,
  } = useApi<IFont[]>(fontsRequest);
  const [fonts, setFonts] = useState<IFont[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fontError) setError(fontError.message);
    if (fontFetch) setFonts(fontFetch);
  }, [fontError, fontFetch, isLoading]);

  const checkResponse = async (
    response: Response,
    setResponseError: React.Dispatch<SetStateAction<IResponseError | null>>
  ) => {
    if (response.status === 401) {
      try {
        const json = await response.json();
        console.log(json);
        if (json.err && json.fieldName) setResponseError(json);
        else
          setError(
            "Something went wrong while saving a font. Please try again later."
          );
      } catch (err) {
        queryClient.invalidateQueries("user");
      }
    } else if (response.status !== 200) {
      setError(
        "Something went wrong while saving a font. Please try again later."
      );
    }
  };

  const handleAddFont = async (
    newFontName: string,
    newFontEmbed: string,
    setNewFontError: React.Dispatch<SetStateAction<IResponseError | null>>
  ) => {
    setNewFontError(null);
    const parsedCode = parseEmbedCode(newFontEmbed);
    if (!parsedCode) {
      setNewFontError({
        err: "Invalid embed code",
        field: "embedCode",
      });
      return false;
    }
    const response = await fetch("/api/v1/nps/themes/fonts", {
      method: "post",
      body: JSON.stringify({
        fontName: newFontName,
        embedCode: parsedCode,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    await checkResponse(response, setNewFontError);
    await refetch();
    return true;
  };

  const deleteFont = async (index: number) => {
    const response = await fetch(
      `/api/v1/nps/themes/fonts/${fonts[index].fontID}`,
      {
        method: "DELETE",
      }
    );
    if (response.status === 401) {
      try {
        const json = await response.json();
        setError(json.err);
      } catch (err) {
        queryClient.invalidateQueries("user");
      }
    } else if (response.status !== 200) {
      setError(
        "Something went wrong while deleting a font. Please try again later."
      );
    } else {
      await refetch();
    }
  };

  const updateFont = async (
    index: number,
    setEditingFont: React.Dispatch<SetStateAction<number | null>>,
    setEditingFontError: React.Dispatch<SetStateAction<IResponseError | null>>
  ) => {
    setEditingFontError(null);
    const font = fonts[index];
    const parsedCode = parseEmbedCode(font.embedCode);
    if (!parsedCode)
      return setEditingFontError({
        err: "Invalid embed code",
        field: "embedCode",
      });
    font.embedCode = parsedCode;
    const response = await fetch(`/api/v1/nps/themes/fonts/${font.fontID}`, {
      method: "put",
      body: JSON.stringify({
        fontName: font.fontName,
        embedCode: font.embedCode,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    await checkResponse(response, setEditingFontError);
    setEditingFont(null);
  };

  const handleChangeFontName = (newName: string, index: number) => {
    const clone = [...fonts];
    clone[index].fontName = newName;
    setFonts(clone);
  };

  const handleChangeEmbedCode = (newCode: string, index: number) => {
    const clone = [...fonts];
    clone[index].embedCode = newCode;
    setFonts(clone);
  };

  function parseEmbedCode(embedCode: string) {
    const linkRegEx = /href="([^"]+)"/; // to match href="url" pattern
    const importRegEx = /url\('([^']+)'\)/; // to match url('url') pattern

    let match;

    // Splitting code into multiple lines if they exist
    const lines = embedCode.split(/\r?\n/);

    for (let line of lines) {
      // If the line includes "link" and "/css2"
      if (line.includes("link") && line.includes("/css2")) {
        match = line.match(linkRegEx);
        if (match) break; // if match is found, break the loop
      } else if (line.includes("import")) {
        match = line.match(importRegEx);
        if (match) break; // if match is found, break the loop
      }
    }

    // considering raw URL if no tags are found
    if (!match) return embedCode.includes("/css2") ? embedCode : null;

    // Extracting the url from regex match object
    return match && match[1] ? match[1] : null;
  }

  return {
    fonts,
    isLoading,
    error,
    setError,
    handleAddFont,
    deleteFont,
    updateFont,
    handleChangeFontName,
    handleChangeEmbedCode,
  };
};

export default useFonts;
