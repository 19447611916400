import Avatar from "@material-ui/core/Avatar";
import { npsChipColors } from "../../config/Theme";

interface IRatingAvatar {
  rating: number | string;
}

/**
 * Component for the rating avatar displayed in dashboard responses
 * @param {IRatingAvatar} props The rating to be displayed
 */
const RatingAvatar = (props: IRatingAvatar) => {
  let background;
  let color;
  // change the avatar background and color based on the rating
  if (Number(props.rating) >= 9) {
    background = npsChipColors.green;
    color = npsChipColors.darkGreen;
  } else if (Number(props.rating) >= 7) {
    background = npsChipColors.yellow;
    color = npsChipColors.darkYellow;
  } else {
    background = npsChipColors.red;
    color = npsChipColors.darkRed;
  }
  return (
    <Avatar
      style={{
        backgroundColor: background,
        color: color,
      }}
    >
      {props.rating}
    </Avatar>
  );
};

export default RatingAvatar;
