import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { IVisitorProperty } from "../../queries/types";
import ErrorSnackbar from "../ErrorSnackbar";

interface IVisitorPropertyEditor {
  classes: any;
  property: IVisitorProperty;
  setModalOpen: () => void;
}

const VisitorPropertyEditor = (props: IVisitorPropertyEditor) => {
  const [displayName, setDisplayName] = React.useState<string>(
    props.property.visitorPropertyDisplay
  );
  const [hidden, setHidden] = React.useState<boolean>(
    Boolean(props.property.hidden)
  );
  const [error, setError] = React.useState<string | null>(null);

  // Update state whenever the provided property is updated
  React.useEffect(() => {
    if (props.property.visitorPropertyDisplay !== displayName)
      setDisplayName(props.property.visitorPropertyDisplay);
    if (Boolean(props.property.hidden) !== hidden)
      setHidden(Boolean(props.property.hidden));
  }, [props.property]);

  React.useEffect(() => {
    // set a 500 millisecond timeout on saving
    const timeoutID = setTimeout(async () => {
      if (
        props.property.visitorPropertyDisplay === displayName &&
        Boolean(props.property.hidden) === hidden
      )
        return;
      try {
        const updatedProperty: IVisitorProperty = {
          ...props.property,
          visitorPropertyDisplay: displayName,
          hidden: Number(hidden),
        };
        const response = await fetch(
          `/api/v1/settings/visitorProperties/${props.property.visitor_propertyID}`,
          {
            method: "put",
            body: JSON.stringify({ property: updatedProperty }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        let json;
        switch (response.status) {
          case 422:
            json = await response.json();
            setError(json.error);
            break;
          case 200:
            break;
          default:
            setError(
              "An unexpected error occured while trying to save a custom property. Please try again later."
            );
        }
      } catch (err) {
        setError((err as Error).message);
      }
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [displayName, hidden]);

  return (
    <TableRow
      className={props.classes.alternatingTableRow}
      key={props.property.visitorPropertyKey}
    >
      <TableCell>{props.property.visitorPropertyKey}</TableCell>
      <TableCell>
        <TextField
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          inputProps={{ className: props.classes.displayNameInput }}
        />
      </TableCell>
      <TableCell>
        {/* visibility radio buttons */}
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            aria-label="Visibility radio buttons"
          ></FormLabel>
          <RadioGroup
            row
            aria-label="visibility"
            value={hidden}
            onChange={() => setHidden((oldValue) => !oldValue)}
          >
            <FormControlLabel
              // values are inverted due to the database storing isHidden
              value={false}
              control={<Radio color="secondary" />}
              label={<Typography variant="body2">Visible</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              value={true}
              control={<Radio color="secondary" />}
              label={<Typography variant="body2">Hidden</Typography>}
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </TableCell>
      <TableCell>
        <Button color="secondary" onClick={() => props.setModalOpen()}>
          View
        </Button>
      </TableCell>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </TableRow>
  );
};

export default React.memo(VisitorPropertyEditor);
