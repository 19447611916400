import React from "react";
import { ISurvey } from "../../../queries/types";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { textInputFactory } from "../FormHooks";
import Preview from "../../SurveyPreview";
import SurveyAdvancedOptionsEditor from "./SurveyAdvancedOptionsEditor";
import { objectsAreEqual } from "./deepComparison";

interface IContent {
  classes: any;
  survey: ISurvey;
  updateSurvey: (survey: ISurvey) => Promise<void>;
}

/**
 * Component for the first step of the survey editing process
 * Asks for basic survey data including name, question, and follow up question
 * @param {IContent} props A css classes object and the fetched survey data
 */
const SurveyContentEditor = (props: IContent) => {
  // TODO: hook this up to React Query
  const survey = props.survey;

  const makeTextInput = textInputFactory(props.classes);

  // input hooks made with the textInput factory defined above
  const [name, nameInput] = makeTextInput("Survey name", survey.surveyName);
  const [question, questionInput] = makeTextInput(
    "Survey question",
    survey.surveyQuestion
  );
  const [followUpQuestion, followUpQuestionInput] = makeTextInput(
    "Follow-up question",
    survey.followUpQuestions[0]?.followUpQuestion || ""
  );
  const [thankYouMessage, thankYouMessageInput] = makeTextInput(
    '"Thank you" message',
    survey.thankYouMessage
  );

  const checkAdvancedOptions = () => {
    if (survey.followUpQuestions.length > 1) return true;
    return false;
  };

  const [advancedOptions, setAdvancedOptions] = React.useState<boolean>(
    checkAdvancedOptions()
  );

  // udpate the survey on each change
  React.useEffect(() => {
    const timeoutID = setTimeout(() => {
      const updatedSurvey = {
        ...props.survey,
        surveyName: name,
        surveyQuestion: question,
        thankYouMessage,
        // save the follow up question input if it's the only follow up question in use
        followUpQuestions:
          survey.followUpQuestions.length === 1
            ? [
                {
                  minRating: 0,
                  maxRating: 10,
                  followUpQuestion: followUpQuestion,
                },
              ]
            : survey.followUpQuestions,
      };
      if (!objectsAreEqual(updatedSurvey, survey))
        props.updateSurvey(updatedSurvey);
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [name, question, followUpQuestion, thankYouMessage]);

  return (
    <div>
      {nameInput}
      {questionInput}
      {!advancedOptions &&
        survey.followUpQuestions.length === 1 &&
        followUpQuestionInput}
      {thankYouMessageInput}
      <FormGroup row style={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={false}
              checked={Boolean(advancedOptions)}
              value={false}
              onChange={() => setAdvancedOptions(!advancedOptions)}
              name="toggleAdvancedOptions"
            />
          }
          label="Toggle advanced options"
        />
      </FormGroup>
      {advancedOptions && (
        <SurveyAdvancedOptionsEditor
          classes={props.classes}
          survey={survey}
          updateSurvey={props.updateSurvey}
        />
      )}
      <Preview
        survey={{
          ...survey,
          surveyQuestion: question,
          surveyName: name,
          followUpQuestions: [
            { ...survey.followUpQuestions[0], followUpQuestion },
          ],
        }}
      />
    </div>
  );
};

export default SurveyContentEditor;
