import { DataGrid } from "@mui/x-data-grid";

export type TableRow = {
  name: string;
  type: "NPS Survey" | "NPS Theme";
  lastPublishedDate: string;
  lastPublishedBy: string;
  id: string;
};

interface ITestTable {
  rows?: TableRow[];
  onChange: (selected: string[]) => void;
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 140,
  },
  {
    field: "type",
    headerName: "Type",
    with: 100,
  },
  {
    field: "lastPublishedDate",
    headerName: "Last Published Date",
    width: 200,
  },
  {
    field: "lastModifiedBy",
    headerName: "Last Published By",
    width: 200,
  },
];
const TestTable = (props: ITestTable) => {
  if (!props.rows) return null;

  const handleSelect = (e: any[]) => {
    props.onChange(e);
  };

  return (
    <DataGrid
      rows={props.rows || []}
      columns={columns}
      pageSize={3}
      disableSelectionOnClick
      checkboxSelection
      onSelectionModelChange={handleSelect}
      // selectionModel={props.rows.map((row) => row.id)}
    />
  );
};

export default TestTable;
