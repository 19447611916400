import React from "react";
import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { internalServerError } from "./errors";
import { IApi } from "./types";

export interface IExtractedResponse<T> {
  error: any;
  status: number;
  data: T | null;
}

/**
 * Parses a response and returns extracted response data
 * @param response The response object returned by the server
 * @param property The json key to extract data from
 * @returns {Promise<IExtractedResponse<T>} The extracted response data
 */
export const extractResponse = async <T>(
  response: Response,
  property: string
): Promise<IExtractedResponse<T>> => {
  let json;
  switch (response.status) {
    case 200:
      json = await response.json();
      // Verify the reponse data's integrity
      if (!json[property]) throw new Error(internalServerError);
      return { error: null, status: 200, data: json[property] };
    case 401:
      // the user isn't properly authenticated
      return { error: null, status: 401, data: null };
    case 422:
      // The request was unprocessable (invalid body data)
      json = await response.json();
      // Verify that the response
      if (!json.error) throw new Error(internalServerError);
      return { error: json.error, status: 422, data: null };
    case 404:
      return { error: null, status: 404, data: null };
    default:
      throw new Error(internalServerError);
  }
};

type IApiResponse<T> = UseQueryResult<T | undefined, Error> & {
  statusCode: number | undefined;
};

/**
 * Hook for making api calls
 * @param {IApi} apiData Data for the endpoint to use (path, params, and caching tags)
 * @returns {IApiResponse<T>} The response returned by the api server, including any errors thrown
 *   and whether or not the request is still loading
 */
export const useApi = <T>(apiData: IApi): IApiResponse<T> => {
  const queryClient = useQueryClient();
  const {
    requestParams,
    url,
    responseProp,
    tag,
    defaultError,
    useQueryParams,
  } = apiData;
  const [statusCode, setStatusCode] = React.useState<number>();
  // use react-query for caching response data
  const response = useQuery<T | undefined, Error>(
    tag,
    async () => {
      let data: T | undefined;
      let json: any;
      // await new Promise((resolve) => setTimeout(resolve, 40000));
      const response = await fetch(url, requestParams);
      setStatusCode(response.status);
      switch (response.status) {
        case 401:
          queryClient.invalidateQueries("user");
          break;
        case 422:
          json = await response.json();
          throw new Error(json.error || defaultError);
        case 200:
          json = await response.json();
          data = json[responseProp];
          break;
        default:
          throw new Error(defaultError);
      }
      return data;
    },
    useQueryParams
  );

  return {
    ...response,
    statusCode,
  };
};
