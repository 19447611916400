import { useQuery } from "react-query";
import { defaultError, internalServerError, userError } from "./errors";
import { ILoginData, IResetData, IUser } from "./types";

/**
 * Pings the server to check if the current user has an active session
 * @returns {Promise<IUser | null>} Any session data attached to the current user
 */
export const checkSessionLogin = async (): Promise<IUser | null> => {
  const result = await fetch("/api/v1/user/checkSessionLogin");
  switch (result.status) {
    case 200:
      return (await result.json()) as IUser;
    case 401:
      return null;
    case 500:
      throw new Error(userError);
    default:
      throw new Error(defaultError);
  }
};

/**
 * React query mutation method for logging a user in
 * @param {ILoginData} userData Inputted email / password combination
 * @returns {Promise<IUser>} If the login is successful, the current user's user data will be returned
 */
export const login = async (userData: ILoginData) => {
  const response = await fetch("/api/v1/user/login", {
    method: "post",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      return (await response.json()) as IUser;
    case 401:
      // TODO: differentiate between invalid email or invalid password
      throw new Error((await response.json()).error);
    case 500:
      throw new Error(internalServerError);
    default:
      throw new Error(defaultError);
  }
};

/**
 * React query mutation method for resetting a user's password
 * @param {string} password New Password
 * @returns {Promise<booleann>} If the login is successful, the current user will have a session cookie
 */
const resetPassword = async (password: string): Promise<boolean> => {
  const response = await fetch("/api/v1/user/resetPassword", {
    method: "post",
    body: JSON.stringify({ password }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      return true;
    case 401:
      throw new Error((await response.json()).error);
    case 422:
      return false;
    case 500:
      throw new Error(internalServerError);
    default:
      throw new Error(defaultError);
  }
};

/**
 * React query mutation method for resetting a user's password
 * @param {string} pin Pin to be validated
 * @param {string} token The password reset jwt
 * @returns {Promise<string | null>} If the pin is valid, the user's password can be reset. Otherwise, return an error message
 */
const validatePin = async (
  pin: string,
  token: string
): Promise<string | null> => {
  const response = await fetch(`/api/v1/user/verifyPasswordReset/${token}`, {
    method: "post",
    body: JSON.stringify({ pin: pin }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      return null;
    case 401:
      throw new Error((await response.json()).error);
    case 422:
      let json = await response.json();
      return json.error;
    case 500:
      throw new Error(internalServerError);
    default:
      throw new Error(defaultError);
  }
};

// Returns null if successful, otherwise returns an error message
export const validatePasswordReset = async (
  resetData: IResetData
): Promise<string | null> => {
  const { pin, token, password } = resetData;
  try {
    const validPin = await validatePin(pin, token);
    if (validPin) return validPin;
    const resetSuccess = await resetPassword(password);
    if (!resetSuccess)
      return "Please enter a password with at least 7 characters.";
    return null;
  } catch (err) {
    throw err;
  }
};

// Returns null if successful, otherwise returns an error message
export const sendResetEmail = async (email: string): Promise<string | null> => {
  const response = await fetch(`/api/v1/user/initializePasswordReset`, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      return null;
    case 401:
      throw new Error((await response.json()).error);
    case 422:
      let json = await response.json();
      return json.error;
    case 500:
      throw new Error(internalServerError);
    default:
      throw new Error(defaultError);
  }
};

/**
 * Custom react-query hook for fetching user data
 */
export const useUser = () => {
  return useQuery<IUser | null, Error>("user", checkSessionLogin, {
    staleTime: 30 * 60 * 1000,
  });
};
