import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { useLocation } from "react-router-dom";
import { Button, Dialog, Typography } from "@material-ui/core";
import { useUser } from "../../queries/user";
import { useQueryClient } from "react-query";
import { defaultError } from "../../queries/errors";
import { ITheme } from "../../config/Theme";
import Routes, { SettingsRoutes, SpecialCases } from "../../config/Routes";
import Loading from "../Loading";
import AccountModal from "../Settings/AccountModal";
import { publishingQueueRequest } from "../../queries/publishingRequests";
import { useApi } from "../../queries/util";
import PublishingForm from "../Forms/Publishing/PublishingForm";
import { IQueue } from "../../queries/types";

// AppBar styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${theme.drawerWidth}px)`,
        marginLeft: theme.drawerWidth,
        flexDirection: "row",
      },
      display: "flex",
      justifyContent: "space-between",
      background: theme.palette.background.paper,
      boxShadow: "0px 2px 2px rgba(100,100,100,0.1)",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    title: {
      color: theme.palette.contrast.dark,
      fontWeight: 400,
    },
    linkContainer: {
      display: "flex",
      flexDirection: "row",
    },
    profileButton: {
      background: "none",
      border: "none",
      display: "flex",
      alignItems: "center",
      outline: "none",
      padding: `0px ${theme.spacing(0.5)}px`,
      transition: "color 0.1s",
      "& > * + *": {
        marginLeft: theme.spacing(0.5),
      },
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.main,
      },
    },
    popoverListItem: {
      color: theme.palette.contrast.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.contrast.light,
      },
      transition: "all 0.1s",
    },
    publishing: {
      width: "60vw",
      maxWidth: "60vw",
    },
  })
);

interface IAppBar {
  handleDrawerToggle: () => void;
  pagesWithoutSidenav: string[];
}

/**
 * Checks it the current pathname is a special case and returns a corresponding page title,
 *
 * @param {string} pathname The current pathname
 */
const getSpecialCase = (pathname: string) => {
  // gets the current page name (ignoring subdirectories)
  const root = pathname.split("/")[1];
  return SpecialCases[root] || "";
};

const AppBarComponent = (props: IAppBar) => {
  const { isLoading: isLoadingUser, data: user } = useUser();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data: publishingQueue, refetch: refetchQueue } = useApi<IQueue>(
    publishingQueueRequest
  );
  const { pathname } = useLocation();
  const [title, setTitle] = React.useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [accountModalOpen, setAccountModalOpen] =
    React.useState<boolean>(false);
  const [publishMenuOpen, setPublishMenuOpen] = React.useState<boolean>(false);

  const closeAccountModal = () => setAccountModalOpen(false);

  // Refetch publishing queue on org change
  React.useEffect(() => {
    refetchQueue();
  }, [user?.organizationID]);

  // Get the new page title whenever the route is changed
  React.useEffect(() => {
    const foundRoute =
      Routes.find((route) => route.path === pathname) ||
      SettingsRoutes.find((route) => route.path === pathname);
    setTitle(foundRoute ? foundRoute.label : getSpecialCase(pathname));
  }, [pathname]);

  // check if the current route is on the list of routes that don't use the default layout
  if (props.pagesWithoutSidenav.includes(pathname)) return null;

  const handleLogout = async () => {
    const response = await fetch("/api/v1/user/logout");
    if (response.status === 200) {
      queryClient.invalidateQueries("user");
    } else {
      queryClient.setQueryData("error", defaultError);
    }
  };

  const handleProfileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  
  const handleClosePublishingMenu = () => {
    setPublishMenuOpen(false);
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* Sidebar toggler for mobile */}
        <Hidden smUp implementation="css">
          <IconButton
            aria-label="open sidenav"
            edge="start"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.linkContainer}>
          {!isLoadingUser &&
            (user ? (
              <button
                className={classes.profileButton}
                onClick={handleProfileClick}
              >
                <Hidden smDown implementation="css">
                  <Typography variant="body2" color="textPrimary">
                    Logged in as{" "}
                  </Typography>
                </Hidden>
                <Typography
                  variant="body2"
                  color={Boolean(anchorEl) ? "primary" : "inherit"}
                >
                  {user.email}
                </Typography>{" "}
                {Boolean(anchorEl) ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore />
                )}
              </button>
            ) : (
              <Loading />
            ))}
          <Button
            color={publishingQueue ? "primary" : "default"}
            disabled={!publishingQueue}
            variant="contained"
            style={{ color: "#fff" }}
            onClick={() => setPublishMenuOpen(true)}
          >
            Publish 
            {publishingQueue && publishingQueue.surveys.length + publishingQueue.themes.length > 0 &&
              `(${publishingQueue.surveys.length + publishingQueue.themes.length})`
            }
            
          </Button>
        </div>
      </Toolbar>
      <Popover
        id="account popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem
            button
            className={classes.popoverListItem}
            onClick={() => setAccountModalOpen(true)}
          >
            Account settings
          </ListItem>

          <ListItem
            button
            className={classes.popoverListItem}
            onClick={handleLogout}
          >
            Logout
          </ListItem>
        </List>
      </Popover>
      <Dialog
        PaperProps={{ style: { maxWidth: "80vw" } }}
        open={publishMenuOpen}
        onClose={handleClosePublishingMenu}
      >
        <PublishingForm onClose={handleClosePublishingMenu} />
      </Dialog>
      <AccountModal onClose={closeAccountModal} open={accountModalOpen} />
    </AppBar>
  );
};

export default AppBarComponent;
