import React from "react";
// material-ui imports
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Hidden,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useMutation } from "react-query";
import { sendResetEmail } from "../queries/user";
import { Alert } from "@material-ui/lab";
import LoadingButton from "@mui/lab/LoadingButton";

const spaceBetween = 24;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100vh",
      minWidth: "100vw",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        maxHeight: "calc(100vh - 100px)",
      },
    },
    appBar: {
      height: 100,
      width: "100vw",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    jumbo: {
      flex: 2,
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      transition: "flex 0.3s",
    },
    formRoot: {
      flex: 3,
      boxShadow: "-2px 0px 3px rgba(50, 50, 50, 0.1)",
      display: "flex",
      background: theme.palette.contrast.light,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100vw",
      transition: "flex 0.3s",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    buttonActive: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // width: 380,
    },
    logoName: {
      marginTop: spaceBetween,
      flex: 1,
      marginBottom: 16,
    },
    input: {
      marginTop: spaceBetween,
      minWidth: 380,
      marginBottom: spaceBetween,
    },
    description: {
      width: 380,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: 380,
    },
    loginErrorAlert: {
      width: 380,
      justifyContent: "center",
      marginTop: spaceBetween,
    },
  })
);

const ForgotPassword = () => {
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [submitError, setSubmitError] = React.useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();

  // const queryClient = useQueryClient();
  const emailMutation = useMutation<string | null, Error, string>(
    sendResetEmail,
    {
      onSuccess: (handledErrorMessage) => {
        if (handledErrorMessage) {
          setSubmitError(handledErrorMessage);
        } else setSubmitSuccess(true);
      },
      onError: (error) => {
        setSubmitError(error.message);
      },
    }
  );

  const handleEmailChange = (e: any) => {
    setSubmitError(null);
    setEmail(e.target.value);
    setEmailError(null);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitError(null);
    setSubmitSuccess(false);
    await emailMutation.mutateAsync(email);
  };

  /**
   * Submits the form if the user presses enter
   * @param e The keydown event
   */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleFormSubmit(e);
    }
  };

  return (
    <main className={classes.root}>
      {/* Left side jumbo (hidden on mobile) */}
      <Hidden xsDown>
        <div className={classes.jumbo} style={{ flex: 2 }}>
          <svg height="100" width="100">
            <circle
              cx="50"
              cy="50"
              r="40"
              stroke="white"
              strokeWidth="8"
              fill="none"
            />
          </svg>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      {/* Top banner (hidden on desktop) */}
      <Hidden smUp>
        <div className={classes.appBar}>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      <div
        className={classes.formRoot}
        style={{
          flex: 3,
        }}
      >
        <div className={classes.column}>
          <Typography variant="h3" className={classes.logoName}>
            Forgot Password?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            To reset your password, please enter your email address. We will
            send you an email with a link and a 6-digit PIN you can use to make
            a new password.
          </Typography>
          {submitSuccess && (
            <Alert severity="success" className={classes.loginErrorAlert}>
              An email with instructions to reset your password has been sent.
            </Alert>
          )}
          {submitError && (
            <Alert severity="error" className={classes.loginErrorAlert}>
              {submitError}
            </Alert>
          )}
          {!submitSuccess && !submitError && <div style={{ height: 24 }} />}
          <form className={classes.form} autoComplete="email">
            <TextField
              label="Email"
              type="text"
              className={classes.input}
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              error={emailError ? true : false}
              helperText={emailError}
              name="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <div>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleFormSubmit}
                disabled={emailMutation.isLoading}
                loading={emailMutation.isLoading}
              >
                Send password reset email
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
