// NPS response properties and corresponding metadata
import { HeadCell } from "../TableHead";
export const responseCells: { [key: string]: HeadCell } = {
  nps_responseDate: {
    id: "nps_responseDate",
    label: "Response Date",
    placeholder: "1/1/2021 10:06 AM",
    property_typeName: "Date",
  },
  npsRating: {
    id: "npsRating",
    label: "Rating",
    placeholder: 8,
    property_typeName: "Number",
  },
  npsRatingDifference: {
    id: "npsRatingDifference",
    label: "Rating +/-",
    placeholder: 1,
    property_typeName: "Number",
  },
  npsScoreDifference: {
    id: "npsScoreDifference",
    label: "Score +/-",
    placeholder: 1,
    property_typeName: "Number",
  },
  npsComment: {
    id: "npsComment",
    label: "Comment",
    placeholder: "Not enough reports",
    property_typeName: "String",
  },
  surveyName: {
    id: "surveyName",
    label: "NPS Survey Name",
    placeholder: "Default survey",
    property_typeName: "String",
  },
  visitorFullName: {
    id: "visitorFullName",
    label: "Full Name",
    placeholder: "Jane Doe",
    property_typeName: "String",
  },
  visitorEmail: {
    id: "visitorEmail",
    label: "Email",
    placeholder: "jane.doe@gmail.com",
    property_typeName: "String",
  },
  accountName: {
    id: "accountName",
    label: "Account Name",
    placeholder: "Test account",
    property_typeName: "String",
  },
};

/**
 * Maps a list of response property names to a list of table head cells (columns)
 * @param {(keyof IResponsetCells)[]} properties The list of properties to convert into table columns
 * @returns {HeadCell[]} An array of properties and their corresponding metadata to add to the table's header row
 */
export const mapResponsePropertiesToHeadCells = (
  properties: string[]
): HeadCell[] => properties.map((property) => responseCells[property]);

export default responseCells;
