import React from "react";
import validator from "email-validator";
// material-ui imports
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useMutation, useQueryClient } from "react-query";
import { checkSessionLogin, validatePasswordReset } from "../../queries/user";
import { IResetData } from "../../queries/types";
import { Link, useLocation } from "react-router-dom";

const spaceBetween = 25;
const formWidth = 340;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    resetForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      minWidth: formWidth,
    },
    input: {
      marginTop: spaceBetween,
      minWidth: formWidth,
    },
    buttonContainer: {
      marginTop: spaceBetween,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: formWidth,
    },
    loginErrorAlert: {
      minWidth: formWidth,
      justifyContent: "center",
      marginTop: spaceBetween,
    },
  })
);

/**
 * Reset Password component
 */
const ResetPasswordForm = () => {
  const [password, setPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [resetError, setResetError] = React.useState<string | null>(null);
  const [resetSuccess, setResetSuccess] = React.useState<boolean>(false);
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const pin = query.get("pin");

  const queryClient = useQueryClient();
  const resetMutation = useMutation<string | null, Error, IResetData>(
    validatePasswordReset,
    {
      onSuccess: async (handledErrorMessage) => {
        if (handledErrorMessage) {
          setResetError(handledErrorMessage);
        } else {
          const userData = await checkSessionLogin();
          if (userData) {
            queryClient.setQueryData("user", userData);
            return setResetSuccess(true);
          }
          setResetError(
            "Your password was successfully changed, but something went wrong authenticating you. Please try logging in again."
          );
        }
      },
      onError: (error) => {
        setResetError(error.message);
      },
    }
  );

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setResetSuccess(false);
    setResetError(null);
    console.log(token, pin);
    if (!token) {
      setResetError("Invalid token");
    }
    if (!pin || pin.length !== 6) {
      setResetError("Invalid PIN");
    }
    if (password.length < 7) {
      setPasswordError("Password must be at least 7 characters");
      return;
    }
    await resetMutation.mutateAsync({
      token: token || "",
      pin: pin || "",
      password: password,
    });
  };

  /**
   * Submits the form if the user presses enter
   * @param e The keydown event
   */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleFormSubmit(e);
    }
  };

  return (
    <>
      {Boolean(resetError) && (
        <Alert severity="error" className={classes.loginErrorAlert}>
          {resetError}
        </Alert>
      )}
      {resetSuccess && (
        <Alert severity="success" className={classes.loginErrorAlert}>
          Password successfully reset.{" "}
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Go to Dashboard.
          </Link>
        </Alert>
      )}
      <form
        onSubmit={handleFormSubmit}
        className={classes.resetForm}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="New Password"
          type={showPassword ? "text" : "password"}
          className={classes.input}
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
          error={passwordError ? true : false}
          helperText={passwordError}
          name="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
            disabled={resetMutation.isLoading}
          >
            Set new password
          </Button>
          <Link to="/login">
            <Button color="primary">Back to login</Button>
          </Link>
        </div>
      </form>
    </>
  );
};

export default ResetPasswordForm;
