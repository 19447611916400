// Draggable list based on https://codesandbox.io/s/74wxnz38m6?file=/src/index.js:906-922
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Container, Draggable } from "react-smooth-dnd";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "../DialogTitle";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { HeadCell } from "./TableHead";
import { IVisitorContext } from "../../Context/VisitorContext";
import { IAccountContext } from "../../Context/AccountContex";
import { IResponseContext } from "../../Context/ResponseContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: `4px 4px`,
      borderRadius: 4,
    },
    inputWrapper: {
      display: "flex",
      paddingRight: theme.spacing(2),
    },
    input: {
      flex: 1,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    accordion: {
      boxShadow: "none",
      padding: 0,
      border: 0,
      "&::before": {
        opacity: 0,
      },
    },
    accordionRoot: {
      paddingLeft: 0,
    },
    overflowX: {
      overflowX: "scroll",
    },
    tableCell: {
      minWidth: 100,
    },
    card: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      border: "1px solid rgba(0,0,0,0.1)",
      boxShadow: "3px 2px 3px 1px rgba(0,0,0,0.15)",
    },
    addColumnsCardRoot: {
      display: "flex",
      flexDirection: "column",
    },
    addColumnsList: {
      listStyle: "none",
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingLeft: 0,
      "&>*": {
        marginLeft: theme.spacing(2),
      },
    },
    addColumnsListItem: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export interface IFormattedProps {
  [key: string]: HeadCell;
}

interface ISortedColumns {
  systemProperties: HeadCell[];
  visitorCustomProperties: HeadCell[];
  accountCustomProperties: HeadCell[];
}

interface IColumnEditor {
  allColumns: IFormattedProps;
  onClose: () => void;
  context:
    | React.Context<IVisitorContext>
    | React.Context<IAccountContext>
    | React.Context<IResponseContext>;
}

/**
 * Component for editing the columns displayed in a given table
 * @param {HeadCell Dictionary} properties List of possible properties to add as table columns
 */
const ColumnEditor = (props: IColumnEditor) => {
  const { columns, setColumns } = React.useContext(props.context);
  const [autocompleteValue] = React.useState<string | null>();
  const [sortedColumns, setSortedColumns] = React.useState<ISortedColumns>();
  const classes = useStyles();
  const [newColumns, setNewColumns] = React.useState<HeadCell[]>(columns);

  /**
   * Sort the list of all columns into system properties, visitor custom properties, and account custom properties.
   */
  React.useEffect(() => {
    const newColumnList: ISortedColumns = {
      systemProperties: [],
      visitorCustomProperties: [],
      accountCustomProperties: [],
    };
    Object.keys(props.allColumns).forEach((key) => {
      const currentColumn = props.allColumns[key];
      if (!currentColumn) return;
      if (currentColumn.isCustomProperty) {
        if (!currentColumn.customPropertyType) return;
        if (currentColumn.customPropertyType === "account")
          newColumnList.accountCustomProperties.push(currentColumn);
        else if (currentColumn.customPropertyType === "visitor")
          newColumnList.visitorCustomProperties.push(currentColumn);
      } else newColumnList.systemProperties.push(currentColumn);
    });
    setSortedColumns(newColumnList);
  }, [props.allColumns]);

  // handles rearranging the column array when elements are dragged and dropped
  const onDrop = ({ removedIndex, addedIndex }: any) => {
    const clone = [...newColumns];
    const temp = clone.splice(removedIndex, 1);
    clone.splice(addedIndex, 0, temp[0]);
    setNewColumns(clone);
  };

  // deletes a column from the columns list
  const removeColumn = (index: number) => {
    const clone = [...newColumns];
    clone.splice(index, 1);
    setNewColumns(clone);
  };

  // adds a column to the column list
  const addColumn = (property: string) => {
    const keys = Object.keys(props.allColumns);
    for (let i = 0; i < keys.length; i++) {
      // console.log(props.allColumns[keys[i]]);
      if (props.allColumns[keys[i]].id === property) {
        // check if the column is already added
        const columnExists = newColumns.find((col) => col.id === property);
        if (columnExists) return null;
        return setNewColumns(newColumns.concat([props.allColumns[property]]));
      }
    }
  };

  /**
   * Either adds or removes a column depending on the new value of the clicked checkbox
   * @param e event reference
   * @param columnID The id of the column that was clicked
   */
  const handleCheckboxChange = (e: any, columnID: string | null) => {
    if (columnID) {
      if (e.target.checked) {
        addColumn(columnID);
      } else {
        const columnIndex = newColumns.findIndex((col) => col.id === columnID);
        if (columnIndex > -1) {
          removeColumn(columnIndex);
        }
      }
    }
  };

  /**
   * Saves the new columns
   */
  const handleSubmit = () => {
    setColumns(newColumns);
    props.onClose();
  };

  return (
    <>
      <DialogTitle id="edit-columns-modal" onClose={props.onClose}>
        Edit visitor table columns
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Drag items to reorder them.</DialogContentText>
        <List>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={onDrop}
          >
            {/* Map current columns to a rearrangeable list */}
            {newColumns.map((column, i) => (
              <Draggable key={`draggable_${column.id}`}>
                <ListItem button className={`drag-handle ${classes.listItem}`}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>{i + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={column.label}></ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeColumn(i)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
          </Container>
        </List>
        <Card className={classes.card}>
          <CardContent>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-preview"
                id="table-preview-header"
                // className={classes.accordionRoot}
              >
                <Typography>Add Columns</Typography>
              </AccordionSummary>
              <AccordionDetails
                className={
                  classes.accordionRoot + " " + classes.addColumnsCardRoot
                }
              >
                <Typography variant="subtitle2" color="textSecondary">
                  System Properties
                </Typography>
                <ul className={classes.addColumnsList}>
                  {sortedColumns?.systemProperties.map((column) => (
                    <li
                      className={classes.addColumnsListItem}
                      key={`checkbox_${column.id}`}
                    >
                      <Checkbox
                        checked={Boolean(
                          newColumns.find((col) => col.id === column.id)
                        )}
                        onChange={(e) => handleCheckboxChange(e, column.id)}
                      />
                      <Typography variant="body2">{column.label}</Typography>
                    </li>
                  ))}
                </ul>
                {sortedColumns?.visitorCustomProperties &&
                  sortedColumns?.visitorCustomProperties.length > 0 && (
                    <div>
                      <Typography variant="subtitle2" color="textSecondary">
                        Visitor Custom Properties
                      </Typography>
                      <ul className={classes.addColumnsList}>
                        {sortedColumns?.visitorCustomProperties.map(
                          (column) => {
                            return (
                              <li
                                className={classes.addColumnsListItem}
                                key={`checkbox_${column.id}`}
                              >
                                <Checkbox
                                  checked={Boolean(
                                    newColumns.find(
                                      (col) => col.id === column.id
                                    )
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, column.id)
                                  }
                                />
                                <Typography variant="body2">
                                  {column.label}
                                </Typography>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                {sortedColumns?.accountCustomProperties &&
                  sortedColumns?.accountCustomProperties.length > 0 && (
                    <div>
                      <Typography variant="subtitle2" color="textSecondary">
                        Account Custom Properties
                      </Typography>
                      <ul className={classes.addColumnsList}>
                        {sortedColumns?.accountCustomProperties.map(
                          (column) => (
                            <li
                              className={classes.addColumnsListItem}
                              key={`checkbox_${column.id}`}
                            >
                              <Checkbox
                                checked={Boolean(
                                  newColumns.find((col) => col.id === column.id)
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(e, column.id)
                                }
                              />
                              <Typography variant="body2">
                                {column.label}
                              </Typography>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-preview"
                id="table-preview-header"
                // className={classes.accordionRoot}
              >
                <Typography>Preview</Typography>
              </AccordionSummary>
              <AccordionDetails
                className={classes.accordionRoot + " " + classes.overflowX}
              >
                <Table aria-label="Visitor table preview">
                  <TableHead>
                    <TableRow>
                      {newColumns.map((column) => (
                        <TableCell
                          key={`${column.label}-label`}
                          className={classes.tableCell}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {newColumns.map((column) => (
                        <TableCell key={`${column.label}-placeholder`}>
                          {column.placeholder || "\u2014"}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default ColumnEditor;
