import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import moment, { Moment } from "moment-timezone";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
    },
    row: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 500,
      display: "flex",
      "&> *": {
        flex: 3,
      },
    },
    input: {
      width: 240,
    },
  })
);

const OrganizationSettings = () => {
  const [editingName, setEditingName] = React.useState<boolean>(false);
  const [editingTimeZone, setEditingTimeZone] = React.useState<boolean>(false);
  const [orgName, setOrgName] = React.useState<string>("Test Org");
  const [timezone, setTimezone] = React.useState<string>(
    "US Los Angeles (PST)"
  );
  const [today] = React.useState<Moment>(moment(new Date()));
  const timezoneGuess = moment.tz.guess();
  const [timezones] = React.useState<string[]>(() => {
    let names = [
      "America/Los_Angeles",
      "America/Denver",
      "America/Chicago",
      "America/New_York",
    ];
    if (!names.includes(timezoneGuess)) names = [timezoneGuess].concat(names);
    return names;
  });
  const [otherTimezone, setOtherTimezone] = React.useState<string>("");

  const classes = useStyles();

  /**
   * Concatenates the timezone id and timezone name, i.e. America/Los_Angeles (PST)
   * @param {string} timezoneID The moment timezoneID (i.e. America/Los_Angeles)
   */
  const formatTimezone = (timezoneID: string) =>
    `${timezoneID} (${today.tz(timezoneID).format("z")})`;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">Organization Information</Typography>
        <div className={classes.row}>
          <Typography style={{ flex: 2 }}>Organization Name: </Typography>
          {editingName ? (
            <div>
              <TextField
                value={orgName}
                aria-label="New organization name"
                onChange={(e) => setOrgName(e.target.value)}
                className={classes.input}
              />
              <Tooltip title="Save Changes">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => setEditingName(false)}
                >
                  <DoneIcon
                    fontSize="small"
                    onClick={() => setEditingName(false)}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Typography>
              Test Org{" "}
              <Tooltip title="Edit organization name">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => setEditingName(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
        </div>
        <div className={classes.row}>
          <Typography style={{ flex: 2 }}>Organization Timezone: </Typography>
          {editingTimeZone ? (
            <div>
              <Select
                native
                inputProps={{
                  name: "timezone",
                  id: "timezone-select",
                }}
                displayEmpty
                value={timezone}
                onChange={(e) => setTimezone(e.target.value as string)}
                className={classes.input}
              >
                {timezones.map((timezone) => (
                  <option key={`${timezone}_option`} value={timezone}>
                    {formatTimezone(timezone)}
                  </option>
                ))}
                <option value="other">Other</option>
              </Select>
              {timezone === "other" && (
                <Autocomplete
                  id="other-timezones"
                  options={moment.tz.names()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Other Timezone"
                      value={otherTimezone}
                      onChange={(e) => setOtherTimezone(e.target.value)}
                    />
                  )}
                  className={classes.input}
                />
              )}
              <Tooltip title="Save Changes">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => setEditingTimeZone(false)}
                >
                  <DoneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Typography>
              US Los Angeles (PST){" "}
              <Tooltip title="Edit organization timezone">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => setEditingTimeZone(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OrganizationSettings;
