import React from "react";
import validator from "email-validator";
// material-ui imports
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { internalServerError } from "../../queries/errors";
import { ReCaptcha } from "react-recaptcha-google";
import DemoModal from "./DemoModal";

const spaceBetween = 25;
const formWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    registrationErrorAlert: {
      minWidth: formWidth,
      [theme.breakpoints.up("sm")]: {
        minWidth: formWidth + 100,
      },
      justifyContent: "center",
      marginTop: 10,
      marginBottom: spaceBetween,
    },
    registerForm: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    registerInput: {
      flex: 1,
      minWidth: formWidth,
      [theme.breakpoints.up("sm")]: {
        minWidth: formWidth + 100,
      },
      marginTop: 10,
    },
    nameInput: {
      flex: 1,
      width: formWidth / 2,
      [theme.breakpoints.up("sm")]: {
        width: (formWidth + 100) / 2,
      },
    },
    registerButtonContainer: {
      marginTop: spaceBetween,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: formWidth,
      [theme.breakpoints.up("sm")]: {
        minWidth: formWidth + 100,
      },
    },
    recaptcha: {
      marginTop: theme.spacing(3),
    },
  })
);

// inherit method to go to the next registration step
interface IRegisterForm {
  setRegistrationStep: React.Dispatch<React.SetStateAction<0 | 1>>;
}

const RegisterForm = (props: IRegisterForm) => {
  // define state
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<
    string | null
  >(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [registrationError, setRegistrationError] = React.useState<string>("");
  const [isHuman, setIsHuman] = React.useState<boolean>(false);
  const [reCaptchaError, setReCaptchaError] = React.useState<string>("");
  const [demoModalOpen, setDemoModalOpen] = React.useState<boolean>(false);
  const captchaDemo = React.createRef<any>();
  const classes = useStyles();

  /**
   * Send an api request to save a new user account
   * @param e form submission event
   */
  const handleRegisterSubmit = async (e: any) => {
    if (e) e.preventDefault();
    const registerResponse = await fetch("/api/v1/user", {
      method: "post",
      body: JSON.stringify({ firstName, lastName, email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    // handle the response based on its status code
    switch (registerResponse.status) {
      case 200:
        return props.setRegistrationStep(1);
      case 422:
        const err = await registerResponse.json();
        return setRegistrationError(err.error);
      case 500:
        return setRegistrationError(internalServerError);
      default:
        setRegistrationError(
          "Oops... Something went wrong. Please try again later."
        );
    }
  };

  /**
   * Validates the length of a specified input and displays an error if an input doesn't meet its length requirement
   * @param nameType The input field to be validated
   */
  const validateInputLength = (
    nameType: "first name" | "last name" | "email" | "password"
  ) => {
    // map state variables to nameType values
    const keys: {
      [key: string]: [
        string,
        React.Dispatch<React.SetStateAction<string | null>>
      ];
    } = {
      "first name": [firstName, setFirstNameError],
      "last name": [lastName, setLastNameError],
      email: [email, setEmailError],
      password: [password, setPasswordError],
    };
    const values = keys[nameType];
    // check that the user entered something
    if (!values[0]) values[1](`Please enter a valid ${nameType}.`);
    // make sure that the user didn't enter too much
    else if (values[0].length > 254)
      values[1](
        `Please enter a${
          nameType === "email" && "n"
        } ${nameType} with fewer than 255 characters.`
      );
  };

  /**
   *
   * @param {any} newValue The new value of the specified input
   * @param {(val: any) => void} valueSetter Function that sets the value of the state variable associated with the specified input
   * @param {(val: any) => void} errorResetter Function that resets the input's error state value
   */
  const handleInputChange = (
    newValue: any,
    valueSetter: (val: any) => void,
    errorResetter: (val: any) => void
  ) => {
    valueSetter(newValue);
    errorResetter(null);
  };

  const validateEmail = () => {
    validateInputLength("email");
    if (!validator.validate(email))
      setEmailError("Please enter a valid email.");
  };

  const validatePassword = () => {
    validateInputLength("password");
    if (password.length < 8)
      return setPasswordError(
        "Please enter a password with more than 7 characters."
      );
  };

  const validateConfirmPassword = () => {
    if (confirmPassword !== password)
      return setConfirmPasswordError("Passwords don't match.");
  };

  const validateReCaptcha = () => {
    if (!isHuman) setReCaptchaError("Please prove you aren't a robot");
    else setReCaptchaError("");
  };

  /**
   * Checks if the submit button should be disabled or not
   * @returns {boolean} True: the button is disabled. False: the button is enabled.
   */
  const buttonDisabled = () => {
    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      passwordError ||
      confirmPasswordError
    )
      return true;
    if (!email || !password || !confirmPassword || !firstName || !lastName)
      return true;
    if (confirmPassword !== password) return true;
    if (!isHuman) return true;
    return false;
  };

  /**
   * Submit the form when the Enter key is pressed
   * @param e keydown event
   */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (!buttonDisabled()) handleRegisterSubmit(null);
    }
  };

  /**
   * Checks each input when the button is clicked while disabled
   */
  const handleButtonContainerClick = () => {
    validateInputLength("first name");
    validateInputLength("last name");
    validateInputLength("email");
    validateInputLength("password");
    validateEmail();
    validatePassword();
    validateConfirmPassword();
    validateReCaptcha();
  };

  const onLoadRecaptcha = () => {
    if (captchaDemo.current) {
      captchaDemo.current.reset();
    }
  };

  const verifyCallback = (recaptchaToken: string | null) => {
    console.log(recaptchaToken);
    setIsHuman(false);
    if (recaptchaToken) setIsHuman(true);
  };

  return (
    <form
      onSubmit={handleRegisterSubmit}
      className={classes.registerForm}
      noValidate
      autoComplete="off"
    >
      {registrationError && (
        <Alert severity="error" className={classes.registrationErrorAlert}>
          {registrationError}
        </Alert>
      )}
      <div className="row">
        <TextField
          label="First Name"
          className={classes.nameInput}
          value={firstName}
          onBlur={(e) => validateInputLength("first name")}
          error={firstNameError ? true : false}
          helperText={firstNameError}
          onChange={(e) =>
            handleInputChange(e.target.value, setFirstName, setFirstNameError)
          }
        />
        <TextField
          label="Last Name"
          className={classes.nameInput}
          value={lastName}
          onBlur={(e) => validateInputLength("last name")}
          error={lastNameError ? true : false}
          helperText={lastNameError}
          onChange={(e) =>
            handleInputChange(e.target.value, setLastName, setLastNameError)
          }
        />
      </div>
      <TextField
        label="Email"
        className={classes.registerInput}
        value={email}
        onBlur={(e) => validateEmail()}
        error={emailError ? true : false}
        helperText={emailError}
        onChange={(e) =>
          handleInputChange(e.target.value, setEmail, setEmailError)
        }
      />
      <TextField
        label="Password"
        className={classes.registerInput}
        type={showPassword ? "text" : "password"}
        value={password}
        onBlur={(e) => validatePassword()}
        error={passwordError ? true : false}
        helperText={passwordError}
        onChange={(e) =>
          handleInputChange(e.target.value, setPassword, setPasswordError)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Comfirm Password"
        className={classes.registerInput}
        type={showPassword ? "text" : "password"}
        value={confirmPassword}
        onBlur={(e) => validateConfirmPassword()}
        error={confirmPasswordError ? true : false}
        helperText={confirmPasswordError}
        onChange={(e) =>
          handleInputChange(
            e.target.value,
            setConfirmPassword,
            setConfirmPasswordError
          )
        }
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.recaptcha}>
        <ReCaptcha
          ref={captchaDemo}
          size="normal"
          sitekey="6Lc20ZIaAAAAAP0LnS7Woh10ty77ked8JNSh9vIq"
          onloadCallBack={onLoadRecaptcha}
          verifyCallback={verifyCallback}
          onExpired={() => console.log("expired")}
        />
        <Typography color="error" variant="body2">
          {reCaptchaError}
        </Typography>
      </div>

      <div className={classes.registerButtonContainer}>
        <div style={{ cursor: "pointer" }} onClick={handleButtonContainerClick}>
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabled()}
            onClick={handleRegisterSubmit}
          >
            Register
          </Button>
        </div>
        <Button color="primary" onClick={() => setDemoModalOpen(true)}>
          Request a Demo
        </Button>
      </div>
      <DemoModal open={demoModalOpen} onClose={() => setDemoModalOpen(false)} />
    </form>
  );
};

export default RegisterForm;
