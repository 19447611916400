import React from "react";

import Routes, { SettingsRoutes } from "../../config/Routes";

// material-ui components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import PolymerIcon from "@material-ui/icons/Polymer";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";

import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import OrgSelect from "../Forms/OrgSelect";
import { ITheme } from "../../config/Theme";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: theme.drawerWidth,
        flexShrink: 0,
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: theme.drawerWidth,
      border: "none",
      boxShadow: "2px 0px 4px rgba(150,150,150,0.1)",
    },
    logo: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      paddingLeft: 16,
      borderRight: "none",
      color: theme.palette.primary.main,
    },
    listItem: {
      paddingTop: 8,
      paddingBottom: 8,
      // ripple color
      color: theme.palette.contrast.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      "& > *": {
        color: theme.palette.contrast.dark,
      },
      "& *": {
        transition: "color 0.1s",
      },
      "&:hover *": {
        color: theme.palette.contrast.light,
      },
    },
    listItemSelected: {
      paddingTop: 8,
      paddingBottom: 8,
      "& *": {
        color: theme.palette.contrast.light,
      },
      background: `${theme.palette.primary.main} !important`,
    },
    listItemCollapse: {
      paddingLeft: 30,
    },
    listItemIcon: {
      color: theme.palette.contrast.main,
      minWidth: 40,
    },
    listItemIconRoot: {
      color: theme.palette.contrast.main,
    },
    list: {
      flexGrow: 1,
      flex: 1,
    },
    sidebar: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  })
);

// prop interface
interface ISidenav {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  pagesWithoutSidenav: string[];
}

const Sidenav = (props: ISidenav) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();
  // open settings by default if the user is on a settings page
  const [settingsOpen, setSettingsOpen] = React.useState<boolean>(
    SettingsRoutes.find((route) => route.path === pathname) ? true : false
  );

  if (props.pagesWithoutSidenav.includes(pathname)) return null;

  /**
   * Redirects the user to the given location
   * @param {string} path The path to redirect the user to
   */
  const handleLinkClick = (path: string) => {
    history.push(path);
  };

  /**
   * opens up the settings menu
   */
  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  /**
   * Sidebar content
   */
  const sidebarDrawer = (
    <div className={classes.sidebar}>
      <Typography
        variant="h5"
        noWrap
        className={`${classes.toolbar} ${classes.logo}`}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <PolymerIcon style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        Zengage
      </Typography>
      <List component="nav" disablePadding dense className={classes.list}>
        {/* map the route list to corresponding links */}
        {Routes.map((route) => (
          <ListItem
            key={`sidenav_${route.label}`}
            button
            classes={{
              root: classes.listItem,
              selected: classes.listItemSelected,
            }}
            color="primary"
            onClick={() => handleLinkClick(route.path)}
            selected={pathname === route.path}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              {route.icon &&
                React.createElement(route.icon, {
                  classes: { root: classes.listItemIconRoot },
                  fontSize: "small",
                  color: "disabled",
                })}
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">{route.label}</Typography>
            </ListItemText>
          </ListItem>
        ))}
        <Divider />
        {/* settings section */}
        <ListItem
          button
          onClick={handleSettingsClick}
          className={classes.listItem}
          color="primary"
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <SettingsIcon
              classes={{ root: classes.listItemIconRoot }}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2">Settings</Typography>
          </ListItemText>
          {settingsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* map setting routes to corresponding links */}
            {SettingsRoutes.map((route) => (
              <ListItem
                button
                classes={{
                  root: `${classes.listItem} ${classes.listItemCollapse}`,
                  selected: `${classes.listItemCollapse} ${classes.listItemSelected}`,
                }}
                onClick={() => handleLinkClick(route.path)}
                key={`sidenav_${route.label}`}
                selected={pathname === route.path}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  {route.icon &&
                    React.createElement(route.icon, {
                      classes: { root: classes.listItemIconRoot },
                      fontSize: "small",
                    })}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="subtitle2">{route.label}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <OrgSelect />
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="Sidenav">
      {/* Sidebar for mobile */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          classes={{ paper: classes.drawerPaper }}
        >
          {sidebarDrawer}
        </Drawer>
      </Hidden>
      {/* Normal Sidebar */}
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{ paper: classes.drawerPaper }}
        >
          {sidebarDrawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidenav;
