// Route json for the sidenav
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import StyleIcon from "@material-ui/icons/Style";
import PieChartIcon from "@material-ui/icons/PieChart";
import PollIcon from "@material-ui/icons/Poll";
import LabelIcon from "@material-ui/icons/Label";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

export interface IRoute {
  label: string;
  path: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

// List of routes and their corresponding labels and sidebar icons
const Routes: IRoute[] = [
  { label: "Dashboard", path: "/", icon: DashboardIcon },
  { label: "Visitors", path: "/visitors", icon: PeopleIcon },
  { label: "Accounts", path: "/accounts", icon: AccountBalanceIcon },
  { label: "NPS Responses", path: "/nps-responses", icon: ChatBubbleIcon },
  { label: "NPS Surveys", path: "/nps-surveys", icon: PollIcon },
  { label: "NPS Themes", path: "/nps-themes", icon: StyleIcon },
  { label: "Segments", path: "/segments", icon: PieChartIcon },
];

// List of routes nested in the settings sidebar list item
export const SettingsRoutes: IRoute[] = [
  {
    label: "Visitor Properties",
    path: "/visitor-properties",
    icon: PeopleIcon,
  },
  {
    label: "Account Properties",
    path: "/account-properties",
    icon: AccountBalanceIcon,
  },
  { label: "Tags", path: "/tags", icon: LabelIcon },
  // { label: "Fonts", path: "/fonts", icon: FontDownloadIcon },
  { label: "Users", path: "/users", icon: AccountBoxIcon },
  {
    label: "Organization Settings",
    path: "/organization-settings",
    icon: AccountBoxIcon,
  },
];

// List of routes that aren't in the sidenav and their corresponding page titles
export const SpecialCases: { [key: string]: string } = {
  "edit-nps-survey": "Edit NPS Survey",
  "create-nps-survey": "Create NPS Survey",
  "edit-nps-theme": "Edit NPS Theme",
  "create-nps-theme": "Create NPS Theme",
  "edit-segment": "Edit Segment",
  "create-segment": "Create Segment",
};

export default Routes;
