import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ICriteria } from "./Criteria";
import CriteriaGroup from "./CriteriaGroup";
import { useCriteriaStyles } from "../../config/Styles";
import ErrorSnackbar from "../ErrorSnackbar";
import { useQueryClient } from "react-query";
import { useApi } from "../../queries/util";
import { IAccountProperty, IVisitorProperty } from "../../queries/types";
import {
  accountPropertiesRequest,
  visitorPropertiesRequest,
} from "../../queries/settingsRequests";
import Loading from "../Loading";
import { IProperty, TypeName } from "./data/properties";
import rules from "./data/rules";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 800,
      minHeight: 400,
    },
  })
);

/**
 * Gets a list of rules for the given rule type
 * @param {"String" | "Date" | "Flag" | "Number"} ruleName The type of rule to get
 * @returns {IRule[]} A list of rules
 */
const getRules = (ruleName: "String" | "Date" | "Flag" | "Number") => {
  switch (ruleName) {
    case "String":
      return rules.strings;
    case "Date":
      return rules.dates;
    case "Flag":
      return rules.booleans;
    case "Number":
      return rules.numbers;
    default:
      return rules.strings;
  }
};

export interface ICriteriaGroup {
  condition: "All" | "Any";
  rules: ICriteriaData[];
}

export interface ICriteriaData {
  id: string;
  type: "group" | "rule";
  criteria: ICriteriaGroup | ICriteria;
}

interface ICriteriaBuilder {
  criteriaID?: number;
  criteria: ICriteriaData | null;
  setLastSaved: () => void;
  showCard: boolean;
  disableAutoSave?: boolean;
  disableDefaultValue?: boolean;
  onSave?: (criteriaJson: ICriteriaData) => void;
  accountMode?: boolean;
}

/**
 * Component housing all of the criteria input components
 * @param {ICriteriaBuilder} props criteria data and method to set the most recent save date
 */
const CriteriaBuilder = (props: ICriteriaBuilder) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const criteriaClasses = useCriteriaStyles();
  const [error, setError] = React.useState<string | null>(null);
  const [criteria, setCriteria] = React.useState<ICriteriaData>(
    props.criteria || {
      id: "mainGroup",
      type: "group",
      criteria: {
        condition: "All",
        rules: props.disableDefaultValue
          ? []
          : [
              {
                id: "adfad",
                type: "rule",
                criteria: {
                  propertyGroup: "Visitor Properties",
                  property: "First Name",
                  type: "String",
                  rule: null,
                  values: [],
                  error: true,
                },
              },
            ],
      },
    }
  );
  const {
    isLoading: isLoadingVisitorProperties,
    data: visitorProperties,
    error: visitorPropertyError,
  } = useApi<IVisitorProperty[]>(visitorPropertiesRequest);
  const {
    isLoading: isLoadingAccountProperties,
    data: accountProperties,
    error: accountPropertyError,
  } = useApi<IAccountProperty[]>(accountPropertiesRequest);

  const [customProperties, setCustomProperties] = React.useState<{
    visitorProperties: IProperty[] | null;
    accountProperties: IProperty[] | null;
  }>({ visitorProperties: null, accountProperties: null });

  /**
   * Save formatted custom properties when they're done fetching
   */
  React.useEffect(() => {
    if (visitorProperties && accountProperties) {
      setCustomProperties({
        visitorProperties: visitorProperties.map(
          (property): IProperty => ({
            label: property.visitorPropertyDisplay,
            type: property.property_typeName as TypeName,
            rules: getRules(property.property_typeName as TypeName),
            group: "Custom Properties",
            isCustomProperty: true,
            customPropertyType: "visitor",
            systemName: property.visitorPropertyKey,
          })
        ),
        accountProperties: accountProperties.map(
          (property): IProperty => ({
            label: property.accountPropertyDisplay,
            type: property.property_typeName as TypeName,
            rules: getRules(property.property_typeName as TypeName),
            group: "Custom Properties",
            isCustomProperty: true,
            customPropertyType: "account",
            systemName: property.accountPropertyKey,
          })
        ),
      });
    }
  }, [visitorProperties, accountProperties]);

  /**
   * Display any errors encountered while fetching property data
   */
  React.useEffect(() => {
    if (accountPropertyError) setError(accountPropertyError.message);
    if (visitorPropertyError) setError(visitorPropertyError.message);
  }, [accountPropertyError, visitorPropertyError]);

  /**
   * Save changes whenever an update is made
   */
  React.useEffect(() => {
    if (!props.disableAutoSave) {
      const timeoutId = setTimeout(async () => {
        const response = await fetch(
          `/api/v1/nps/segments/criteria/${props.criteriaID}`,
          {
            method: "put",
            body: JSON.stringify({ criteriaJson: criteria }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 401) queryClient.invalidateQueries("user");
        else if (response.status === 422) {
          const json = await response.json();
          setError(json.err);
        } else if (response.status !== 200) {
          setError("Something went wrong. Please try again later.");
        }
        props.setLastSaved();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    if (props.onSave) props.onSave(criteria);
  }, [criteria]);

  /**
   * Updates the criteria object
   * @param id The id of the criteria to be updated
   * @param newCriteria The new criteria object
   */
  const updateCriteria = (id: string, newCriteria: ICriteriaGroup) => {
    setCriteria({ ...criteria, criteria: newCriteria });
  };

  if (
    isLoadingAccountProperties ||
    isLoadingVisitorProperties ||
    !customProperties.visitorProperties ||
    !customProperties.accountProperties
  )
    return <Loading />;

  const content = (
    <>
      {props.showCard && (
        <Typography variant="subtitle2">Criteria Builder</Typography>
      )}
      {
        <CriteriaGroup
          classes={criteriaClasses}
          criteria={criteria.criteria as ICriteriaGroup}
          updateCriteria={updateCriteria}
          id={criteria.id}
          deletable={false}
          delete={() => null}
          customProperties={customProperties}
          accountMode={props.accountMode}
        />
      }
    </>
  );
  return (
    <div>
      {props.showCard ? (
        <Card className={classes.root}>
          <CardContent>{content}</CardContent>
        </Card>
      ) : (
        content
      )}
      <ErrorSnackbar
        error={error}
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
      />
    </div>
  );
};

export default CriteriaBuilder;
