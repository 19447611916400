import {
  IPublishedHistory,
  IPublishedSurvey,
  IPublishedTheme,
} from "../../../queries/types";

interface IHashmap {
  [key: number]: { date: Date; index: number };
}

interface ISurveysAndThemes {
  surveys: IPublishedSurvey[];
  themes: IPublishedTheme[];
}

interface IFormattedHistory {
  live: ISurveysAndThemes;
  test: ISurveysAndThemes;
}

export const getAllVersions = (history: IPublishedHistory) => {
  let { surveys, themes } = history;
  const live: IPublishedHistory = { surveys: [], themes: [] };
  const test: IPublishedHistory = { surveys: [], themes: [] };

  for (let survey of surveys) {
    if (survey.publishedLive) live.surveys.push(survey);
    else if (survey.publishedTest) test.surveys.push(survey);
  }

  for (let theme of themes) {
    if (theme.publishedLive) live.themes.push(theme);
    else if (theme.publishedTest) test.themes.push(theme);
  }

  return { live, test };
};

export const formatHistory = (
  history: IPublishedHistory
): IFormattedHistory => {
  let { surveys, themes } = history;

  let liveSurveys: IPublishedSurvey[] = [];
  let testSurveys: IPublishedSurvey[] = [];
  let liveThemes: IPublishedTheme[] = [];
  let testThemes: IPublishedTheme[] = [];

  for (let survey of surveys) {
    if (survey.publishedLive) liveSurveys.push(survey);
    else if (survey.publishedTest) testSurveys.push(survey);
  }

  for (let theme of themes) {
    if (theme.publishedLive) liveThemes.push(theme);
    else if (theme.publishedTest) testThemes.push(theme);
  }

  liveSurveys = getLatestItems(liveSurveys, "nps_surveyID", "publishedLive");
  testSurveys = getLatestItems(testSurveys, "nps_surveyID", "publishedTest");
  liveThemes = getLatestItems(liveThemes, "themeID", "publishedLive");
  testThemes = getLatestItems(testThemes, "themeID", "publishedTest");
  return {
    live: {
      surveys: liveSurveys,
      themes: liveThemes,
    },
    test: {
      surveys: testSurveys,
      themes: testThemes,
    },
  };
};

const getLatestItems = (
  array: any[],
  idProperty: string,
  dateProperty: string
) => {
  const hashmap: IHashmap = {};
  let hash;
  for (let i = 0; i < array.length; i++) {
    hash = array[i][idProperty];
    if (hashmap[hash]) {
      if (hashmap[hash].date < array[i][dateProperty])
        hashmap[hash] = { date: array[i][dateProperty], index: i };
    } else {
      hashmap[hash] = { date: array[i][dateProperty], index: i };
    }
  }
  return Object.keys(hashmap).map((key) => array[hashmap[Number(key)].index]);
};

interface ISelectedItems {
  surveys: { [key: number]: IPublishedSurvey };
  themes: { [key: number]: IPublishedTheme };
}
export const formatSelectedItems = (selectedItems: ISelectedItems) => {
  const formattedSurveys = Object.keys(selectedItems.surveys).map(
    (key) => selectedItems.surveys[Number(key)].published_nps_surveyID
  );
  const formattedThemes = Object.keys(selectedItems.themes).map(
    (key) => selectedItems.themes[Number(key)].published_themeID
  );
  return {
    published_nps_surveyIDs: formattedSurveys,
    published_themeIDs: formattedThemes,
  };
};
