import React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Account, Visitor, Response } from "../../queries/types";
import EnhancedTableHead, { HeadCell, Order } from "./TableHead";
import Settings from "./Settings";
import VisitorTableBody from "./Visitor/VisitorTableBody";
import AccountTableBody from "./Account/AccountTableBody";
import visitorCells from "./Visitor/visitorCells";
import accountCells from "./Account/accountCells";
import ResponseTableBody from "./NPS/ResponseBody";
import responseCells from "./NPS/npsResponseCells";
import {
  IFilter,
  IVisitorContext,
  VisitorContext,
} from "../../Context/VisitorContext";
import { IAccountContext } from "../../Context/AccountContex";
import { IResponseContext } from "../../Context/ResponseContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    table: {
      padding: 4,
      borderRadius: 10,
    },
    toolbar: {
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
    headCell: {
      color: theme.palette.contrast.main,
    },
    row: {
      "&:hover": {
        boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
        backgroundColor: "#e7e7e7 !important",
        transform: "translate(0, -2px)",
      },
      transition: "all 0.05s",
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
      // display: 'flex'
    },
    tableCell: {
      // maxWidth: 300,
      whiteSpace: "normal",
      wordWrap: "break-word",
      padding: `2px ${theme.spacing(1)}px`,
      alignItems: "center",
    },
    headCellContainer: {
      display: "inline-flex",
      flexFlow: "row wrap",
    },
    tagList: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    tag: {
      padding: 0,
      height: 28,
      marginTop: theme.spacing(0.75),
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface ITable {
  headCells: HeadCell[];
  title: string;
  allColumns?: { [key: string]: HeadCell };
  type: "visitor" | "account" | "npsResponses";
  maxWidth?: number | string;
  forceWidth?: boolean;
  context:
    | React.Context<IVisitorContext>
    | React.Context<IAccountContext>
    | React.Context<IResponseContext>;
}

/**
 *
 * @param {HeadCell[]} headCells List of column data for the table's header cells
 * @param {string} title The table's title
 * @param {Visitor[] | Account[]} rows The table's body data
 * @param {"visitor" | "account" | "npsResponses"} type Whether this is the visitor table or account table
 */
const Table = (props: ITable) => {
  const {
    currentFilter,
    updateFilter,
    rowsPerPage,
    updateRowsPerPage,
    lastFilter,
    filterCriteria,
    ignoredNpsScoreThreshold,
  } = React.useContext(props.context);
  const [filter, setFilter] = React.useState<IFilter>(
    currentFilter || {
      propertyID: props.headCells[0].id,
      order: "desc",
      property_typeName: props.headCells[0].property_typeName,
    }
  );
  const [page, setPage] = React.useState<number>(() => 0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [nextPageDisabled, setNextPageDisabled] =
    React.useState<boolean>(false);
  const classes = useStyles();

  /**
   * Handler function for sorting by column on column click
   * @param e Event
   * @param property The name of the property to sort by
   * @param propertyID The id of the property to sort by
   */
  const handleRequestSort = (e: any, property: string, propertyID: string) => {
    const isAsc = filter.propertyID === property && filter.order === "asc";
    const propertyType = props.headCells.find(
      (column) => column.id === propertyID
    )?.property_typeName;
    if (!propertyType) return;
    setFilter({
      propertyID: property,
      order: isAsc ? "desc" : "asc",
      property_typeName: propertyType,
    });
  };

  /**
   * Updates local state whenever the sortBy filter is changed
   */
  React.useEffect(() => {
    if (
      filter.propertyID !== currentFilter?.propertyID ||
      filter.order !== currentFilter?.order
    ) {
      updateFilter({
        propertyID: filter.propertyID,
        order: filter.order,
        property_typeName: filter.property_typeName,
      });
    }
  }, [filter]);

  React.useEffect(() => {
    setPage(0);
  }, [currentFilter, lastFilter, filterCriteria, ignoredNpsScoreThreshold]);

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  let tableBody;
  switch (props.type) {
    case "visitor":
      tableBody = (
        <VisitorTableBody
          cols={props.headCells}
          page={page}
          classes={classes}
          setLoading={(loading: boolean) => setIsLoading(loading)}
          setNextPageDisabled={setNextPageDisabled}
        />
      );
      break;
    case "account":
      tableBody = (
        <AccountTableBody
          cols={props.headCells}
          page={page}
          rowsPerPage={rowsPerPage}
          classes={classes}
          setLoading={(loading: boolean) => setIsLoading(loading)}
          setNextPageDisabled={setNextPageDisabled}
        />
      );
      break;
    case "npsResponses":
      tableBody = (
        <ResponseTableBody
          cols={props.headCells}
          page={page}
          rowsPerPage={rowsPerPage}
          classes={classes}
          setLoading={(loading: boolean) => setIsLoading(loading)}
          setNextPageDisabled={setNextPageDisabled}
        />
      );
  }

  let settingsColumns;
  switch (props.type) {
    case "visitor":
      settingsColumns = visitorCells;
      break;
    case "npsResponses":
      settingsColumns = responseCells;
      break;
    case "account":
      settingsColumns = accountCells;
      break;
  }

  if (props.allColumns) settingsColumns = props.allColumns;

  return (
    <Card
      className={classes.root}
      style={
        props.forceWidth
          ? { width: props.maxWidth }
          : { maxWidth: props.maxWidth }
      }
    >
      <CardContent>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6">{props.title}</Typography>
          <Settings
            allColumns={settingsColumns}
            context={props.context}
            type={props.type}
          />
        </Toolbar>
        <TableContainer>
          <MuiTable
            size="medium"
            aria-label="visitor table"
            className={classes.table}
          >
            <EnhancedTableHead
              order={filter.order}
              orderBy={filter.propertyID}
              onRequestSort={handleRequestSort}
              classes={classes}
              headCells={props.headCells}
            />
            <TableBody>{tableBody}</TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={999999}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `Showing ${from} - ${to}`
          }
          nextIconButtonProps={{ disabled: nextPageDisabled }}
        />
      </CardContent>
      {isLoading && <LinearProgress />}
    </Card>
  );
};

export default Table;
