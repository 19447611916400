import React from "react";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

/**
 * Hook for displaying a toggle button (toggles between "Any" and "All")
 * Is called at the start of each criteria group's render method
 * @param rowClass classname for the row housing this component
 * @param buttonClass classname for the toggle buttons when they are selected
 * @param deleteable whether or not a delete button should be displayed
 */
const useToggle = (
  rowClass: string,
  buttonClass: string,
  deleteable: boolean,
  handleDelete: () => void,
  defaultValue?: "All" | "Any",
  accountMode?: boolean
): ["All" | "Any", JSX.Element] => {
  const [condition, setCondition] = React.useState<"All" | "Any">(
    defaultValue || "All"
  );
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const typeText = accountMode ? "Accounts" : "Visitors";

  const output = (
    <div className={rowClass}>
      <ToggleButtonGroup
        value={condition}
        exclusive
        onChange={(e, newCondition) =>
          newCondition && setCondition(newCondition)
        }
        aria-label="Toggle group for choosing the condition"
      >
        <ToggleButton value="All" classes={{ selected: buttonClass }}>
          All
        </ToggleButton>
        <ToggleButton value="Any" classes={{ selected: buttonClass }}>
          Any
        </ToggleButton>
      </ToggleButtonGroup>

      <Typography style={{ fontSize: 12 }} color="textSecondary">
        {" "}
        {condition === "All"
          ? typeText + " must meet all of the rules in this group"
          : typeText + " must meet one or more of the rules in this group"}
      </Typography>
      {deleteable && (
        <IconButton onClick={() => setDeleteModalOpen(true)}>
          <DeleteIcon />
        </IconButton>
      )}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to delete this rule group?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleDelete();
              setDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  return [condition, output];
};

export default useToggle;
