import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { tags } from "./TagTable";
import { useApi } from "../../queries/util";
import { ISegmentNpsData } from "../../queries/types";
import { getSegmentNpsRequest } from "../../queries/npsRequests";
import React from "react";
import Loading from "../Loading";
import { Typography } from "@material-ui/core";
import ErrorSnackbar from "../ErrorSnackbar";

interface ISegmentTable {
  classes: any;
  days: number;
}

/**
 *
 * @param props css classes object, the level of detail, and whether or not to show percent values
 */
const SegmentTable = (props: ISegmentTable) => {
  const {
    isLoading,
    data: segments,
    error: segmentError,
  } = useApi<ISegmentNpsData>(getSegmentNpsRequest(props.days));
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (segmentError) setError(segmentError.message);
  }, [segmentError]);

  if (isLoading) return <Loading />;

  return (
    <TableContainer>
      <Table aria-label="tags-table">
        {segments ? (
          <>
            <TableHead>
              <TableRow>
                <TableCell component="th">Segment Name</TableCell>
                <TableCell component="th">NPS Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(segments).map((segmentName: string, i) => (
                <TableRow
                  className={props.classes.alternatingTable}
                  key={`${segmentName}_${i}`}
                >
                  <TableCell>{segmentName}</TableCell>
                  <TableCell>{segments[segmentName]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ alignSelf: "center" }}
          >
            <i>No segment data could be found</i>
          </Typography>
        )}
      </Table>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </TableContainer>
  );
};

export default SegmentTable;
