import React from "react";
import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
import { useApi } from "../../queries/util";
import { INpsPoint } from "../../queries/types";
import { getNpsGraphDataRequest } from "../../queries/npsRequests";
import { IPlotPoint } from "./NpsResponses";
import ErrorSnackbar from "../../Components/ErrorSnackbar";
import { ITheme } from "../../config/Theme";
import Loading from "../../Components/Loading";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    cardTitle: {
      color: theme.palette.contrast.main,
    },
  })
);

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/**
 * Gets a label for a provided point
 * @param {INpsPoint} point The target point's data
 * @returns {string} A label for the provided point
 */
const getGraphLabel = (point: INpsPoint): string => {
  if (point.month && point.day) {
    return `${point.year}-${String(point.month).padStart(2, "0")}-${String(
      point.day
    ).padStart(2, "0")}`;
  } else if (point.month) {
    return `${monthNames[point.month - 1]} ${point.year}`;
  } else if (point.week) {
    return `W${point.week} ${point.year}`;
  } else if (point.quarter) {
    return `Q${point.quarter} ${point.year}`;
  }
  return "";
};

interface IResponseGraph {
  timeframe: number;
}

const ResponseGraph = (props: IResponseGraph) => {
  const classes = useStyles();
  const theme = useTheme();
  const onIPad = useMediaQuery(theme.breakpoints.only("sm"));

  const [formattedGraphData, setFormattedGraphData] = React.useState<
    IPlotPoint[]
  >([]);
  const [error, setError] = React.useState<string | null>(null);

  const {
    isLoading: isLoadingGraph,
    data: graphData,
    error: graphError,
  } = useApi<INpsPoint[]>(getNpsGraphDataRequest(props.timeframe));

  React.useEffect(() => {
    if (graphData) {
      setFormattedGraphData(
        graphData.map((point) => ({
          name: getGraphLabel(point),
          score: point.npsScore,
        }))
      );
    }
  }, [graphData]);

  // if there is an error, set the error message
  React.useEffect(() => {
    if (graphError) setError(graphError.message);
  }, [graphError]);

  const chartWidth = onIPad
    ? window.innerWidth - theme.spacing(8) - (theme as ITheme).drawerWidth
    : Math.min(700, window.innerWidth - theme.spacing(8));

  return (
    <Card>
      <CardContent
        style={{
          width: chartWidth + 25,
        }}
      >
        <Typography variant="subtitle2" className={classes.cardTitle}>
          NPS Score Over Time
        </Typography>
        {isLoadingGraph ? (
          <Loading />
        ) : formattedGraphData.length > 0 ? (
          <AreaChart
            // if the user is on an ipad,
            //    the graph width is equal to the total width - the drawer width - the side margins
            // otherwise
            //    the graph width is equal to whichever is smaller:
            //    700 (for desktop) or the total width - side margins (for phones)
            width={chartWidth}
            height={184}
            margin={{ top: 10, right: 30, left: -24, bottom: 0 }}
            data={formattedGraphData}
          >
            <defs>
              <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={1}
                />
                <stop offset="33%" stopColor="#b4c400" stopOpacity={1} />
                <stop offset="50%" stopColor="#edc00c" stopOpacity={1} />
                <stop offset="75%" stopColor="#ff7575" stopOpacity={1} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" allowDataOverflow interval={0} />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="score"
              stroke="#aaa"
              fillOpacity={1}
              fill="url(#colorGreen)"
            />
          </AreaChart>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ alignSelf: "center" }}
          >
            <i>No NPS data found for the specified timeframe</i>
          </Typography>
        )}
      </CardContent>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </Card>
  );
};

export default ResponseGraph;
