import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { ISegmentMetadata } from "../queries/types";
import { Skeleton } from "@material-ui/lab";
import Loading from "../Components/Loading";
import ErrorSnackbar from "../Components/ErrorSnackbar";
import { useApi } from "../queries/util";
import { segmentsRequest } from "../queries/npsRequests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
    },
    title: {
      fontWeight: 400,
      marginBottom: theme.spacing(3),
    },
    tableRow: {
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
    },
    newSegmentButton: {
      marginBottom: theme.spacing(1),
      float: "right",
    },
    link: {
      textDecoration: "none",
    },
  })
);

const Segments = () => {
  const {
    isLoading,
    data: segments,
    error: segmentsError,
  } = useApi<{
    segmentList: ISegmentMetadata[];
    visitorCount: { [key: string]: null };
  }>(segmentsRequest);
  const [makingSegment, setMakingSegment] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (segmentsError) setError(segmentsError.message);
  }, [segmentsError]);

  const createSegment = async () => {
    setMakingSegment(true);
    const response = await fetch("/api/v1/nps/segments", {
      method: "post",
    });
    if (response.status !== 200) {
      setMakingSegment(false);
      return setError(
        "Something went wrong while trying to make a new segment. Please try again later."
      );
    }
    const json = await response.json();
    history.push(`/create-segment/${json.segmentID}`);
  };

  if (makingSegment) return <Loading />;
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Manage Segments
      </Typography>
      <Card>
        <CardContent>
          <Button
            color="primary"
            className={classes.newSegmentButton}
            onClick={createSegment}
          >
            Create new segment
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Segment Name</TableCell>
                  <TableCell component="th" align="right">
                    # Visitors Last 7 Days
                  </TableCell>
                  <TableCell component="th" align="right">
                    # Visitors Last 30 Days
                  </TableCell>
                  <TableCell component="th" aria-label="edit"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [...new Array(4)].map((e, i) => (
                      <TableRow
                        key={`segmentSkeleton${i}`}
                        className={classes.tableRow}
                      >
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))
                  : segments?.segmentList.map((segment, i) => (
                      <TableRow
                        key={`segmentSkeleton${i}`}
                        className={classes.tableRow}
                      >
                        <TableCell>{segment.segmentName}</TableCell>
                        <TableCell align="right">
                          {
                            segments.visitorCount[
                              `${segment.segmentName} last 7 days`
                            ]
                          }
                        </TableCell>
                        <TableCell align="right">
                          {
                            segments.visitorCount[
                              `${segment.segmentName} last 30 days`
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/edit-segment/${segment.segmentID}`}
                            className={classes.link}
                          >
                            <Button color="primary">Edit</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default Segments;
