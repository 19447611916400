import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { useLinks } from "../config/Styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CriteriaBuilder from "../Components/Segments/CriteriaBuilder";
import { useQueryClient } from "react-query";
import Loading from "../Components/Loading";
import { ISegment } from "../queries/types";
import { useApi } from "../queries/util";
import { getSegmentRequest } from "../queries/npsRequests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1400,
      display: "flex",
      flexDirection: "column",
    },
    titleSection: {
      display: "flex",
      alignItems: "center",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    headerInput: {
      fontSize: "2.0243rem",
      fontWeight: 700,
      lineSpacing: "0.00735em",
      maxWidth: 300,
    },
    breadcrumbs: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Component that houses all edit segment forms
 */
const EditSegment = () => {
  const queryClient = useQueryClient();
  // Get the segment id from the url parameter
  const { segmentID } = useParams<{ segmentID: string | undefined }>();
  const {
    isLoading,
    data: segment,
    error: segmentError,
    statusCode: segmentStatus,
  } = useApi<ISegment>(getSegmentRequest(segmentID as string));
  // TODO: connect this to react query to display last save date
  const [segmentName, setSegmentName] = React.useState<string>();
  const [lastSaved, setLastSaved] = React.useState<Date>();
  const [lastSavedText, setLastSavedText] = React.useState<string>("");
  const [editingTitle, setEditingTitle] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const classes = useStyles();
  const links = useLinks();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  // start a clock to compare time since last save
  React.useEffect(() => {
    const timerID = setInterval(() => {
      if (lastSaved) {
        const difference = new Date().getTime() - lastSaved.getTime();
        const minutes = difference / 1000 / 60;
        const seconds = difference / 1000;
        // less than a minute ago
        if (minutes < 1) setLastSavedText(`${seconds.toFixed(0)} seconds ago`);
        else if (minutes < 60)
          setLastSavedText(`${minutes.toFixed(0)} minutes ago`);
        else if (minutes / 60 < 24)
          setLastSavedText(`${(minutes / 60).toFixed(0)} hours ago`);
        else setLastSavedText(lastSaved.toISOString().split("T")[0]);
      }
    }, 1 * 1000);
    return () => clearInterval(timerID);
  }, [lastSaved]);

  React.useEffect(() => {
    if (segmentError) setError(segmentError.message);
    if (segment) {
      setSegmentName(segment.segmentName);
    }
  }, [segmentError, segment]);

  const handleNameChange = (e: any) => {
    if (segment) {
      setSegmentName(e.target.value);
    }
  };

  const handleSaveSegmentName = async () => {
    if (segmentName) {
      // TODO: save
      const response = await fetch(`/api/v1/nps/segments/${segmentID}/name`, {
        method: "put",
        body: JSON.stringify({ segmentName: segmentName }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let json;
      if (response.status === 401) queryClient.invalidateQueries("user");
      else if (response.status === 422) {
        json = await response.json();
        setError(json.err);
      } else if (response.status !== 200) {
        setError("Something went wrong. Please try again later.");
      }
      setEditingTitle(false);
    }
  };

  if (!segmentID) return <Redirect to="/segments" />;
  if (isLoading)
    return (
      <Loading
        style={{
          height: "calc(100vh - 100px)",
          display: "flex",
          alignItems: "center",
          marginTop: "-32px",
        }}
      />
    );
  if (!isLoading && segmentStatus && segmentStatus === 404)
    return <Redirect to="/segments" />;
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        <Link to="/segments" className={links.root}>
          Segments
        </Link>
        <Typography color="textPrimary">
          {pathname === "edit-segment" ? "Edit Segment" : "Create Segment"}
        </Typography>
      </Breadcrumbs>
      <div className={classes.titleSection}>
        {/* if the title is being edited, render an input, otherwise, render the title */}
        {editingTitle ? (
          <>
            <TextField
              aria-label="edit theme name"
              inputProps={{ className: classes.headerInput }}
              value={segmentName}
              onChange={handleNameChange}
            />
            <Button
              color="primary"
              onClick={handleSaveSegmentName}
              disabled={segmentName?.length === 0}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4">{segmentName}</Typography>

            <Tooltip title="Edit theme name">
              <IconButton color="primary" onClick={() => setEditingTitle(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Typography color="textSecondary" style={{ fontSize: 12 }}>
          {/* TODO: hook this value up to the state */}
          Last saved {lastSavedText}
        </Typography>
      </div>
      {segment && (
        <CriteriaBuilder
          criteriaID={segment.criteriaID}
          criteria={segment.criteriaJson || null}
          setLastSaved={() => setLastSaved(new Date())}
          showCard
        />
      )}
    </div>
  );
};

export default EditSegment;
