import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ITheme as IMuiTheme } from "../config/Theme";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CssEditor from "../Components/Themes/CssEditor";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ISurvey, ITheme } from "../queries/types";
import PointAndClick from "../Components/Themes/PointAndClick";
import jsonToCss, { ICssObject } from "../Components/Themes/util/jsonToCss";
import EditIcon from "@material-ui/icons/Edit";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useLinks } from "../config/Styles";
import Preview from "../Components/SurveyPreview";
import { useQueryClient } from "react-query";
import Loading from "../Components/Loading";
import ErrorSnackbar from "../Components/ErrorSnackbar";
import { useApi } from "../queries/util";
import { getThemeRequest } from "../queries/npsRequests";
import useFonts from "../hooks/useFonts";
import cssToObject from "../Components/Themes/util/cssToObject";

const defaultSurvey: ISurvey = {
  surveyName: "Test survey",
  surveyQuestion:
    "How likely are you to recommend this product to a friend or colleague?",
  followUpQuestions: [
    {
      followUpQuestion: "Why did you give that response?",
      minRating: 0,
      maxRating: 10,
    },
  ],
  notLikelyText: "Not likely",
  veryLikelyText: "Very likely",
  remindMeLaterText: "Remind me later",
  closeWindowText: "Close",
  submitButtonText: "Submit",
  backButtonText: "Back",
  themeID: 0,
  criteriaID: 0,
  displayFrequencyDays: 90,
  remindMeLaterDays: 7,
  visitorSessionPages: 0,
  samplePercent: 100,
  nps_surveyID: 0,
  thankYouMessage: "Thank you for your response",
  maxRating: 10,
  minRating: 0,
  maxDetractorRating: 6,
  minPromoterRating: 8,
  surveyOrder: 0,
};

const useStyles = makeStyles((theme: IMuiTheme) =>
  createStyles({
    outerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      // set width to the remaining space on the screen (100vw - the width of the sidebar - side margins)
      width: `calc(100vw - ${theme.drawerWidth}px - ${theme.spacing(6)}px)`,
      maxWidth: "min(1600px, 100vw)",
    },
    previewContainer: {
      flex: 3,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    header: {
      height: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    headerInput: {
      fontSize: "2.0243rem",
      fontWeight: 700,
      lineSpacing: "0.00735em",
    },
    lastSavedContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    backdrop: {
      width: "100%",
      padding: theme.spacing(3),
      flex: 1,
      marginTop: theme.spacing(3),
    },
    editorContainer: {
      marginLeft: theme.spacing(3),
      flex: 2,
      // set the height to the remaining space on the page (total height - app bar - top and bottom margin)
      height: `calc(100vh - ${theme.spacing(16 + 3 + 3)}px)`,
      overflow: "scroll",
      minWidth: 415,
      // maxHeight: 100,
    },
    buttonRow: {
      display: "flex",
      "& > *+*": {
        marginLeft: theme.spacing(3),
      },
      marginBottom: theme.spacing(1),
    },
    titleSection: {
      display: "flex",
      alignItems: "center",
    },
    tabContainer: {
      height: theme.spacing(4),
      width: "100%",
    },
    cssEditor: {
      padding: 0,
      // set the height to the height of the container minus the height of the tab container
      height: `calc(100% - ${theme.spacing(4)}px)`,
    },
    breadcrumbs: {
      marginBottom: theme.spacing(2),
    },
  })
);

const EditNpsTheme = () => {
  const queryClient = useQueryClient();
  // split the location into [empty value, pathname, theme id]
  const location = useLocation().pathname.split("/");
  const pathname = location[1];
  const id = Number(location[2]);
  const {
    isLoading,
    data: theme,
    error: themeError,
    statusCode: themeStatus,
  } = useApi<ITheme>(getThemeRequest(id.toString()));
  const [error, setError] = React.useState<string | null>(null);
  const [css, setCss] = React.useState<ICssObject>();
  const [tab, setTab] = React.useState<number>(0);
  const [editingThemeName, setEditingThemeName] =
    React.useState<boolean>(false);
  const [themeName, setThemeName] = React.useState<string>(
    theme?.themeName || ""
  );
  const [lastSaved, setLastSaved] = React.useState<Date>();
  const [lastSavedText, setLastSavedText] = React.useState<string>("");
  const classes = useStyles();
  const links = useLinks();
  const { fonts } = useFonts();

  React.useEffect(() => {
    if (themeError) setError(themeError.message);
  }, [themeError]);

  // set the app state to theme data when the theme finishes loading
  React.useEffect(() => {
    if (theme) {
      setThemeName(theme.themeName);
      setLastSaved(new Date(theme.lastModifiedDate));
      try {
        // console.log(cssToObject(theme.themeCSS));
        // console.log(theme.themeCSS);
        // console.log(cssToObject(theme.themeCSS));
        setCss(cssToObject(theme.themeCSS,fonts));
      } catch (err) {
        setError("Couldn't load css");
      }
    }
  }, [theme, fonts]);

  // start a clock to compare time since last save
  React.useEffect(() => {
    const timerID = setInterval(() => {
      if (lastSaved) {
        const difference = new Date().getTime() - lastSaved.getTime();
        const minutes = difference / 1000 / 60;
        // less than a minute ago
        if (minutes < 1) setLastSavedText("just a moment ago");
        else if (minutes < 60)
          setLastSavedText(`${minutes.toFixed(0)} minutes ago`);
        else if (minutes / 60 < 24)
          setLastSavedText(`${(minutes / 60).toFixed(0)} hours ago`);
        else setLastSavedText(lastSaved.toISOString().split("T")[0]);
      }
    }, 1 * 1000);
    return () => clearInterval(timerID);
  });

  // if a theme preview stylesheet hasn't been created yet,
  // create it and populate it with the css parsed from the css state object
  if (!document.getElementById("preview-stylesheet") && css) {
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    style.id = "preview-stylesheet";
    style.innerHTML = jsonToCss(css);
    head.appendChild(style);
  }
  // Whenever the css object is updated (which is only when it's valid),
  //  disable the publish button and save the css
  React.useEffect(() => {
    if (css && theme && fonts) {
      if (jsonToCss(css) === theme.themeCSS) return;
      const timeoutId = setTimeout(async () => {
        try {
          const backgroundColor =
            css[".zengage-container"].background ||
            css[".zengage-container"].backgroundColor;
          const secondaryColor =
            css[".zengage-button-secondary"].background ||
            css[".zengage-button-secondary"].backgroundColor;
          const tertiaryColor =
            css[".zengage-button-secondary:hover"].background ||
            css[".zengage-button-secondary:hover"].backgroundColor;

          // add font imports
          let cssString = jsonToCss(css);
          for (const font of fonts) {
            if (cssString.includes(font.embedCode)) continue;
            cssString = `@import url("${font.embedCode}");

${cssString}`;
          }

          const response = await fetch(`/api/v1/nps/themes/${id}/css`, {
            method: "PUT",
            body: JSON.stringify({
              themeCss: jsonToCss(css),
              backgroundColor,
              secondaryColor,
              tertiaryColor,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });
          let json;
          switch (response.status) {
            case 401:
              queryClient.invalidateQueries("user");
              break;
            case 422:
              json = await response.json();
              setError(json.err);
              break;
            case 200:
              setLastSaved(new Date());
              setLastSavedText("just a moment ago");
              queryClient.refetchQueries("queue");
              break;
            default:
              setError("An unexpected error occured. Please try again later.");
          }
        } catch (err: any) {
          setError(err.message);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [css, fonts]);

  React.useEffect(() => {
    const stylesheet = document.getElementById("preview-stylesheet");
    if (stylesheet && css) {
      stylesheet.innerHTML = jsonToCss(css);
    }
  }, [css]);

  const handleSaveThemeName = async () => {
    console.log("saving");
    setEditingThemeName(false);
    const response = await fetch(`/api/v1/nps/themes/${id}/name`, {
      method: "PUT",
      body: JSON.stringify({ themeName }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      if (theme) setThemeName(theme.themeName);
      setError("Something went wrong while updating this theme's name.");
    } else {
      queryClient.invalidateQueries("themes");
      setLastSaved(new Date());
      setLastSavedText("just a moment ago");
      queryClient.refetchQueries("queue");
    }
  };

  if (isLoading)
    return (
      <Loading
        style={{
          height: "calc(100vh - 100px)",
          display: "flex",
          alignItems: "center",
          marginTop: "-32px",
        }}
      />
    );

  if (!isLoading && themeStatus && themeStatus === 404)
    return <Redirect to="/nps-themes" />;
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        <Link to="/nps-themes" className={links.root}>
          NPS Themes
        </Link>
        <Typography color="textPrimary">
          {pathname === "edit-nps-theme"
            ? "Edit NPS Theme"
            : "Create NPS Theme"}
        </Typography>
      </Breadcrumbs>
      <div className={classes.outerContainer}>
        <div className={classes.previewContainer}>
          <div className={classes.header}>
            <div className={classes.titleSection}>
              {/* if the theme name is being edited, render an input, otherwise render the theme name */}
              {editingThemeName ? (
                <>
                  <TextField
                    aria-label="edit theme name"
                    inputProps={{ className: classes.headerInput }}
                    value={themeName}
                    onChange={(e) => setThemeName(e.target.value)}
                  />
                  <Button color="secondary" onClick={handleSaveThemeName}>
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h4">{themeName}</Typography>

                  <Tooltip title="Edit theme name">
                    <IconButton
                      color="secondary"
                      onClick={() => setEditingThemeName(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <div className={classes.lastSavedContainer}>
              <Typography color="textSecondary" style={{ fontSize: 12 }}>
                Last saved {lastSavedText ? lastSavedText : "..."}
              </Typography>
            </div>
          </div>

          <div id="backdrop" className={classes.backdrop}>
            <Preview survey={defaultSurvey} />
          </div>
        </div>
        <Card className={classes.editorContainer}>
          <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
            variant="fullWidth"
            aria-label="change the type of style editor"
            classes={{
              root: classes.tabContainer,
            }}
          >
            <Tab label="Point and Click" />
            <Tab label="CSS" />
          </Tabs>

          <CardContent className={tab === 1 ? classes.cssEditor : ""}>
            {tab === 0
              ? css && (
                  <PointAndClick
                    css={css as ICssObject}
                    setCss={
                      setCss as React.Dispatch<React.SetStateAction<ICssObject>>
                    }
                  />
                )
              : css && (
                  <CssEditor
                    css={css as ICssObject}
                    setCss={
                      setCss as React.Dispatch<React.SetStateAction<ICssObject>>
                    }
                  />
                )}
          </CardContent>
        </Card>
      </div>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default EditNpsTheme;
