import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ITag } from "../../queries/types";
import { Skeleton } from "@material-ui/lab";

interface ITagCard {
  isSystemTag?: boolean;
  classes: any;
  tagData: ITag[] | null;
}

const TagCard = (props: ITagCard) => {
  const { classes } = props;
  const [tags, setTags] = React.useState<ITag[] | null>(props.tagData);

  React.useEffect(() => setTags(props.tagData), [props.tagData]);

  const changeVisibility = async (i: number) => {
    if (tags) {
      const chosenTag = tags[i];
      if (chosenTag) {
        // update tag
        console.log(chosenTag.hidden);
        const response = await fetch(
          `/api/v1/settings/tags/${chosenTag.nps_tagID}`,
          {
            method: "put",
            body: JSON.stringify({ hidden: !chosenTag.hidden }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.status);
      }

      // update local state
      const clone = [...tags];
      clone[i].hidden = !clone[i].hidden;
      setTags(clone);
    }
  };

  // Table heads
  const systemTagsTableHead = (
    <TableHead>
      <TableCell component="th">System Tag Name</TableCell>
      <TableCell component="th">Visible?</TableCell>
      <TableCell component="th"># of Responses Tagged</TableCell>
    </TableHead>
  );

  const customTagTableHead = (
    <TableHead>
      <TableCell component="th">Tag Name</TableCell>
      <TableCell component="th">Visible?</TableCell>
      <TableCell component="th"># of Responses Tagged</TableCell>
    </TableHead>
  );

  return (
    <Card className={classes.customTagContainer}>
      <CardContent>
        <Typography variant="h6" className={classes.cardTitle}>
          {props.isSystemTag ? "System Tags" : "Custom Tags"}
        </Typography>
        {tags && tags.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            <i>No responses have been tagged yet.</i>
          </Typography>
        ) : (
          <Table aria-label="Custom tags table">
            {props.isSystemTag ? systemTagsTableHead : customTagTableHead}
            <TableBody>
              {!props.tagData
                ? new Array(3).fill(2).map((i) => (
                    <TableRow className={classes.alternatingTableRow}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))
                : tags?.map((tag, i) => (
                    <TableRow className={classes.alternatingTableRow}>
                      {tag.npsTagName && (
                        <TableCell>
                          {/* <TextField
                          defaultValue={tag.npsTagName}
                          inputProps={{ className: classes.displayNameInput }}
                          // fullWidth
                        /> */}
                          {tag.npsTagName}
                        </TableCell>
                      )}
                      <TableCell>
                        {/* visibility radio buttons */}
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            aria-label="Visibility radio buttons"
                          ></FormLabel>
                          <RadioGroup
                            row
                            aria-label="visibility"
                            value={!Boolean(tag.hidden)}
                            onChange={() => changeVisibility(i)}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="secondary" />}
                              label={
                                <Typography variant="body2">Visible</Typography>
                              }
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="secondary" />}
                              label={
                                <Typography variant="body2">Hidden</Typography>
                              }
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell>{tag.numTagAssignments}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default TagCard;
