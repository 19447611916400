// Account properties and corresponding metadata
import { HeadCell } from "../TableHead";

interface IAccountCells {
  accountLastSeen: HeadCell;
  accountName: HeadCell;
  organizationAccountID: HeadCell;
  accountCreated: HeadCell;
  accountFirstSeen: HeadCell;
}

export const accountCells: { [key: string]: HeadCell } = {
  accountLastSeen: {
    id: "accountLastSeen",
    label: "Last Seen",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  accountName: {
    id: "accountName",
    label: "Account Name",
    placeholder: "Test account",
    property_typeName: "String",
  },
  organizationAccountID: {
    id: "organizationAccountID",
    label: "Account ID",
    placeholder: "TCA",
    property_typeName: "String",
  },
  accountCreated: {
    id: "accountCreated",
    label: "Created",
    helpText: "When this account was created in your application",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  accountFirstSeen: {
    id: "accountFirstSeen",
    label: "First Seen",
    helpText: "The first time a visitor for this account was seen by Engagify",
    placeholder: "1/1/2021",
    property_typeName: "Date",
  },
  npsRating: {
    id: "npsRating",
    label: "NPS Rating",
    placeholder: "8",
    property_typeName: "Number",
  },
  previousNpsRating: {
    id: "previousNpsRating",
    label: "Previous NPS Rating",
    placeholder: "6",
    property_typeName: "Number",
  },
  npsRatingDifference: {
    id: "npsRatingDifference",
    label: "Rating +/-",
    placeholder: "2",
    property_typeName: "Number",
  },
  npsScore: {
    id: "npsScore",
    label: "NPS Score",
    placeholder: "8",
    property_typeName: "Number",
  },
  previousNpsScore: {
    id: "previousNpsScore",
    label: "Previous NPS Score",
    placeholder: "6",
    property_typeName: "Number",
  },
  npsScoreDifference: {
    id: "npsScoreDifference",
    label: "Score +/-",
    placeholder: "2",
    property_typeName: "Number",
  },
  numVisitors: {
    id: "numVisitors",
    label: "# of Visitors",
    placeholder: "100",
    property_typeName: "Number",
  },
};

/**
 * Maps a list of account property names to a list of table head cells (columns)
 * @param {(keyof IAccountCells)[]} properties The list of properties to convert into table columns
 * @returns {HeadCell[]} An array of properties and their corresponding metadata to add to the table's header row
 */
export const mapAccountPropertiesToHeadCells = (
  properties: (keyof IAccountCells)[]
): HeadCell[] => properties.map((property) => accountCells[property]);

export default accountCells;
