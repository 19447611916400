import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useQueryClient } from "react-query";
import { ITheme } from "../../../config/Theme";
import { publishingHistoryRequest } from "../../../queries/publishingRequests";
import { IPublishedHistory, IQueue } from "../../../queries/types";
import { useApi } from "../../../queries/util";
import { formatHistory } from "./publishingUtil";
import PublishingPage from "./Steps/PublishingPage";
import PublishToLive from "./Steps/PublishToLive";
import PublishToTest from "./Steps/PublishToTest";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      padding: "8px 16px 16px 16px",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      color: theme.palette.contrast.dark,
      fontWeight: 400,
    },
    changesContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRight: "3px solid rgba(0,0,0,0.1)",
      minHeight: 264,
      marginRight: 16,
      paddingRight: 16,
    },
    subtitle: {
      color: theme.palette.contrast.main,
      width: "100%",
      // borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    changesColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "50vw",
      overflowY: "scroll",
    },
    changesActions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    changesSelectedText: {
      color: theme.palette.grey[600],
      fontStyle: "italic",
      flex: 1,
    },
  })
);

const PublishingForm = ({onClose}: {onClose: () => void}) => {
  const queryClient = useQueryClient();
  const queue = queryClient.getQueryData<IQueue>("queue");
  const {
    isLoading,
    data: history,
    refetch,
  } = useApi<IPublishedHistory>(publishingHistoryRequest);
  const classes = useStyles();
  const [testingItems, setTestingItems] = React.useState<IPublishedHistory>();
  const [step, setStep] = React.useState<number>(0);

  React.useEffect(() => {
    if (!isLoading && history) {
      const formattedHistory = formatHistory(history);
      setTestingItems(formattedHistory.test);
    }
  }, [isLoading, history]);

  const loadTest = async () => {
    await refetch();
    await queryClient.refetchQueries("queue");
    setStep(1);
  };

  const loadLive = async () => {
    await refetch();
    await queryClient.refetchQueries("queue");
    setStep(2);
  };

  return (
    <>
      <DialogTitle>Publishing Manager</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.changesColumn}>
            {step === 0 && queue && (
              <PublishToTest queue={queue} next={loadTest} />
            )}
            {step === 1 && testingItems && (
              <PublishToLive items={testingItems} next={loadLive} />
            )}
            {step === 2 && queue && testingItems && (
              <PublishingPage queue={queue} testingItems={testingItems} onClose={onClose} />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
};

export default PublishingForm;
