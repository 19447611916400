import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "../DialogTitle";
import {
  IAccountProperty,
  IPropertyData,
  IVisitorProperty,
} from "../../queries/types";
import {
  getAccountRequest,
  getVisitorRequest,
} from "../../queries/settingsRequests";
import { useApi } from "../../queries/util";
import Loading from "../Loading";

interface ICustomPropertyValue {
  value: string | number;
  occurrences: number;
  lastSeen: string;
}

interface ICustomProperty {
  displayName: string;
  systemName: string;
  values: ICustomPropertyValue[];
}

interface IViewPropertyModal {
  onClose: () => void;
  open: boolean;
  classes: any;
  type: "visitor" | "account";
  index: number | null;
  displayName: string | null;
  systemName: string | null;
}

const ViewPropertyModal = (props: IViewPropertyModal) => {
  const [limit, setLimit] = React.useState<number>(10);
  const [lastIndex, setLastIndex] = React.useState<number | null>(null);
  const {
    isLoading,
    data: propertyData,
    error,
    refetch,
  } = useApi<IPropertyData[]>(
    props.type === "visitor"
      ? getVisitorRequest(props.index || 0, limit)
      : getAccountRequest(props.index || 0, limit)
  );

  // reset the limit everytime a new property is seen
  React.useEffect(() => {
    if (props.index !== lastIndex && props.index !== null) {
      setLimit(10);
    }
    if (props.index !== null) setLastIndex(props.index);
  }, [props.index]);

  /**
   * Refetch each time the limit is changed
   */
  React.useEffect(() => {
    if (props.index !== null) refetch();
  }, [limit]);

  /**
   * Loads more examples
   */
  const loadMore = () => {
    setLimit((oldLimit) => oldLimit + 50);
  };

  if (!props.open) return null;
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: props.classes.modal }}
    >
      <DialogTitle onClose={props.onClose}>
        {props.displayName} Examples ({props.systemName})
      </DialogTitle>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Typography color="error">{error.message}</Typography>
      ) : (
        <>
          <DialogContent>
            <Table aria-label="Custom property examples">
              <TableHead>
                <TableCell component="th">Value</TableCell>
                <TableCell component="th"># of occurrences</TableCell>
                <TableCell component="th">Last Seen</TableCell>
              </TableHead>
              <TableBody>
                {propertyData?.map((value) => (
                  <TableRow
                    className={props.classes.alternatingTableRow}
                    key={`${value.value}_example`}
                  >
                    <TableCell>{value.value}</TableCell>
                    <TableCell>{value.numOccurrences}</TableCell>
                    <TableCell>
                      {value.lastSeen.toString().split("T")[0]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions
            style={{ flexDirection: "column", alignContent: "space-between" }}
          >
            <div className={props.classes.examplesShownRow}>
              <Typography variant="body2">
                {propertyData?.length} examples shown
              </Typography>
              {/* Only display "load more" if maximum amount of examples have been loaded */}
              {propertyData && propertyData.length === limit && (
                <Button
                  color="secondary"
                  style={{ flex: 1 }}
                  onClick={loadMore}
                >
                  Load more
                </Button>
              )}
              <Button color="secondary">Export to CSV</Button>
            </div>
            <div style={{ alignSelf: "end" }}>
              <Button color="secondary" onClick={props.onClose}>
                Close
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ViewPropertyModal;
