import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SegmentTable from "./SegmentTable";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DialogTitle from "../DialogTitle";

interface ISegmentModal {
  onClose: () => void;
  open: boolean;
  classes: any;
}

/**
 * Component for displaying a segment data modal containing a more detailed segment data table
 * @param {ISegmentModal} props css classes object, onClose handler, and whether or not the modal should be open
 */
const SegmentModal = (props: ISegmentModal) => {
  const [showPercent, setShowPercent] = React.useState<boolean>(false);
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: props.classes.modal }}
    >
      <DialogTitle onClose={props.onClose}>Segment Data</DialogTitle>
      <DialogContent>
        <FormGroup row style={{ float: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={showPercent}
                onChange={() => setShowPercent(!showPercent)}
                name="showPercent"
              />
            }
            label="Show percent values"
          />
        </FormGroup>
        <SegmentTable classes={props.classes} days={365} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SegmentModal;
