import React from "react";
import TextField from "@material-ui/core/TextField";
import { ISurvey } from "../queries/types";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

const usePreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    preview: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      textAlign: "center",
      width: "100%",
      minWidth: 600,
      margin: "auto",
    },
    ratingRow: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      padding: "4px 8px",
      background: "#fff",
      border: 0,
      margin: "0px 4px",
      color: "#039be5",
      fontWeight: 600,
      borderRadius: 4,
      "&:hover": {
        background: "#007fbd",
        color: "#fff",
        cursor: "pointer",
      },
      transition: "all 0.125s",
    },
    buttonContainer: {
      margin: "0px 8px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
      paddingBottom: theme.spacing(2),
      minHeight: 264,
      background: "#039be5",
      color: "#fff",
      borderRadius: 8,
      transition: "all 0.5s",
    },
    textFieldContainer: {
      width: 400,
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      background: "rgba(255,255,255,0.1)",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textFieldRoot: {
      color: "#fff",
    },
    logo: {
      alignSelf: "flex-end",
      justifySelf: "flex-end",
      display: "flex",
      alignItems: "baseline",
      "& > * + *": {
        marginLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
      },
    },
    backButton: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flex: 1,
    },
    tabContainer: {
      height: theme.spacing(4),
    },
    selectedTab: {
      background: theme.palette.primary.main,
      borderRadius: "8px 8px 0px 0px",
    },
    tab: {
      color: theme.palette.contrast.main,
    },
    indicator: {
      backgroundColor: "rgba(0,0,0,0)",
    },
    topRow: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  })
);

/**
 * Component that displays a preview of the survey being edited
 * NOTE: also used in theme editor
 * @param {survey: ISurvey} props Survey data
 */
const Preview = ({ survey }: { survey: ISurvey }) => {
  const [tab, setTab] = React.useState<number>(0);
  const classes = usePreviewStyles();

  const getButtons = () => {
    // Create an array of numbers ranging from survey.minRating to survey.maxRating
    const ratingRange = [...Array(survey.maxRating - survey.minRating + 1)].map(
      (empty: null, i) => i + survey.minRating
    );

    return (
      <div className={classes.buttonContainer}>
        {ratingRange.map((rating) => (
          <button
            className={`zengage-button-primary ${classes.button}`}
            key={`nps-${rating}`}
          >
            {rating}
          </button>
        ))}
      </div>
    );
  };

  const surveyPreview = (
    <div className={`${classes.container} zengage-container`}>
      <div className={classes.topRow}>
        <Button
          color="inherit"
          className={`zengage-button-secondary ${classes.backButton}`}
          onClick={() => setTab(0)}
        >
          {survey.remindMeLaterText}
        </Button>
        <Button
          color="inherit"
          className={`zengage-button-secondary ${classes.backButton}`}
          startIcon={<CloseIcon />}
        >
          {survey.closeWindowText}
        </Button>
      </div>
      <div className={classes.content}>
        <Typography color="inherit" className="zengage-text-header">
          {survey.surveyQuestion}
        </Typography>
        <div className={classes.ratingRow}>
          <Typography color="inherit" className="zengage-text-body">
            {survey.notLikelyText}
          </Typography>
          {getButtons()}

          <Typography color="inherit" className="zengage-text-body">
            {survey.veryLikelyText}
          </Typography>
        </div>
      </div>
      <div className={classes.logo}>
        <Typography style={{ fontSize: 12 }} className="zengage-text-header">
          powered by
        </Typography>
        <Typography
          color="inherit"
          variant="subtitle2"
          className="zengage-text-header"
        >
          Zengage
        </Typography>
      </div>
    </div>
  );

  const feedbackPreview = (
    <div className={`${classes.container} zengage-container`}>
      <div className={classes.topRow}>
        <Button
          color="inherit"
          className={`zengage-button-secondary ${classes.backButton}`}
          startIcon={<ArrowBackIosIcon />}
          onClick={() => setTab(0)}
        >
          {survey.backButtonText}
        </Button>
        <Button
          color="inherit"
          className={`zengage-button-secondary ${classes.backButton}`}
          startIcon={<CloseIcon />}
        >
          {survey.closeWindowText}
        </Button>
      </div>

      <div className={classes.content}>
        <Typography color="inherit" id="preview-follow-up">
          {survey.followUpQuestions[0].followUpQuestion}
        </Typography>
        <div className={classes.textFieldContainer}>
          <TextField
            aria-labelledby="preview-follow-up"
            multiline
            rows={3}
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.textFieldRoot }}
          />
          <Button
            color="inherit"
            style={{ alignSelf: "flex-start" }}
            className="zengage-button-secondary"
          >
            {survey.submitButtonText}
          </Button>
        </div>
      </div>
      <div className={classes.logo}>
        <Typography style={{ fontSize: 12 }} className="zengage-text-header">
          powered by
        </Typography>
        <Typography
          color="inherit"
          variant="subtitle2"
          className="zengage-text-header"
        >
          Engagify
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.preview}>
      <div>
        <Tabs
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          aria-label="Preview tabs"
          centered
          classes={{ indicator: classes.indicator, root: classes.tabContainer }}
        >
          <Tab
            label="Survey Preview"
            id="preview-survey-tab"
            aria-controls="preview-survey"
            classes={{ selected: classes.selectedTab, root: classes.tab }}
          />
          <Tab
            label="Feedback Preview"
            id="preview-feedback-tab"
            aria-controls="preview-feedback"
            classes={{ selected: classes.selectedTab, root: classes.tab }}
          />
        </Tabs>
      </div>
      {tab === 0 ? surveyPreview : feedbackPreview}
    </div>
  );
};

export default Preview;
