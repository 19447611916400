import React from "react";
import { ThemeProvider } from "@material-ui/core";
import EngagifyTheme from "./config/Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "./Components/Layout/AppBar";
import Sidenav from "./Components/Layout/Sidenav";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Visitors from "./Pages/Visitors";
import Login from "./Pages/Login";
import ChooseOrg from "./Pages/ChooseOrg";
import ProtectedRoute from "./Components/ProtectedRoute";
import { useUser } from "./queries/user";
import { useQuery, useQueryClient } from "react-query";
import Loading from "./Components/Loading";
import Accounts from "./Pages/Accounts";
import NpsResponses from "./Pages/NpsResponses/NpsResponses";
import NpsSurveys from "./Pages/NpsSurveys";
import EditNpsSurvey from "./Pages/EditNpsSurvey";
import Themes from "./Pages/Themes";
import EditNpsTheme from "./Pages/EditNpsTheme";
import Segments from "./Pages/Segments";
import EditSegment from "./Pages/EditSegment";
import CustomVisitorProperties from "./Pages/Settings/CustomVisitorProperties";
import TagSettings from "./Pages/Settings/TagSettings";
import Users from "./Pages/Settings/Users";
import OrganizationSettings from "./Pages/Settings/OrganizationSettings";
import { loadReCaptcha } from "react-recaptcha-google";
import CustomAccountProperties from "./Pages/Settings/CustomAccountProperties";
import { VisitorProvider } from "./Context/VisitorContext";
import { AccountProvider } from "./Context/AccountContex";
import { ResponseProvider } from "./Context/ResponseContext";
import ResetPassword from "./Pages/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword";

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      // centered max-width for ultrawide monitors
      display: "flex",
      justifyContent: "center",
      // necessary for content to below the app bar
      marginTop: theme.spacing(8),
    },
    loadingScreen: {
      width: "100vw",
      height: "100vh",
    },
  })
);

/**
 * Error alert component
 */
const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

/**
 * App (home) component
 */
const App = () => {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const { isLoading, data: user, error: userFetchError } = useUser();
  // the user is authenticated when the checkSessionLogin request is done and user data was returned
  const isAuthenticated = isLoading === false && user;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data: error } = useQuery<string | null>("error", () => null);

  React.useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
    } else {
      setSnackbarOpen(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (userFetchError) {
      queryClient.setQueryData("error", userFetchError);
    }
  }, [userFetchError, queryClient]);

  React.useEffect(() => {
    loadReCaptcha();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Validate user
  if (isLoading) return <Loading className={classes.loadingScreen} />;

  // pages without the default component layout
  const pagesWithoutSidenav = [
    "/login",
    "/chooseOrg",
    "/resetPassword",
    "/forgotPassword",
  ];
  const RoutesWithSidenav = () => (
    <>
      <main className={classes.content}>
        {/* If no org is attached to the current user, send them to the choose org page */}
        {isAuthenticated && user && !user.organizationID && (
          <Redirect to="/chooseOrg" />
        )}
        <Switch>
          <Route path="/visitors">
            <VisitorProvider>
              <Visitors />
            </VisitorProvider>
          </Route>
          <Route path="/accounts">
            <AccountProvider>
              <Accounts />
            </AccountProvider>
          </Route>
          <Route path="/nps-responses">
            <ResponseProvider>
              <NpsResponses />
            </ResponseProvider>
          </Route>
          <Route path="/nps-surveys">
            <NpsSurveys />
          </Route>
          <Route path="/edit-nps-survey/:surveyID">
            <EditNpsSurvey />
          </Route>
          <Route path="/create-nps-survey/:surveyID">
            <EditNpsSurvey />
          </Route>
          <Route path="/nps-themes">
            <Themes />
          </Route>
          <Route path="/edit-nps-theme/:themeID">
            <EditNpsTheme />
          </Route>
          <Route path="/create-nps-theme/:themeID">
            <EditNpsTheme />
          </Route>
          <Route path="/segments">
            <Segments />
          </Route>
          <Route path="/edit-segment/:segmentID">
            <EditSegment />
          </Route>
          <Route path="/create-segment/:segmentID">
            <EditSegment />
          </Route>
          {/* Settings routes */}
          <Route path="/visitor-properties">
            <CustomVisitorProperties />
          </Route>
          <Route path="/account-properties">
            <CustomAccountProperties />
          </Route>
          <Route path="/tags">
            <TagSettings />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/organization-settings">
            <OrganizationSettings />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </main>
    </>
  );

  console.log(isAuthenticated);

  return (
    <Router>
      <ThemeProvider theme={EngagifyTheme}>
        <CssBaseline />
        <div style={{ display: "flex" }}>
          {/* Default Layout */}
          <AppBarComponent
            handleDrawerToggle={handleDrawerToggle}
            pagesWithoutSidenav={pagesWithoutSidenav}
          />
          <Sidenav
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            pagesWithoutSidenav={pagesWithoutSidenav}
          />
          {/* Router */}
          <Switch>
            <Route exact path="/login">
              {isAuthenticated ? <Redirect to="/" /> : <Login />}
            </Route>
            <Route exact path="/resetPassword">
              <ResetPassword />
            </Route>
            <Route exact path="/forgotPassword">
              <ForgotPassword />
            </Route>
            {/* let the user choose an org if they are authenticated */}
            <Route exact path="/chooseOrg">
              {isAuthenticated ? (
                user?.organizationID ? (
                  <Redirect to="/" />
                ) : (
                  <ChooseOrg />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            {/* Protected Routes */}
            <ProtectedRoute
              component={RoutesWithSidenav}
              isAuthenticated={isAuthenticated}
            />
          </Switch>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="error" onClose={handleSnackbarClose}>
            {error}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </Router>
  );
};

export default App;
