import { ICssObject } from "./jsonToCss";

const getStyle = (
  css: ICssObject,
  selector: string,
  property: string
): any | undefined => {
  // verify that the given selector exists
  if (css[selector]) {
    // verify that the given property has been
    // attributed to the selector
    if (css[selector][property]) {
      return css[selector][property];
    }
  }
  return undefined;
};

export default getStyle;
