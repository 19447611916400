import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CriteriaData from "./data/CriteriaData";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import { IProperty, IPropertyGroup } from "./data/properties";
import { IRule } from "./data/rules";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

export interface ICriteria {
  propertyGroup: string | null;
  property: string | null;
  type: "String" | "Date" | "Flag" | "Number" | null;
  rule: string | null;
  values: any[] | null;
  error?: boolean;
  isCustomProperty?: boolean;
  customPropertyType?: "account" | "visitor";
}

interface ICriteriaComponent {
  classes: any;
  criteria: ICriteria;
  updateCriteria: (id: string, newCriteria: ICriteria) => void;
  delete: () => void;
  id: string;
  customProperties: {
    visitorProperties: IProperty[] | null;
    accountProperties: IProperty[] | null;
  };
  accountMode?: boolean;
}

/**
 * Component that renders a single conditional editor
 * Child to a criteria group
 * @param props css classes object, method to update parent criteria, and method to trigger a form save
 */
const Criteria = (props: ICriteriaComponent) => {
  const [propertyGroup, setPropertyGroup] =
    React.useState<IPropertyGroup | null>(
      props.accountMode
        ? CriteriaData.properties.find(
            (prop) => prop.label === "Account Properties"
          ) || null
        : CriteriaData.properties.find(
            (prop) => prop.label === props.criteria.propertyGroup
          ) || null
    );
  const [property, setProperty] = React.useState<IProperty | null>(() => {
    const currentGroup =
      CriteriaData.properties.find(
        (prop) => prop.label === props.criteria.propertyGroup
      ) || null;
    if (currentGroup) {
      if (
        props.customProperties.accountProperties &&
        props.customProperties.visitorProperties
      ) {
        if (currentGroup.label === "Visitor Properties") {
          return (
            [
              ...currentGroup.propertyGroup,
              ...props.customProperties.visitorProperties,
            ].find((prop) => prop.label === props.criteria.property) || null
          );
        } else if (currentGroup.label === "Account Properties")
          return (
            [
              ...currentGroup.propertyGroup,
              ...props.customProperties.accountProperties,
            ].find((prop) => prop.label === props.criteria.property) || null
          );
        else {
          return (
            currentGroup.propertyGroup.find(
              (prop) => prop.label === props.criteria.property
            ) || null
          );
        }
      } else {
        console.log("no properties");
      }
    }
    return null;
  });
  const [rule, setRule] = React.useState<IRule | null>(
    property?.rules.find((r) => r.label === props.criteria.rule) || null
  );
  const [values, setValues] = React.useState<any[]>(
    props.criteria.values || []
  );
  const [valueErrors, setValueErrors] = React.useState<(null | string)[]>([]);
  const [error, setError] = React.useState<boolean | undefined>(
    props.criteria.error
  );
  const [multilineValue, setMultilineValue] = React.useState<string>(
    props.criteria.values && props.criteria.values[0] instanceof Array
      ? props.criteria.values[0].join("\n")
      : ""
  );
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const [propertyList, setPropertyList] = React.useState<IProperty[] | null>(
    null
  );

  React.useEffect(() => {
    if (
      propertyGroup &&
      props.customProperties.accountProperties &&
      props.customProperties.visitorProperties
    ) {
      let newPropertyList;
      if (propertyGroup.label === "Visitor Properties") {
        newPropertyList = [
          ...propertyGroup.propertyGroup,
          ...props.customProperties.visitorProperties,
        ];
        setPropertyList(newPropertyList);
        // console.log(newPropertyList, props.criteria.property);
        setProperty(
          newPropertyList.find(
            (prop) => prop.label === props.criteria.property
          ) || null
        );
      } else if (propertyGroup.label === "Account Properties") {
        newPropertyList = [
          ...propertyGroup.propertyGroup,
          ...props.customProperties.accountProperties,
        ];
        setPropertyList(newPropertyList);
        setProperty(
          newPropertyList.find(
            (prop) => prop.label === props.criteria.property
          ) || null
        );
      } else {
        setPropertyList(propertyGroup.propertyGroup);
        setProperty(
          propertyGroup.propertyGroup.find(
            (prop) => prop.label === props.criteria.property
          ) || null
        );
      }
    }
  }, [propertyGroup, props.customProperties]);

  React.useEffect(() => {
    if (valueErrors[0] || valueErrors[1]) {
      setError(true);
    }
  }, [valueErrors]);

  let criteria: ICriteria = {
    propertyGroup: propertyGroup?.label || null,
    property: property?.label || null,
    type: property?.type || null,
    rule: rule?.label || null,
    values: values || null,
    error,
    isCustomProperty: property?.isCustomProperty || false,
    customPropertyType: property?.customPropertyType,
  };

  // save the criteria data whenever the form could be finished
  // (on rule change, or on value change)
  React.useEffect(() => {
    if (!propertyGroup || !property || !rule) setError(true);
    else setError(false);
    // console.log(criteria);
    props.updateCriteria(props.id, criteria);
  }, [propertyGroup, property, rule, values]);

  React.useEffect(() => {
    props.updateCriteria(props.id, criteria);
  }, [error]);

  const handlePropertyGroupChange = (
    e: any,
    newValue: IPropertyGroup | null
  ) => {
    setProperty(null);
    setRule(null);
    setPropertyGroup(newValue);
  };

  const handlePropertyChange = (e: any, newValue: IProperty | null) => {
    console.log(newValue);
    setRule(null);
    setProperty(newValue);
  };

  // React.useEffect(() => console.log(property), [property]);

  const handleRuleChange = (e: any, newValue: IRule | null) => {
    setValues([...Array(rule?.inputs)].fill(""));
    setValueErrors([...Array(rule?.inputs)].fill(null));
    setRule(newValue);
    setError(false);
  };

  /**
   * Changes the value of the appropriate element in the values state variable
   * @param e The input element
   * @param i The value's index (for conditions requiring multiple values)
   */
  const handleValueChange = (e: any, i: number) => {
    const clone = [...values];
    clone[i] = e.target.value;
    setValues(clone);
    setError(false);
    const errorClone = [...valueErrors];
    errorClone[i] = null;
    setValueErrors(errorClone);
  };

  const handleMultilineChange = (newValue: string) => {
    const newLineSeparated = newValue.split("\n");
    setMultilineValue(newValue);
    setValues([newLineSeparated.filter((item) => item.length > 0)]);
  };

  const validateValues = (i: number) => {
    if (rule) {
      if (rule.inputs === 2) {
        if (!values[0] && values[1])
          setValueErrors(["Please enter a value", valueErrors[1]]);
        if (!values[i]) {
          const clone = [...valueErrors];
          clone[i] = "Please enter a value";
          setValueErrors(clone);
        }
      } else if (rule.inputs === 1) {
        if (!values[0]) {
          setValueErrors([" ", valueErrors[1]]);
        }
      }
    }
  };

  const focusValue = (i: number) => {
    const clone = [...valueErrors];
    clone[i] = null;
    setValueErrors(clone);
    setError(false);
  };

  React.useEffect(() => {
    if (props.criteria.error) {
      validateValues(0);
      // validateValues(1);
    }
  }, [props.criteria]);

  const isMultiline = rule?.inputType === "multiline";

  return (
    <div className={props.classes.superContainer} key={`div_${props.id}`}>
      <div className={props.classes.criteriaContainer}>
        {props.criteria.error && (
          <div className={props.classes.errorContainer}>
            <Tooltip title="This rule is incomplete and will not be used.">
              <ErrorIcon color="inherit" fontSize="large" />
            </Tooltip>
          </div>
        )}
        <Autocomplete
          options={CriteriaData.properties}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Property type"
              variant="outlined"
              error={!Boolean(propertyGroup)}
            />
          )}
          value={propertyGroup}
          onChange={handlePropertyGroupChange}
          className={props.classes.input}
          disabled={props.accountMode}
        />
        {/* 
        conditionally render components based on which parts of the form have been filled out
       */}
        {propertyGroup && propertyList && (
          <Autocomplete
            // add custom properties to options list when appropriate
            options={propertyList}
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.group || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Property"
                variant="outlined"
                error={!Boolean(property)}
              />
            )}
            value={property}
            onChange={handlePropertyChange}
            className={props.classes.input}
          />
        )}
        {property && (
          <Autocomplete
            options={property.rules}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Rule"
                variant="outlined"
                error={!Boolean(rule)}
              />
            )}
            value={rule}
            onChange={handleRuleChange}
            className={props.classes.input}
            onBlur={() => {
              validateValues(0);
              validateValues(1);
            }}
          />
        )}
        {rule &&
          !isMultiline &&
          [...Array(rule.inputs)].map((empty, i) => (
            <TextField
              key={`value_${props.id}_${i}`}
              type={rule.inputType}
              variant="outlined"
              aria-label={`${i === 0 ? "1st" : "2nd"} value`}
              value={values[i]}
              onChange={(e) => handleValueChange(e, i)}
              error={Boolean(valueErrors[i])}
              onFocus={() => focusValue(i)}
              onBlur={() => validateValues(i)}
            />
          ))}
        <div className={props.classes.deleteContainer}>
          <IconButton
            className={props.classes.deleteIcon}
            onClick={() => setDeleteModalOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      {rule && isMultiline && (
        <TextField
          variant="outlined"
          aria-label="1st value"
          value={multilineValue}
          onChange={(e) => handleMultilineChange(e.target.value)}
          label={`${values[0]?.length} items`}
          className={props.classes.multiline}
          multiline
          rows={4}
        />
      )}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to delete this rule?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              props.delete();
              setDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Criteria;
