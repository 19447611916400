import React from "react";
import RegisterForm from "../Components/Forms/RegisterForm";
import RegisterOrgForm from "../Components/Forms/RegisterOrgForm";
import LoginForm from "../Components/Forms/LoginForm";
// material-ui imports
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Hidden, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

// styles
const spaceBetween = 25;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100vh",
      minWidth: "100vw",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        maxHeight: "calc(100vh - 100px)",
      },
    },
    appBar: {
      height: 100,
      width: "100vw",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    jumbo: {
      flex: 2,
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      transition: "flex 0.3s",
    },
    loginRoot: {
      flex: 3,
      boxShadow: "-2px 0px 3px rgba(50, 50, 50, 0.1)",
      display: "flex",
      background: theme.palette.contrast.light,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100vw",
      transition: "flex 0.3s",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    buttonActive: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logoName: {
      marginTop: spaceBetween,
      flex: 1,
    },
  })
);
const Login = () => {
  const [formSelection, setFormSelection] = React.useState<
    "login" | "register"
  >("login");
  const [registrationStep, setRegistrationStep] = React.useState<0 | 1>(0);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      {/* Left side jumbo (hidden on mobile) */}
      <Hidden xsDown>
        <div
          className={classes.jumbo}
          style={{ flex: formSelection === "login" ? 2 : 1 }}
        >
          <svg height="100" width="100">
            <circle
              cx="50"
              cy="50"
              r="40"
              stroke="white"
              strokeWidth="8"
              fill="none"
            />
          </svg>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      {/* Top banner (hidden on desktop) */}
      <Hidden smUp>
        <div className={classes.appBar}>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      <div
        className={classes.loginRoot}
        style={{
          // change the login section size based on what form the user is filling out
          flex: formSelection === "login" ? 3 : 2,
        }}
      >
        <div className={classes.column}>
          <div className={classes.row}>
            <Button
              variant="outlined"
              className={formSelection === "login" ? classes.buttonActive : ""}
              onClick={() => setFormSelection("login")}
            >
              Login
            </Button>
            <Button
              variant="outlined"
              className={
                formSelection === "register" ? classes.buttonActive : ""
              }
              onClick={() => setFormSelection("register")}
            >
              Register
            </Button>
          </div>
          <Typography variant="h3" className={classes.logoName}>
            {/* Change the header based on which form the user is filling out */}
            {formSelection === "login"
              ? "Welcome Back"
              : registrationStep === 0
              ? "Welcome to Engagify"
              : "Build an Organization"}
          </Typography>
          {formSelection === "login" ? (
            <LoginForm />
          ) : registrationStep === 0 ? (
            <RegisterForm setRegistrationStep={setRegistrationStep} />
          ) : (
            <RegisterOrgForm />
          )}
        </div>
      </div>
    </main>
  );
};

export default Login;
