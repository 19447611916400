// common styles
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { npsColors } from "./Theme";

// styles for common layouts
export const useLayout = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    col: {
      display: "flex",
      flexDirection: "column",
    },
    fullRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    evenRow: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        "& > * + *": {
          marginLeft: theme.spacing(3),
          marginTop: 0,
        },
      },
    },
    betweenRow: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    fullWidth: {
      width: "100%",
    },
    flex1: {
      flex: 1,
    },
    flex2: {
      flex: 2,
    },
    flex3: {
      flex: 3,
    },
    flexGrow: {
      flexGrow: 1,
    },
    baseline: {
      alignItems: "baseline",
    },
  })
);

// styles for links
export const useLinks = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "inherit",
      textDecoration: "none",
      "& :hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    primary: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "& :hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    secondary: {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "& :hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  })
);

// styles for criteria editor
export const useCriteriaStyles = makeStyles((theme: Theme) =>
  createStyles({
    builderContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(1),
    },
    toggleButtonSelected: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.contrast.light} !important`,
    },
    buttonRow: {
      display: "flex",
      alignItems: "center",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(2),
    },
    conditionContainer: {
      marginLeft: theme.spacing(3),
      borderLeft: "2px solid rgba(0,0,0,0.1)",
      paddingLeft: theme.spacing(3),
      flex: 1,
    },
    criteriaContainer: {
      display: "flex",
      flexDirection: "row",
      "&>*": {
        flex: 1,
      },
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    input: {
      minWidth: 200,
      maxWidth: 250,
    },
    listItem: {
      color: theme.palette.contrast.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.contrast.light,
      },
      transition: "all 0.1s",
    },
    deleteIcon: {
      maxWidth: theme.spacing(7),
      marginLeft: theme.spacing(1),
    },
    multiline: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
      flex: 1,
      flexGrow: 1,
    },
    superContainer: {
      display: "flex",
      flexDirection: "column",
    },
    deleteContainer: {
      display: "flex",
      alignItems: "center",
    },
    addGroupButton: {
      marginLeft: theme.spacing(2),
    },
    addButtonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    errorContainer: {
      maxWidth: 56,
      color: `${npsColors.red}a3`,
      display: "flex",
      alignItems: "center",
    },
  })
);
