import React from "react";
import { IFollowUpQuestion, ISurvey } from "../../../queries/types";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import { textInputFactory } from "../FormHooks";
import {
  checkForOverlap,
  checkForUnusedRatings,
  formatFollowUpQuestions,
} from "./AdvanedOptionsUtil";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Delete from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { objectsAreEqual } from "./deepComparison";
import RatingCategorySlider from "./RatingCategorySlider";

export interface IRangedQuestion {
  scores: [number, number];
  question: string;
  lastScores: [number, number];
  autoMoved?: boolean;
  error?: string | null;
}

interface ISurveyAdvancedOptionsEditor {
  classes: any;
  survey: ISurvey;
  updateSurvey: (survey: ISurvey) => Promise<void>;
}

const SurveyAdvancedOptionsEditor = (props: ISurveyAdvancedOptionsEditor) => {
  const makeTextInput = textInputFactory(props.classes);

  const [notLikelyText, notLikelyInput] = makeTextInput(
    `"Not likely" text`,
    props.survey.notLikelyText
  );
  const [veryLikelyText, veryLikelyInput] = makeTextInput(
    `"Very likely" text`,
    props.survey.veryLikelyText
  );
  const [remindMeLaterText, remindMeLaterInput] = makeTextInput(
    `"Remind me later" text`,
    props.survey.remindMeLaterText
  );
  const [closeWindowText, closeWindowInput] = makeTextInput(
    `"Close window" text`,
    props.survey.closeWindowText
  );
  const [submitButtonText, submitButtonInput] = makeTextInput(
    `Submit button text`,
    props.survey.submitButtonText
  );
  const [backButtonText, backButtonInput] = makeTextInput(
    `Back button text`,
    props.survey.backButtonText
  );
  const [minRating, setMinRating] = React.useState<number>(
    props.survey.minRating
  );
  const [maxRating, setMaxRating] = React.useState<number>(
    props.survey.maxRating
  );
  const [rangedQuestions, setRangedQuestions] = React.useState<
    IRangedQuestion[]
  >(
    props.survey.followUpQuestions.map((question) => ({
      question: question.followUpQuestion,
      scores: [question.minRating, question.maxRating],
      lastScores: [question.minRating, question.maxRating],
    }))
  );
  const [detractorMax, setDetractorMax] = React.useState<number>(
    props.survey.maxDetractorRating
  );
  const [promoterMin, setPromoterMin] = React.useState<number>(
    props.survey.minPromoterRating
  );
  const [usedRatings, setUsedRatings] = React.useState<number[]>([]);
  const [unusedRating, setUnusedRating] = React.useState<string | null>(null);
  const [deletingQuestion, setDeletingQuestion] = React.useState<number | null>(
    null
  );

  const theme = useTheme();

  // Save on change
  React.useEffect(() => {
    const timeoutID = setTimeout(() => {
      const newSurvey = {
        ...props.survey,
        followUpQuestions:
          rangedQuestions.length === 1
            ? [
                {
                  minRating: 0,
                  maxRating: 10,
                  followUpQuestion: rangedQuestions[0].question,
                },
              ]
            : formatFollowUpQuestions(rangedQuestions),
        notLikelyText,
        veryLikelyText,
        closeWindowText,
        submitButtonText,
        backButtonText,
        remindMeLaterText,
        minRating: minRating,
        maxRating: maxRating,
        minPromoterRating: promoterMin,
        maxDetractorRating: detractorMax,
      };
      if (!objectsAreEqual(props.survey, newSurvey))
        props.updateSurvey(newSurvey);
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [
    notLikelyText,
    veryLikelyText,
    remindMeLaterText,
    closeWindowText,
    submitButtonText,
    backButtonText,
    minRating,
    maxRating,
    rangedQuestions,
    detractorMax,
    promoterMin,
  ]);

  React.useEffect(() => {
    if (props.survey) {
      // instantiate used scores with all possible values being set to 0,
      const newusedRatings: number[] = new Array(11).fill(0);
      let currentQuestion: IFollowUpQuestion;
      for (let i = 0; i < props.survey.followUpQuestions.length; i++) {
        currentQuestion = props.survey.followUpQuestions[i];
        for (
          let j = currentQuestion.minRating;
          j <= currentQuestion.maxRating;
          j++
        ) {
          newusedRatings[j + props.survey.minRating]++;
        }
      }
      setUsedRatings(newusedRatings);
    }
  }, [props.survey]);

  // handle minimum and maximum rating changesus
  React.useEffect(() => {
    // handle follow up question changes
    const rangedQuestionsClone = [...rangedQuestions];
    for (let i = 0; i < rangedQuestionsClone.length; i++) {
      const currentQuestion = rangedQuestionsClone[i];
      // try to revert to previous scores if possible
      if (
        currentQuestion.autoMoved &&
        currentQuestion.lastScores[0] >= minRating &&
        currentQuestion.lastScores[1] <= maxRating
      ) {
        handleScoreChange(
          [currentQuestion.lastScores[0], currentQuestion.lastScores[1]],
          i,
          true
        );
      } else {
        // if reverting isn't possible, move scores so that they fit in the new rating range.
        if (currentQuestion.scores[0] < minRating)
          handleScoreChange([minRating, currentQuestion.scores[1]], i, true);
        if (currentQuestion.scores[1] > maxRating)
          handleScoreChange([currentQuestion.scores[0], maxRating], i, true);
        if (currentQuestion.scores[0] > maxRating)
          handleScoreChange([maxRating, maxRating], i, true);
        if (currentQuestion.scores[1] < minRating)
          handleScoreChange([minRating, minRating], i, true);
      }
    }
    setUnusedRating(
      checkForUnusedRatings(minRating, maxRating, rangedQuestionsClone)
    );
    // handle nps score changes
    let newPromoterMin = promoterMin;
    let newDetractorMax = detractorMax;
    if (promoterMin > maxRating) newPromoterMin = maxRating;
    if (detractorMax >= newPromoterMin - 1)
      newDetractorMax = newPromoterMin - 2;
    else if (detractorMax < minRating) newDetractorMax = minRating;
    setPromoterMin(newPromoterMin);
    setDetractorMax(newDetractorMax);
  }, [minRating, maxRating]);

  /**
   * Validates a given score range whenever it is changed
   * Checks the new range against all other ranges to find overlap
   * @param {[number, number]} newValue The new score range
   * @param {number} i The question's index in the rangedQuestions list
   */
  const handleScoreChange = (
    newValue: [number, number],
    i: number,
    autoMove?: boolean
  ) => {
    let clone = [...rangedQuestions];
    // remove previous range from usedRatings
    for (let j = clone[i].lastScores[0]; j <= clone[i].lastScores[1]; j++) {
      usedRatings[j]--;
      if (usedRatings[j] < 0) usedRatings[j] = 0;
    }
    // add new range to usedRatings
    for (let j = newValue[0]; j <= newValue[1]; j++) {
      usedRatings[j]++;
    }
    setUsedRatings(usedRatings);

    // fix invalid newValues caused by shifting min and max ratings.
    if (newValue[0] > newValue[1]) newValue[1] = newValue[0];
    clone[i].lastScores = clone[i].scores;
    clone[i].scores = newValue;
    if (autoMove) clone[i].autoMoved = autoMove;
    else clone[i].autoMoved = false;
    clone = checkForOverlap(clone);
    console.log(clone, usedRatings);
    setUnusedRating(checkForUnusedRatings(minRating, maxRating, clone));
    setRangedQuestions(clone);
  };

  /**
   * Function that updates each slider's state when moved (no validation)
   * @param {[number, number]} newValue The new score range
   * @param {number} i The question's index in the rangedQuestions list
   */
  const moveSlider = (newValue: [number, number], i: number) => {
    const clone = [...rangedQuestions];
    clone[i].scores = newValue;
    setRangedQuestions(clone);
  };

  /**
   * Updates a question in the rangedQuestions state variable
   * @param {string} newValue The new question
   * @param {number} i The question's index in the rangedQuestions list
   */
  const handleQuestionChange = (newValue: string, i: number) => {
    const clone = [...rangedQuestions];
    clone[i].question = newValue;
    setRangedQuestions(clone);
  };

  /**
   * Adds another follow up question to the rangedQuestions state variable
   */
  const addFollowUp = () => {
    const availableRatings = usedRatings.slice(minRating, maxRating + 1);
    // find first empty rating
    let startingIndex = availableRatings.indexOf(0);
    let endingIndex: number = minRating;
    // this will only happen if every rating is in use
    if (startingIndex === -1) {
      startingIndex = minRating;
    } else {
      // find the end of the open range
      let i = startingIndex;
      while (availableRatings[i] === 0) {
        endingIndex = i;
        i++;
      }
      startingIndex += minRating;
      endingIndex += minRating;
    }
    const clone = [...rangedQuestions];
    clone.push({
      scores: [startingIndex, endingIndex],
      lastScores: [startingIndex, endingIndex],
      question: "Why did you give that response?",
    });
    setRangedQuestions(checkForOverlap(clone));
    for (let i = startingIndex; i <= endingIndex; i++) {
      usedRatings[i]++;
    }
    setUnusedRating(checkForUnusedRatings(minRating, maxRating, clone));
  };

  const deleteQuestion = (index: number | null) => {
    if (index === null) return;
    // // remove previous range from usedRatings
    for (
      let i = rangedQuestions[index].scores[0];
      i <= rangedQuestions[index].scores[1];
      i++
    ) {
      usedRatings[i]--;
      if (usedRatings[i] < 0) usedRatings[i] = 0;
    }
    rangedQuestions.splice(index, 1);
    const clone = checkForOverlap(rangedQuestions);
    setRangedQuestions(clone);
    setUnusedRating(checkForUnusedRatings(minRating, maxRating, clone));
  };

  const multipleResponsesForm = (
    <div>
      <Typography variant="subtitle2">Follow-up Questions: </Typography>
      {rangedQuestions.map((question, i) => (
        <div
          key={`ranged-question-${i}`}
          className={props.classes.extraQuestion}
        >
          <div className={props.classes.questionContainer}>
            <div className={props.classes.slider}>
              <Typography
                variant="caption"
                color="textSecondary"
                id={`score-caption-${i}`}
              >
                Rating :
              </Typography>
              <Slider
                value={question.scores}
                onChange={(e, newValue) =>
                  moveSlider(newValue as [number, number], i)
                }
                onChangeCommitted={(e, newValue) =>
                  handleScoreChange(newValue as [number, number], i)
                }
                valueLabelDisplay="auto"
                aria-labelledby={`score-caption-${i}`}
                // create a label for each step (starting from the minimum score and ending on the maximum)
                marks={[...Array(maxRating - minRating + 1)].map(
                  (element, i) => ({
                    value: i + minRating,
                    label: (i + minRating).toString(),
                  })
                )}
                min={minRating}
                max={maxRating}
                style={{
                  flex: 1,
                  color: question.error ? theme.palette.error.main : "",
                }}
                classes={{ track: props.classes.track }}
              />
            </div>
            {question.error && <Alert severity="error">{question.error}</Alert>}
            <TextField
              value={question.question}
              onChange={(e) => handleQuestionChange(e.target.value, i)}
              fullWidth
              multiline
              label="Question"
              className={props.classes.multipleResponsesQuestion}
            />
          </div>

          {rangedQuestions.length > 1 && (
            <Tooltip
              title="Delete this follow up question"
              className={props.classes.deleteQuestionContainer}
            >
              <IconButton onClick={() => setDeletingQuestion(i)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ))}
      {unusedRating !== null && <Alert severity="error">{unusedRating}</Alert>}
    </div>
  );

  return (
    <div>
      <Typography
        variant="subtitle2"
        className={props.classes.headingContainer}
        gutterBottom
      >
        Rating Range{" "}
        <Tooltip title="Set the minimum and maximum rating.">
          <InfoIcon color="disabled" style={{ marginLeft: theme.spacing(1) }} />
        </Tooltip>
      </Typography>
      <div className={props.classes.rangeInputContainer}>
        <FormControl className={props.classes.rangeInput}>
          <InputLabel id="minimum-rating-label">Minimum rating</InputLabel>
          <Select
            labelId="minimum-rating-label"
            value={minRating}
            onChange={(e) => {
              const newMin = Number(e.target.value);
              setMinRating(newMin);
            }}
          >
            <MenuItem value={0}>0</MenuItem>
            {maxRating > 2 && <MenuItem value={1}>1</MenuItem>}
          </Select>
        </FormControl>
        <FormControl className={props.classes.rangeInput}>
          <InputLabel id="maximum-rating-label">Maximum rating</InputLabel>
          <Select
            labelId="maximum-rating-label"
            value={maxRating}
            onChange={(e) => {
              const newMax = Number(e.target.value);
              setMaxRating(newMax);
            }}
          >
            {[...Array(minRating === 0 ? 9 : 8)].map((e, i) => (
              <MenuItem
                value={minRating === 0 ? i + 2 : i + 3}
                key={`max_rating_${i}`}
              >
                {minRating === 0 ? i + 2 : i + 3}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Typography
        variant="subtitle2"
        className={props.classes.headingContainer}
        gutterBottom
      >
        NPS Scores{" "}
        <Tooltip title="Set the NPS scores given to each rating.">
          <InfoIcon color="disabled" style={{ marginLeft: theme.spacing(1) }} />
        </Tooltip>
      </Typography>
      <div className={props.classes.rangeInputContainer}>
        <FormControl className={props.classes.scoreInput}>
          <InputLabel id="detractor-score-label">
            Maximum detractor rating
          </InputLabel>
          <Select
            labelId="detractor-score-label"
            value={detractorMax}
            onChange={(e) => setDetractorMax(Number(e.target.value))}
          >
            {[
              ...Array(minRating === 0 ? promoterMin - 1 : promoterMin - 2),
            ].map((e, i) => (
              <MenuItem
                value={minRating === 0 ? i : i + 1}
                key={`min_rating_${i}`}
              >
                {minRating === 0 ? i : i + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={props.classes.scoreInput}>
          <InputLabel id="promoter-score-label">
            Minimum promoter rating
          </InputLabel>
          <Select
            labelId="promoter-score-label"
            value={promoterMin}
            onChange={(e) => setPromoterMin(Number(e.target.value))}
          >
            {/* avoid creating an array with an illegal size (negative size) */}
            {detractorMax < maxRating - 1 &&
              [...Array(maxRating - detractorMax - 1)].map((e, i) => (
                <MenuItem
                  value={i + detractorMax + 2}
                  key={`promoter_min_${i}`}
                >
                  {i + detractorMax + 2}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <RatingCategorySlider
        detractorMax={detractorMax}
        promoterMin={promoterMin}
        minRating={minRating}
        maxRating={maxRating}
      />
      {multipleResponsesForm}
      <Button
        color="secondary"
        onClick={addFollowUp}
        variant="contained"
        style={{ float: "right", color: "#fff" }}
      >
        <AddIcon /> Add another follow-up question
      </Button>
      {notLikelyInput}
      {veryLikelyInput}
      {remindMeLaterInput}
      {closeWindowInput}
      {submitButtonInput}
      {backButtonInput}
      <Dialog
        open={deletingQuestion !== null}
        onClose={() => setDeletingQuestion(null)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you'd like to delete this follow up question?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletingQuestion(null)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteQuestion(deletingQuestion);
              setDeletingQuestion(null);
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SurveyAdvancedOptionsEditor;
