import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useLayout } from "../../../config/Styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useEffect, useState } from "react";
import CustomFontsModal from "./CustomFontsModal";
import { fontsRequest } from "../../../queries/npsRequests";
import { useApi } from "../../../queries/util";
import { IFont } from "../../../queries/types";

type FontPickerProps = {
  headerFont: string;
  setHeaderFont: (font: string) => void;
  headerFontSize: number;
  setHeaderFontSize: (size: number) => void;
  bodyFont: string;
  setBodyFont: (font: string) => void;
  bodyFontSize: number;
  setBodyFontSize: (size: number) => void;
  classes: any;
};

const defaultFonts = [
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",
];

const FontPicker = (props: FontPickerProps) => {
  const layout = useLayout();
  const {
    isLoading,
    data: fontFetch,
    error: fontError,
    refetch,
  } = useApi<IFont[]>(fontsRequest);
  // web safe font list (TODO: hook this up to react query to fetch custom fonts)
  const [fonts, setFonts] = useState<string[]>([
    "Arial",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet MS",
    "Times New Roman",
    "Georgia",
    "Garamond",
    "Courier New",
    "Brush Script MT",
  ]);
  const [fontsModalOpen, setFontsModalOpen] = useState<boolean>(false);
  const closeFontsModal = () => setFontsModalOpen(false);

  useEffect(() => {
    if (fontFetch) {
      let newFonts = [];
      for (let font of fontFetch) {
        newFonts.push(font.fontName);
        document.head.innerHTML += font.embedCode;
      }
      newFonts = newFonts.concat(defaultFonts);
      setFonts(newFonts);
    }
  }, [fontFetch]);

  // Put subheadings in the font picker

  return (
    <>
      <div className={layout.row}>
        <Autocomplete
          options={fonts}
          renderInput={(params) => (
            <TextField {...params} label="Header font" />
          )}
          freeSolo
          value={props.headerFont}
          onChange={(e, newValue) => props.setHeaderFont(newValue || "")}
          className={props.classes.inputLarge}
        />
        <FormControl className={props.classes.inputSmall}>
          <InputLabel id="header-font-size-label">Font size</InputLabel>
          <Input
            aria-labelledby="header-font-size-label"
            type="number"
            endAdornment={<InputAdornment position="end">pt</InputAdornment>}
            value={props.headerFontSize}
            onChange={(e) => props.setHeaderFontSize(Number(e.target.value))}
          />
        </FormControl>
      </div>
      <div className={layout.row}>
        <Autocomplete
          options={fonts}
          renderInput={(params) => (
            <TextField {...params} label="Body font" fullWidth />
          )}
          freeSolo
          className={props.classes.inputLarge}
          value={props.bodyFont}
          onChange={(e, newValue) => props.setBodyFont(newValue || "")}
        />
        <FormControl className={props.classes.inputSmall}>
          <InputLabel id="body-font-size-label">Font size</InputLabel>
          <Input
            aria-labelledby="body-font-size-label"
            type="number"
            endAdornment={<InputAdornment position="end">pt</InputAdornment>}
            value={props.bodyFontSize}
            onChange={(e) => props.setBodyFontSize(Number(e.target.value))}
          />
        </FormControl>
      </div>
      <div className={layout.row} style={{ justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          aria-haspopup="true"
          onClick={() => setFontsModalOpen(true)}
        >
          Custom Fonts
        </Button>
      </div>
      <CustomFontsModal open={fontsModalOpen} onClose={closeFontsModal} />
    </>
  );
};

export default FontPicker;
