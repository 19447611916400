import React from "react";
// material-ui imports
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { useQueryClient, useMutation } from "react-query";
import { chooseOrg } from "../queries/org";
import { IUser, IOrgWithRestrictions, IOrg } from "../queries/types";
import { useUser } from "../queries/user";
import RegisterOrgForm from "../Components/Forms/RegisterOrgForm";
import { colors } from "../config/Theme";
import Loading from "../Components/Loading";
import { useApi } from "../queries/util";
import { orgsRequest } from "../queries/orgRequests";

// styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      width: "100vw",
      background: "#fff",
    },
    row: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    card: {
      marginTop: 20,
      minWidth: "50vw",
      borderRadius: 5,
      boxShadow: "2px 2px 7px rgba(0,0,0,0.1)",
    },
    cardRow: {
      padding: "10px 10px",
    },
    listItem: {
      paddingTop: 10,
      paddingBottom: 10,
      color: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },
    listItemSelected: {
      background: `${theme.palette.primary.light} !important`,
    },
    errorAlert: {
      minWidth: "50vw",
      justifyContent: "center",
    },
    jumbo: {
      flex: 1,
      background: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      color: "#fff",
      justifyContent: "center",
      alignItems: "center",
    },
    form: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    orgIcon: {
      height: 30,
      width: 30,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.contrast.light,
    },
    buttonContainer: {
      marginTop: 20,
      minWidth: "50vw",
      display: "flex",
      justifyContent: "end",
    },
  })
);

const ChooseOrg = () => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const [redirectedToOrgCreation, setRedirectedToOrgCreation] =
    React.useState<boolean>(false);
  const classes = useStyles();
  const queryClient = useQueryClient();
  // get user data (should be cached by react-query)
  const {
    isLoading: isLoadingUser,
    data: user,
    error: userFetchError,
  } = useUser();

  // fetch the current user's orgs
  const {
    isLoading,
    data: orgs,
    error: orgsError,
  } = useApi<IOrg[]>(orgsRequest);

  // Mutation for selecting an org
  const orgMutation = useMutation<IOrgWithRestrictions, Error, number>(
    chooseOrg,
    {
      /**
       * Attaches the selected org data to the user session when the mutation succeeds
       * @param {IOrgWithRestrictions} data The data returned by the mutation
       */
      onSuccess: (data: IOrgWithRestrictions) => {
        queryClient.setQueryData("user", (user: IUser | undefined) => ({
          ...(user as IUser),
          organizationID: data.organizationID,
          organizationName: data.organizationName,
          restrictions: data.restrictions,
        }));
      },
    }
  );

  if (isLoading || isLoadingUser) return <Loading className={classes.root} />;

  const handleSubmit = async () => {
    if (!orgs || selectedIndex === null) return setButtonDisabled(true);
    const organizationID = orgs[selectedIndex].organizationID;
    await orgMutation.mutate(organizationID);
  };

  /**
   * Sets the selected index and disables the submit button when a list item is clicked
   * @param {number} index The index of the list item that was clicked (mapped to org list)
   */
  const handleListItemClick = (index: number): void => {
    if (!orgs) return;
    setSelectedIndex(index);
    const organizationID = orgs[index].organizationID;
    orgMutation.mutate(organizationID);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <main className={classes.root}>
      <Hidden xsDown>
        <div className={classes.jumbo}>
          <svg height="100" width="100">
            <circle
              cx="50"
              cy="50"
              r="40"
              stroke="white"
              strokeWidth="8"
              fill="none"
            />
          </svg>
          <Typography variant="h2" component="h1">
            Zengage
          </Typography>
        </div>
      </Hidden>
      <div className={classes.form}>
        {!redirectedToOrgCreation ? (
          <>
            <Typography variant="h5" component="h2">
              Welcome back
            </Typography>
            {orgsError && (
              <Alert severity="error" className={classes.errorAlert}>
                {orgsError.message}
              </Alert>
            )}
            {userFetchError && (
              <Alert severity="error" className={classes.errorAlert}>
                {userFetchError.message}
              </Alert>
            )}
            {orgMutation.error && (
              <Alert severity="error" className={classes.errorAlert}>
                {orgMutation.error.message}
              </Alert>
            )}
            <Typography variant="subtitle1">
              {/* TODO: rephrase this? */}
              Choose an organization below to get started.
            </Typography>
            <div className={classes.card}>
              <div
                className={classes.cardRow}
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <Typography variant="body1">
                  Organizations for <strong>{user?.email}</strong>
                </Typography>
              </div>
              <List
                component="nav"
                aria-label="organization selection"
                // overwrite default styles
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onKeyDown={handleKeyDown}
              >
                {orgs &&
                  orgs.map((org, i) => (
                    <ListItem
                      button
                      selected={selectedIndex === i}
                      onClick={(e) => handleListItemClick(i)}
                      classes={{
                        root: classes.listItem,
                        selected: classes.listItemSelected,
                      }}
                      key={org.organizationName}
                    >
                      <ListItemIcon>
                        <div
                          className={classes.orgIcon}
                          style={{ background: colors[i % colors.length] }}
                        >
                          <Typography variant="subtitle1">
                            {org.organizationName.substring(0, 1).toUpperCase()}
                          </Typography>
                        </div>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          {org.organizationName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                onClick={() => setRedirectedToOrgCreation(true)}
              >
                + Create a new organization
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                disabled={buttonDisabled}
                onClick={handleSubmit}
              >
                Sign In
              </Button> */}
            </div>
          </>
        ) : (
          <>
            <Typography variant="h3" component="h2">
              Create a New Organization
            </Typography>
            <RegisterOrgForm />
          </>
        )}
      </div>
    </main>
  );
};

export default ChooseOrg;
