import { defaultError, internalServerError } from "./errors";

/**
 * Signs the current user into the org with the given organizationID
 * @param {number} orgationID The organizationID of the org to sign the current user into
 * @returns {Promise<IOrg>} Org data for the org the user is being signed into
 */
export const chooseOrg = async (organizationID: number) => {
  const response = await fetch("/api/v1/user/orgs", {
    method: "post",
    body: JSON.stringify({ organizationID }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      return await response.json();
    case 401:
      let errorJSON;
      try {
        errorJSON = await response.json();
        throw new Error(errorJSON.err);
      } catch (err) {
        // if there was no json to be parsed, it means that the user isn't logged in
        throw new Error("Please login.");
      }
    case 500:
      throw new Error(internalServerError);
    default:
      throw new Error(defaultError);
  }
};
