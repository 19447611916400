import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import Avatar from "@material-ui/core/Avatar";
import { colors } from "../config/Theme";
import { Link, useHistory } from "react-router-dom";
import { useApi } from "../queries/util";
import { ISurveyMetadata } from "../queries/types";
import { surveysRequest } from "../queries/npsRequests";
import Loading from "../Components/Loading";
import ErrorSnackbar from "../Components/ErrorSnackbar";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
    },
    title: {
      fontWeight: 400,
    },
    description: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      maxWidth: 600,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    tableRow: {
      padding: "0px 0px",
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    infoIcon: {
      marginBottom: -7,
      marginRight: 4,
    },
    link: {
      textDecoration: "none",
    },
  })
);

const NpsSurveys = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: surveys,
    error: surveysError,
  } = useApi<ISurveyMetadata[]>(surveysRequest);
  // TODO: hook this up to react query
  const [surveyList, setSurveyList] = React.useState<ISurveyMetadata[]>([]);
  const [makingSurvey, setMakingSurvey] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (surveysError) setError(surveysError.message);
    if (surveys)
      setSurveyList(surveys.sort((a, b) => a.surveyOrder - b.surveyOrder));
  }, [isLoading, surveys, surveysError]);

  /**
   * Moves a survey up by one place in the survey list
   * @param index The index of the survey to be moved up in the survey list
   */
  const moveItemUp = (index: number) => {
    if (index === 0) return null;
    const newList = [...surveyList];
    newList[index].surveyOrder = newList[index].surveyOrder - 1;
    const selectedItem = newList.splice(index, 1);
    newList.splice(index - 1, 0, selectedItem[0]);
    setSurveyList(newList);
  };

  /**
   * Moves a survey down by one place in the survey list
   * @param index The index of the survey to be moved down in the survey list
   */
  const moveItemDown = (index: number) => {
    if (index === surveyList.length - 1) return null;
    const newList = [...surveyList];
    newList[index].surveyOrder = newList[index].surveyOrder + 1;
    const selectedItem = newList.splice(index, 1);
    newList.splice(index + 1, 0, selectedItem[0]);
    setSurveyList(newList);
  };

  const createNpsSurvey = async () => {
    const defaultError =
      "Something went wrong while creating a survey. Please try again later.";
    setMakingSurvey(true);
    const response = await fetch("/api/v1/nps/surveys", {
      method: "post",
    });
    switch (response.status) {
      case 401:
        queryClient.invalidateQueries("user");
        break;
      case 200:
        try {
          const json = await response.json();
          if (!json.surveyID) throw new Error(defaultError);
          history.push(`/create-nps-survey/${json.surveyID}`);
        } catch (err) {
          setError(defaultError);
        }
        break;
      default:
        setError(defaultError);
        setMakingSurvey(false);
    }
  };

  if (makingSurvey) return <Loading />;
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Manage NPS Surveys
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.description}
      >
        A given visitor will see only one NPS survey at a given time. The
        visitor will be shown the first survey in this list for which they match
        the criteria. For instance, if a given visitor matches the criteria for
        both the first NPS survey in this list and the second NPS survey in this
        list, they will be shown the first one.
      </Typography>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardContent>
            <div className={classes.buttonContainer}>
              <Tooltip title="Update NPS survey data">
                <IconButton color="primary" aria-label="Refresh survey data">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Button color="primary" onClick={createNpsSurvey}>
                Create NPS survey
              </Button>
            </div>
            {/* Survey table */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Order</TableCell>
                    <TableCell>Survey Name</TableCell>
                    <TableCell align="right" component="th">
                      <Tooltip title="Ignores how long ago their most recent NPS survey was shown to them.">
                        <InfoIcon
                          color="disabled"
                          className={classes.infoIcon}
                        />
                      </Tooltip>
                      # Eligible Visitors Total
                    </TableCell>
                    <TableCell align="right" component="th">
                      <Tooltip title="Factors in how long ago their most recent NPS survey was shown to them.">
                        <InfoIcon
                          color="disabled"
                          className={classes.infoIcon}
                        />
                      </Tooltip>
                      # Eligible Visitors Now
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {surveyList.map((survey, i) => (
                    <TableRow
                      key={survey.surveyName}
                      className={classes.tableRow}
                    >
                      <TableCell>
                        <Avatar
                          style={{ background: colors[i % colors.length] }}
                        >
                          {survey.surveyName.substring(0, 1)}
                        </Avatar>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          disabled={i === 0}
                          className={classes.iconButton}
                          onClick={() => moveItemUp(i)}
                        >
                          <ArrowUpwardIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          disabled={i === surveyList.length - 1}
                          className={classes.iconButton}
                          onClick={() => moveItemDown(i)}
                        >
                          <ArrowDownwardIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{survey.surveyName}</TableCell>
                      <TableCell align="right">100</TableCell>
                      <TableCell align="right">90</TableCell>
                      <TableCell>
                        <Link
                          to={`/edit-nps-survey/${survey.surveyID}`}
                          className={classes.link}
                        >
                          <Button color="primary">Edit</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};
export default NpsSurveys;
