// Theme styles
import {
  createMuiTheme,
  responsiveFontSizes,
  Theme,
} from "@material-ui/core/styles";

// define the palette colors
const primary = "#3b81e3";
const secondary = "#42BF9C";
const info = "#FF7E5F";

// List of colors for org icons
export const colors = [
  "#2196f3",
  "#e91e63",
  "#673ab7",
  "#f44336",
  "#9c27b0",
  "#ff9800",
  "#3f51b5",
  "#009688",
  "#4caf50",
];

export const npsColors = {
  green: "#00e676",
  yellow: "#ffeb3b",
  red: "#f44336",
};

export const npsChipColors = {
  green: "#cfeed7",
  darkGreen: "#2d8a44",
  yellow: "#ffe9c9",
  darkYellow: "#c47b06",
  red: "#fbd0d2",
  darkRed: "#b53544",
};

// extend the palette interface
declare module "@material-ui/core/styles/createPalette" {
  // allow configuration using `createMuiTheme`
  interface Palette {
    contrast: PaletteColor;
  }
  interface PaletteOptions {
    contrast: PaletteColorOptions;
  }
}

/**
 * Extend theme interface to parameterize styles
 */
declare module "@material-ui/core/styles/createMuiTheme" {
  interface ThemeOptions {
    drawerWidth: number;
  }
}

export interface ITheme extends Theme {
  drawerWidth: number;
}

export const drawerWidth = 240;

// Customized material-ui theme for the site
let EngagifyTheme = createMuiTheme({
  drawerWidth: drawerWidth,

  palette: {
    primary: {
      main: primary,
      light: "#d6e7ff",
      dark: "#21549c",
    },
    secondary: {
      main: secondary,
      light: "#e8fff9",
      dark: "#2f9c7d",
    },
    info: {
      main: info,
      light: "#ffdcd4",
    },
    background: {
      default: "#f3f3f3",
    },
    // defining contrast values for text and backgorunds
    contrast: {
      main: "rgba(0, 0, 0, 0.54)",
      // for light text on a dark background
      light: "#fff",
      // for dark text on a light background
      dark: "#444",
    },
  },
  typography: {
    subtitle2: {
      color: "#444",
      fontWeight: 450,
    },
    h5: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 900,
    },
    h4: {
      fontWeight: 700,
    },
  },
  // material-ui style overrides
  overrides: {
    MuiButton: {
      outlined: {
        borderWidth: 0,
        borderBottomWidth: 2,
        borderRadius: 0,
        minWidth: 130,
        margin: "0px 20px",
        "&:hover": {
          borderColor: primary,
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
      },
    },
    MuiTableCell: {
      root: {
        padding: "8px 8px",
        // whiteSpace: "nowrap",
        borderBottom: "0px solid black",
      },
    },
    MuiDialogTitle: {
      root: {
        background: primary,
        color: "#fff",
        marginBottom: 16,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      },
    },
    MuiChip: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCardContent: {
      root: {
        // display: "flex",
        // flexDirection: "column",
        // flex: 1,
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          background: "#fff",
          color: `${primary} !important`,
        },
        background: "#4442",
        color: "rgba(0, 0, 0, 0.54)",
        transition: "all 0.3s",
        borderBottom: "none",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
  },
});

// make all font sizes responsive for accessibility
EngagifyTheme = responsiveFontSizes(EngagifyTheme);

export default EngagifyTheme;
