import React, { useEffect } from "react";
import AceEditor from "react-ace";
import "brace/theme/chrome";
import "brace/mode/css";
import "brace/ext/language_tools";
import { useTheme } from "@material-ui/core";
import jsonToCss, { ICssObject } from "./util/jsonToCss";
import useFonts from "../../hooks/useFonts";
import cssToObject from "./util/cssToObject";

interface ICssEditor {
  css: ICssObject;
  setCss: React.Dispatch<React.SetStateAction<ICssObject>>;
}

/**
 * Component for
 * @param {ICssEditor} props css object and method to update the survey's css
 */
const CssEditor = (props: ICssEditor) => {
  const { fonts } = useFonts();
  const [styles, setStyles] = React.useState(jsonToCss(props.css));
  const theme = useTheme();

  // hook to only update css rules if 400ms have passed since the last key press
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      try {
        props.setCss(cssToObject(styles, fonts));
      } catch (err) {
        console.log(err);
      }
    }, 400);
    return () => clearTimeout(timeoutId);
  }, [styles]);

  return (
    <div>
      <AceEditor
        mode="css"
        theme="chrome"
        name="css-editor"
        width="100%"
        // set the height to the remaining space in the window (window height - app bar - top and bottom margins - tabContainer)
        height={`calc(100vh - ${theme.spacing(8 + 3 + 3 + 6)}px)`}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          tabSize: 2,
        }}
        onChange={(newValue) => setStyles(`${newValue}`)}
        value={styles}
      />
    </div>
  );
};

export default CssEditor;
