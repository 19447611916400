import { CircularProgress } from "@material-ui/core";

interface ILoading {
  color?: "inherit" | "primary" | "secondary";
}

/**
 * Loading circle component (can be plugged into the body element or individual components)
 * @param {"inherit" | "primary" | "secondary" | undefined} color Optional parameter to change the loading icon color
 * @param props Optional props added to the loading component
 */
const Loading = ({ color, ...rest }: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
      {...rest}
    >
      <CircularProgress color={color || "primary"} />
    </div>
  );
};

export default Loading;
