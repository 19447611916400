import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import DialogTitle from "../../DialogTitle";
import Loading from "../../Loading";
import ErrorSnackbar from "../../ErrorSnackbar";
import FontList from "./FontList";
import useFonts from "../../../hooks/useFonts";
import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 900,
      maxWidth: 1200,
    },
    title: {
      minWidth: 900,
    },
    tableCell: {
      maxWidth: 400,
      minWidth: 100,
      overflowWrap: "break-word", // break lines within words if needed
      wordBreak: "break-all", // break lines between letters if needed
      whiteSpace: "normal", // allow text to wrap
    },
    tableRow: {
      "&:nth-child(even)": {
        background: theme.palette.background.default,
      },
    },
    iconButtons: {
      display: "flex",
    },
  })
);

interface ICustomFontsModal {
  onClose: () => void;
  open: boolean;
}

interface IResponseError {
  err: string;
  field: "fontName" | "embedCode";
}

/**
 * Modal for managing a custom font list and adding new custom fonts
 * @param {ICustomFontsModal} props a close modal handler function and whether or not the modal should be open
 */
const CustomFontsModal = (props: ICustomFontsModal) => {
  const {
    fonts,
    isLoading,
    error,
    setError,
    handleAddFont,
    deleteFont,
    updateFont,
    handleChangeFontName,
    handleChangeEmbedCode,
  } = useFonts();
  const [newFontName, setNewFontName] = React.useState<string>("");
  const [newFontEmbed, setNewFontEmbed] = React.useState<string>("");
  const [newFontError, setNewFontError] = React.useState<IResponseError | null>(
    null
  );
  const [editingFont, setEditingFont] = React.useState<number | null>(null);
  const [editingFontError, setEditingFontError] =
    React.useState<IResponseError | null>(null);
  // const [error, setError] = React.useState<string | null>(null);

  const classes = useStyles();

  const handleClose = () => {
    setNewFontError(null);
    setEditingFontError(null);
    setError(null);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle onClose={props.onClose}>Custom fonts</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Table aria-label="Custom fonts table">
            <TableHead>
              <TableCell component="th">Font Name</TableCell>
              <TableCell
                component="th"
                style={{ display: "flex", alignItems: "center" }}
              >
                Embed Code
                <Tooltip
                  title="Embed code is an HTML snippet that links to your external font file. Font providers like Google Fonts often provide these codes on their platform."
                  style={{ marginLeft: 8 }}
                >
                  <InfoOutlined color="disabled" fontSize="small" />
                </Tooltip>
              </TableCell>
              <TableCell component="th" aria-label="action column"></TableCell>
            </TableHead>
            <TableBody>
              <FontList
                fonts={fonts}
                editingFont={editingFont}
                setEditingFont={setEditingFont}
                handleChangeFontName={handleChangeFontName}
                handleChangeEmbedCode={(newCode: string, index: number) => {
                  setEditingFontError(null);
                  console.log("test");
                  setNewFontError(null);
                  handleChangeEmbedCode(newCode, index);
                }}
                deleteFont={deleteFont}
                updateFont={(index: number) =>
                  updateFont(index, setEditingFont, setEditingFontError)
                }
                classes={classes}
              />
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <TextField
                    label="New font name"
                    value={newFontName}
                    onChange={(e) => setNewFontName(e.target.value)}
                    fullWidth
                    error={Boolean(newFontError?.field === "fontName")}
                    helperText={
                      newFontError?.field === "fontName" ? newFontError.err : ""
                    }
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    label="New font embed code"
                    value={newFontEmbed}
                    onChange={(e) => {
                      setNewFontError(null);
                      setNewFontEmbed(e.target.value);
                    }}
                    fullWidth
                    multiline
                    error={Boolean(newFontError?.field === "embedCode")}
                    helperText={
                      newFontError?.field === "embedCode"
                        ? newFontError.err
                        : ""
                    }
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Button
                    color="secondary"
                    onClick={async () => {
                      if (
                        await handleAddFont(
                          newFontName,
                          newFontEmbed,
                          setNewFontError
                        )
                      ) {
                        setNewFontName("");
                        setNewFontEmbed("");
                      }
                    }}
                    style={{ marginBottom: -4 }}
                  >
                    Add Font
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </Dialog>
  );
};

export default CustomFontsModal;
