import properties from "./properties";
import rules from "./rules";

/**
 * Criteria Data object combining the hardcoded property and rules lists
 */
const CriteriaData = {
  properties: properties,
  rules: rules,
};

export default CriteriaData;
