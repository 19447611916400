import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

export type TableRow = {
  name: string;
  type: "NPS Survey" | "NPS Theme";
  lastModifiedDate: string;
  id: string;
};

interface IItemTable {
  rows?: TableRow[];
  onChange: (selected: string[]) => void;
  selectedItems: string[];
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 140,
  },
  {
    field: "type",
    headerName: "Type",
    with: 100,
  },
  {
    field: "lastModifiedDate",
    headerName: "Last Modified Date",
    width: 200,
  },
];
const ItemTable = (props: IItemTable) => {
  if (!props.rows) return null;

  const handleSelect = (selectionModel: any[]) => {
    props.onChange(selectionModel as string[]);
  };

  return (
    <DataGrid
      rows={props.rows || []}
      columns={columns}
      pageSize={8}
      disableSelectionOnClick
      checkboxSelection
      onSelectionModelChange={handleSelect}
      initialState={{
        sorting: {
          sortModel: [{ field: "lastModifiedDate", sort: "desc" }],
        },
      }}
      selectionModel={props.selectedItems}
    />
  );
};

export default ItemTable;
