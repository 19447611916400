import React, { SetStateAction } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useLayout } from "../../config/Styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { ChromePicker } from "react-color";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ICssObject } from "./util/jsonToCss";
import updateCss from "./util/updateCss";
import getStyle from "./util/getStyle";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import FontPicker from "./fonts/FontPicker";
import { FormControlLabel, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLarge: {
      flex: 3,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    inputSmall: {
      flex: 1,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      minWidth: 64,
    },
    colorButton: {
      width: 30,
      height: 30,
      border: `1px solid ${theme.palette.contrast.main}`,
      marginLeft: theme.spacing(1),
      borderRadius: 8,
      "&:hover": {
        cursor: "pointer",
      },
    },
    colorLabel: {
      flex: 1,
    },
    colorTextField: {
      width: 100,
    },
    colorRow: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    sectionTitle: {
      marginTop: theme.spacing(3),
    },
    section: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(2),
      borderLeft: `2px solid rgba(0,0,0,0.1)`,
    },
    sliderLabel: {
      alignSelf: "center",
    },
    infoIcon: {
      alignSelf: "baseline",
      marginLeft: theme.spacing(1),
    },
  })
);

/**
 * Hook for reusing a color picker input component
 * @param css Objectified css
 * @param setCss Function to update the css object
 * @param classes classes object for styling components
 * @param label The color picker input's label
 * @param selector (Optional) The selector to update in the css object
 * @param property (Optional) The property to update in the given selector's styles
 * @param options A default color if provided
 * NOTE: The selector and property paramaters are optional in case a hook is used that doesn't update the css
 * object directly (i.e. a backdrop color picker that mixes the color returned by this hook and an opacity slider into an rgba value)
 * @returns {[string, JSX.Element]} [The color chosen, The color picker component]
 */
const useColorPicker = (
  css: ICssObject,
  setCss: React.Dispatch<React.SetStateAction<ICssObject>>,
  classes: any,
  label: string,
  selector?: string,
  property?: string,
  options?: { defaultColor?: string }
): [string, JSX.Element] => {
  const [color, setColor] = React.useState<string>(
    options?.defaultColor || "#fff"
  );
  const [popoverAnchor, setPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const [uniqueId] = React.useState<number>(() => Math.random());

  /**
   * Update the preview css if the color picker's value changes
   * and if
   */
  React.useEffect(() => {
    if (selector && property) {
      // TODO: let the user know if there's an error in their css
      try {
        updateCss(css, setCss, selector, property, color);
      } catch (err) {
        console.error(err);
      }
    }
  }, [color]);

  const output = (
    <FormControl className={classes.colorRow}>
      <Typography id={`color-label-${uniqueId}`} className={classes.colorLabel}>
        {label} :{" "}
      </Typography>
      <TextField
        value={color}
        onChange={(e) => setColor(e.target.value)}
        aria-label="Hex code"
        className={classes.colorTextField}
      />
      <button
        className={classes.colorButton}
        aria-labelledby={`color-label-${uniqueId}`}
        aria-haspopup="true"
        onClick={(e) => setPopoverAnchor(e.currentTarget)}
        style={{ background: color }}
      ></button>
      <Popover
        id={`color-popover-${uniqueId}`}
        open={popoverAnchor ? true : false}
        onClose={() => setPopoverAnchor(null)}
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ChromePicker
          color={color}
          onChange={(color) => setColor(color.hex)}
          disableAlpha
        />
      </Popover>
    </FormControl>
  );

  return [color, output];
};

/**
 * Creates a color picker factory for color pickers that share common properties
 * @param css Preview css object
 * @param setCss Method that updates the css object
 * @param classes css classes object for styling components
 * @returns {(label: string, options: { defaultColor?: string; selector?: string; property?: string })=> [string, JSX.Element]} A method that generates a new color picker hook
 */
const createColorPickerFactory =
  (
    css: ICssObject,
    setCss: React.Dispatch<React.SetStateAction<ICssObject>>,
    classes: any
  ) =>
  (
    label: string,
    options: { defaultColor?: string; selector?: string; property?: string }
  ) =>
    useColorPicker(
      css,
      setCss,
      classes,
      label,
      options.selector,
      options.property,
      options
    );

interface IPointAndClick {
  css: ICssObject;
  setCss: React.Dispatch<SetStateAction<ICssObject>>;
}

/**
 * Point and click theme editor component
 * @param {IPointAndClick} props Css object and method to set the css object
 */
const PointAndClick = (props: IPointAndClick) => {
  const classes = useStyles();
  const layout = useLayout();

  // color picker factory
  const createColorPicker = createColorPickerFactory(
    props.css,
    props.setCss,
    classes
  );

  // color pickers
  const [containerColor, containerColorComponent] = createColorPicker(
    "Survey color",
    {
      selector: ".zengage-container",
      property: "background",
      defaultColor: props.css[".zengage-container"]?.background,
    }
  );
  const [headerColor, headerColorComponent] = createColorPicker(
    "Header font color",
    {
      selector: ".zengage-text-header",
      property: "color",
      defaultColor: props.css[".zengage-text-header"]?.color,
    }
  );
  const [bodyColor, bodyColorComponent] = createColorPicker("Body font color", {
    selector: ".zengage-text-body",
    property: "color",
    defaultColor: props.css[".zengage-text-body"]?.color,
  });
  // button color hooks
  const [primaryButtonColor, primaryButtonColorComponent] = createColorPicker(
    "Primary button color",
    {
      selector: ".zengage-button-primary",
      property: "background",
      defaultColor: props.css[".zengage-button-primary"]?.background,
    }
  );
  const [primaryButtonHoverColor, primaryButtonHoverColorComponent] =
    createColorPicker("Primary button hover color", {
      selector: ".zengage-button-primary:hover",
      property: "background",
      defaultColor: props.css[".zengage-button-primary:hover"]?.background,
    });
  const [primaryButtonFontColor, primaryButtonFontColorComponent] =
    createColorPicker("Primary button font color", {
      selector: ".zengage-button-primary",
      property: "color",
      defaultColor: props.css[".zengage-button-primary"]?.color,
    });
  const [secondaryButtonColor, secondaryButtonColorComponent] =
    createColorPicker("Secondary button color", {
      selector: ".zengage-button-secondary",
      property: "background",
      defaultColor: props.css[".zengage-button-secondary"]?.background,
    });
  const [secondaryButtonHoverColor, secondaryButtonHoverColorComponent] =
    createColorPicker("Secondary button hover color", {
      selector: ".zengage-button-secondary:hover",
      property: "background",
      defaultColor: props.css[".zengage-button-secondary:hover"]?.background,
    });
  const [secondaryButtonFontColor, secondaryButtonFontColorComponent] =
    createColorPicker("Secondary button font color", {
      selector: ".zengage-button-secondary",
      property: "color",
      defaultColor: props.css[".zengage-button-secondary"]?.color,
    });

  /**
   * Updates the theme's css
   * @param selector The css selector to be updated
   * @param property The property to update in the given css selector's styles
   * @param newValue The new value to assign the property to
   */
  const update = (selector: string, property: string, newValue: any) =>
    updateCss(props.css, props.setCss, selector, property, newValue);

  // header states
  const [headerFont, setHeaderFont] = React.useState<string>(
    getStyle(props.css, ".zengage-text-header", "font-family") || "Arial"
  );
  // hook for updating the stylesheet whenever the header font is changed
  React.useEffect(
    () => update(".zengage-text-header", "font-family", headerFont),
    [headerFont]
  );
  const [headerFontSize, setHeaderFontSize] = React.useState<number>(
    // Convert the font size from #px to #.
    // If NaN or undefined, default to 16
    Number(
      getStyle(props.css, ".zengage-text-header", "font-size")?.split("px")[0]
    ) || 16
  );
  React.useEffect(
    () => update(".zengage-text-header", "font-size", `${headerFontSize}px`),
    [headerFontSize]
  );

  // body states
  const [bodyFont, setBodyFont] = React.useState<string>(
    getStyle(props.css, ".zengage-text-body", "font-family") || "Arial"
  );
  React.useEffect(
    () => update(".zengage-text-body", "font-family", bodyFont),
    [bodyFont]
  );
  const [bodyFontSize, setBodyFontSize] = React.useState<number>(
    Number(
      getStyle(props.css, ".zengage-text-body", "font-size")?.split("px")[0]
    ) || 12
  );
  React.useEffect(
    () => update(".zengage-text-body", "font-size", `${bodyFontSize}px`),
    [bodyFontSize]
  );

  // button border-radius
  const [buttonRoundness, setButtonRoundness] = React.useState<
    "0px" | "8px" | "custom"
  >(() => {
    // get the border-radius of both button types (no border radius is equivalent to a 0px border radius)
    const primaryRadius =
      getStyle(props.css, ".zengage-button-primary", "border-radius") || "0px";
    const secondaryRadius =
      getStyle(props.css, ".zengage-button-primary", "border-radius") || "0px";

    if (primaryRadius === "0px" && secondaryRadius === "0px") return "0px";
    else if (primaryRadius === "8px" && secondaryRadius === "8px") return "8px";
    return "custom";
  });

  const [uppercaseButtons, setUppsercaseButtons] = React.useState<boolean>(
    getStyle(props.css, ".zengage-button-secondary", "text-transform") ===
      "uppercase"
  );

  React.useEffect(() => {
    update(
      ".zengage-button-primary",
      "text-transform",
      uppercaseButtons ? "uppercase" : "none"
    );
    update(
      ".zengage-button-secondary",
      "text-transform",
      uppercaseButtons ? "uppercase" : "none"
    );
  }, [uppercaseButtons]);

  /**
   * If the button roundness is ever set to 0px or 8px, update the theme css with those values
   */
  React.useEffect(() => {
    if (buttonRoundness !== "custom") {
      update(".zengage-button-primary", "border-radius", buttonRoundness);
      update(".zengage-button-secondary", "border-radius", buttonRoundness);
    }
  }, [buttonRoundness]);

  return (
    <div>
      {/* Container section */}
      <Typography variant="subtitle2">Survey</Typography>
      <div className={classes.section}>{containerColorComponent}</div>
      {/* Font section */}
      <Typography variant="subtitle2">Fonts</Typography>
      <div className={classes.section}>
        <FontPicker
          headerFont={headerFont}
          setHeaderFont={setHeaderFont}
          bodyFont={bodyFont}
          setBodyFont={setBodyFont}
          headerFontSize={headerFontSize}
          setHeaderFontSize={setHeaderFontSize}
          bodyFontSize={bodyFontSize}
          setBodyFontSize={setBodyFontSize}
          classes={classes}
        />
        {headerColorComponent}
        {bodyColorComponent}
      </div>
      {/* Button section */}
      <Typography variant="subtitle2" className={classes.sectionTitle}>
        Buttons
      </Typography>
      <div className={classes.section}>
        <FormControl className={classes.inputLarge} fullWidth>
          <InputLabel id="button-roundness-select">Button roundness</InputLabel>
          <Select
            labelId="button-roundness-select"
            value={buttonRoundness ? buttonRoundness : "8px"}
            onChange={(e) =>
              setButtonRoundness(e.target.value as "0px" | "8px" | "custom")
            }
          >
            <MenuItem value="0px">Square corners</MenuItem>
            <MenuItem value="8px">Rounded corners</MenuItem>
            <MenuItem value="custom">
              <div className={layout.row}>
                <Typography>Custom </Typography>
                <Tooltip title="For custom values set in the CSS editor">
                  <InfoIcon className={classes.infoIcon} color="disabled" />
                </Tooltip>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
        {primaryButtonColorComponent}
        {primaryButtonHoverColorComponent}
        {primaryButtonFontColorComponent}
        {secondaryButtonColorComponent}
        {secondaryButtonHoverColorComponent}
        {secondaryButtonFontColorComponent}
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={uppercaseButtons}
              onChange={(e) => setUppsercaseButtons(e.target.checked)}
            />
          }
          label="Uppercase buttons"
          labelPlacement="start"
          style={{
            alignSelf: "flex-start",
            margin: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 92,
          }}
        />
      </div>
      {/* Backdrop editor */}
      {/* <Typography variant="subtitle2" className={classes.sectionTitle}>
        Backdrop
      </Typography>
      <div className={classes.section}>
        {backdropColorComponent}
        <div className={layout.row}>
          <Typography
            id="opacity-label"
            style={{ alignSelf: "center", marginRight: theme.spacing(3) }}
          >
            Backdrop opacity :{" "}
          </Typography>
          <div className={layout.row}>
            <Typography
              className={classes.sliderLabel}
              style={{ marginRight: theme.spacing(2) }}
            >
              0%
            </Typography>
            <Slider
              aria-label="opacity-label"
              min={0}
              max={100}
              value={backdropOpacity}
              onChange={(e, newValue) => setBackdropOpacity(newValue as number)}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
            />
            <Typography
              className={classes.sliderLabel}
              style={{ marginLeft: theme.spacing(2) }}
            >
              100%
            </Typography>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default PointAndClick;
