import { ITheme } from "../config/Theme";
import { IApi } from "./types";

export const themesRequest: IApi = {
  url: "/api/v1/nps/themes",
  defaultError:
    "Something went wrong while trying to fetch themes. Please try again later.",
  tag: "themes",
  responseProp: "themes",
  useQueryParams: {
    staleTime: 30 * 60 * 1000,
  },
};

export const getThemeRequest = (themeID: string): IApi<ITheme> => ({
  url: `/api/v1/nps/themes/${themeID}`,
  defaultError:
    "Something went wrong while trying to fetch this theme. Please try again later.",
  responseProp: "theme",
  tag: ["theme", Number(themeID)],
});

export const fontsRequest: IApi = {
  url: "/api/v1/nps/themes/fonts",
  defaultError:
    "Something went wrong while trying to fetch custom fonts. Please try again later.",
  responseProp: "fonts",
  tag: "fonts",
};

export const segmentsRequest: IApi = {
  url: "/api/v1/nps/segments/list",
  defaultError:
    "Something went wrong while fetching segment list. Please try agan later.",
  responseProp: "segments",
  tag: "segments",
};

export const getSegmentRequest = (segmentID: string): IApi => ({
  url: `/api/v1/nps/segments/${segmentID}`,
  defaultError:
    "Something went wrong while trying to fetch this segment. Please try again later.",
  responseProp: "segment",
  tag: ["segment", Number(segmentID)],
});

export const getSegmentNpsRequest = (days: number): IApi => ({
  url: `/api/v1/nps/segments/nps?days=${days}`,
  defaultError:
    "Something went wrong while trying to fetch segment nps score data. Please try again later.",
  responseProp: "segmentNpsScores",
  tag: ["segmentNpsData", days],
  useQueryParams: { staleTime: 2 * 60 * 60 * 1000 },
});

export const getOverviewRequest = (days: number): IApi => ({
  url: `/api/v1/nps/overview?days=${days}`,
  defaultError:
    "Something went wrong while trying to fetch overview data. Please try again later.",
  responseProp: "overview",
  tag: ["overview", days],
  useQueryParams: { staleTime: 2 * 60 * 60 * 1000 },
});

export const getNpsScoreRequest = (days: number): IApi => ({
  url: `/api/v1/nps/score?days=${days}`,
  defaultError:
    "Something went wrong while trying to fetch your NPS score. Please try again later.",
  responseProp: "score",
  tag: ["score", days],
  useQueryParams: { staleTime: 2 * 60 * 60 * 1000 },
});

export const getNpsGraphDataRequest = (days: number): IApi => ({
  url: `/api/v1/nps/graphData?days=${days}`,
  defaultError:
    "Something went wrong while trying to fetch your NPS graph. Please try again later.",
  responseProp: "npsData",
  tag: ["graphData", days],
  useQueryParams: { staleTime: 2 * 60 * 60 * 1000 },
});

export const recentCommentsRequest: IApi = {
  url: `/api/v1/nps/recentComments`,
  defaultError:
    "Something went wrong while trying to fetch recent comments. Please try again later.",
  responseProp: "responses",
  tag: "recentComments",
};

export const getResponsesRequest = (rows: number, page: number): IApi => ({
  url: `/api/v1/nps/responses`,
  defaultError:
    "Something went wrong while fetching responses. Please try again later.",
  responseProp: "responses",
  tag: "responses",
});

export const getResponseRequest = (
  visitorID: number,
  nps_responseDate: string
): IApi => ({
  url: `/api/v1/nps/response?visitorID=${visitorID}&nps_responseDate=${nps_responseDate}`,
  defaultError:
    "Something went wrong while fetching response data. Please try again later.",
  responseProp: "response",
  tag: ["response", `${visitorID}_${nps_responseDate}`],
  useQueryParams: {
    enabled: false,
  },
});

export const surveysRequest: IApi = {
  url: "/api/v1/nps/surveys/",
  defaultError:
    "Something went wrong while fetching surveys. Please try again later.",
  responseProp: "surveys",
  tag: "surveys",
};

export const getSurveyRequest = (surveyID: number): IApi => ({
  url: `/api/v1/nps/surveys/${surveyID}`,
  defaultError:
    "Something went wrong while fetching survey data. Please try again later.",
  responseProp: "survey",
  tag: ["survey", surveyID],
});

export const getTagsRequest = (days: number): IApi => ({
  url: `/api/v1/nps/tags?days=${days}`,
  defaultError:
    "Something went wrong while trying to fetch tag data. Please try again later.",
  responseProp: "tags",
  tag: ["tags", days],
  useQueryParams: { staleTime: 2 * 60 * 60 * 1000 },
});

export const tagListRequest: IApi = {
  url: "/api/v1/nps/tagList",
  defaultError: "Something went wrong while fetching the list of tags",
  responseProp: "tagList",
  tag: "tagList",
};
