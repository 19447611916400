import React from "react";
import { ICriteriaData } from "../Components/Segments/CriteriaBuilder";
import { mapResponsePropertiesToHeadCells } from "../Components/Tables/NPS/npsResponseCells";
import { HeadCell, Order } from "../Components/Tables/TableHead";

export interface IFilter {
  propertyID: string;
  order: Order;
  property_typeName: "String" | "Flag" | "Date" | "Number";
}

interface IFilterCriteria {
  criteriaType: "segment" | "custom" | "none";
  segmentID?: number;
  criteria?: ICriteriaData;
}

export interface IResponseContext {
  ignoredNpsScoreThreshold: number;
  currentFilter?: IFilter;
  lastFilter?: IFilter;
  updateFilter: (newFilter: IFilter) => void;
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => void;
  columns: HeadCell[];
  setColumns: (newColumns: HeadCell[]) => void;
  rowsPerPage: number;
  updateRowsPerPage: (newRowsPerPage: number) => void;
  filterCriteria: IFilterCriteria;
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => void;
  lastNDays?: number;
  updateLastNDays?: (newLastNDays: number) => void;
}
const defaultState: IResponseContext = {
  columns: mapResponsePropertiesToHeadCells([
    "nps_responseDate",
    "npsRating",
    "npsComment",
    "visitorFullName",
    "visitorEmail",
    "accountName",
  ]),
  setColumns: (newColumns: HeadCell[]) => null,
  updateFilter: (newFilter: IFilter) => null,
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => null,
  ignoredNpsScoreThreshold: 365,
  rowsPerPage: 20,
  updateRowsPerPage: (newRowsPerPage: number) => null,
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => null,
  filterCriteria: { criteriaType: "custom" },
  lastNDays: 90,
  updateLastNDays: (newLastNDays: number) => null,
};

export const ResponseContext =
  React.createContext<IResponseContext>(defaultState);

/**
 * Provider component for handling global response state (browser storage handler)
 */
export const ResponseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [columns, setColumns] = React.useState<HeadCell[]>(() => {
    const localColumns = localStorage.getItem("responseColumns");
    try {
      return localColumns ? JSON.parse(localColumns) : defaultState.columns;
    } catch (err) {
      console.error(err);
      return defaultState.columns;
    }
  });
  const [currentFilter, setCurrentFilter] = React.useState<IFilter>(() => {
    const localFilter = localStorage.getItem("responseSortBy");
    try {
      if (localFilter) return JSON.parse(localFilter);
    } catch (err) {
      console.error(err);
    }
  });
  const [lastFilter, setLastFilter] = React.useState<IFilter | undefined>();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(() => {
    const localRowsPerPage = localStorage.getItem("responseRowsPerPage");
    return localRowsPerPage
      ? Number(localRowsPerPage)
      : defaultState.rowsPerPage;
  });
  const [filterCriteria, setFilterCriteria] = React.useState<IFilterCriteria>(
    () => {
      const localFilterCriteria = localStorage.getItem(
        "responseFilterCriteria"
      );
      try {
        if (localFilterCriteria) return JSON.parse(localFilterCriteria);
        return defaultState.filterCriteria;
      } catch (err) {
        return defaultState.filterCriteria;
      }
    }
  );
  const [lastNDays, setLastNDays] = React.useState<number | undefined>(() => {
    const localLastNDays = localStorage.getItem("responseLastNDays");
    return localLastNDays ? Number(localLastNDays) : defaultState.lastNDays;
  });

  const updateLastNDays = (newLastNDays: number) => setLastNDays(newLastNDays);

  const updateRowsPerPage = (newRowsPerPage: number) =>
    setRowsPerPage(newRowsPerPage);

  const updateFilter = (newFilter: IFilter) => {
    // save previous filter only if it's sorting by a different column than the new filter
    if (currentFilter && newFilter.propertyID !== currentFilter.propertyID) {
      setLastFilter(currentFilter);
    }
    // update the filter
    setCurrentFilter(newFilter);
  };

  const updateFilterCriteria = (newCriteria: IFilterCriteria | undefined) => {
    if (newCriteria) setFilterCriteria(newCriteria);
  };

  // Update local storage whenever there's a state change
  React.useEffect(() => {
    localStorage.setItem("responseColumns", JSON.stringify(columns));
  }, [columns]);

  React.useEffect(() => {
    localStorage.setItem("responseSortBy", JSON.stringify(currentFilter));
  }, [currentFilter]);

  React.useEffect(() => {
    localStorage.setItem("responseRowsPerPage", rowsPerPage.toString());
  }, [rowsPerPage]);

  React.useEffect(() => {
    localStorage.setItem(
      "responseFilterCriteria",
      JSON.stringify(filterCriteria)
    );
  });

  React.useEffect(() => {
    if (lastNDays)
      localStorage.setItem("responseLastNDays", lastNDays.toString());
  }, [lastNDays]);

  return (
    <ResponseContext.Provider
      value={{
        ...defaultState,
        columns,
        setColumns,
        currentFilter,
        lastFilter,
        updateFilter,
        rowsPerPage,
        updateRowsPerPage,
        filterCriteria,
        updateFilterCriteria,
        lastNDays,
        updateLastNDays,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
};
