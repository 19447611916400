import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { IDataWithUnparsedProperties } from "../../../queries/types";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import visitorCells from "./visitorCells";
import AccountModal from "../Account/AccountModal";
import DialogTitle from "../../DialogTitle";
import { useApi } from "../../../queries/util";
import { getVisitorRequest } from "../../../queries/orgRequests";
import Loading from "../../Loading";
import { Tab, Tabs, Typography } from "@material-ui/core";
import { HeadCell } from "../TableHead";

export interface ICellDictionary {
  [key: string]: HeadCell;
}

export interface IDict {
  [key: string]: String | Date | Boolean | Number;
}

export interface ICustomProperties {
  customDates: IDict;
  customString: IDict;
  customNumbers: IDict;
  customFlags: IDict;
  [key: string]: IDict;
}

interface IVisitorModal {
  onClose: () => void;
  open: boolean;
  visitorID: number;
}

const lastVisitProperties = [
  "countryName",
  "languageName",
  "browserName",
  "browserVersionName",
  "doNotTrack",
  "userAgentName",
  "operatingSystemName",
  "deviceTypeName",
  "operatingSystemVersionName",
  "visitorLastSeen",
];

const keyMapping: IDict = {
  customStrings: "Custom Strings",
  customNumbers: "Custom Numbers",
  customBooleans: "Custom Flags",
};

/**
 * A modal that shows a given visitor's data
 * @param {() => void} onClose method that is invoked when the modal is closed
 * @param {boolean} open Whether or not the modal is currently open
 * @param {number} visitorID The visitorID of the visitor who's details will be shown
 */
const VisitorModal = (props: IVisitorModal) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [tab, setTab] = React.useState<number>(0);
  const {
    isLoading,
    data: visitorData,
    error: visitorError,
    refetch,
  } = useApi<IDataWithUnparsedProperties>(getVisitorRequest(props.visitorID));
  const [systemProps, setSystemProps] = React.useState<ICellDictionary>({});
  const [lastVisitProps, setLastVisitProps] = React.useState<ICellDictionary>(
    {}
  );
  const [customProps, setCustomProps] = React.useState<ICustomProperties>();

  /**
   * Refetches visitor data whenever a new visitorID is passed down to this component
   */
  React.useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.visitorID]);

  /**
   * Splits up visitor data into three categories whenever new visitor data is fetched
   * The categories are:
   *  - System Properties
   *  - Last Visit Properties
   *  - Custom Properties
   */
  React.useEffect(() => {
    if (visitorData) {
      const newSystemProps: ICellDictionary = {};
      const newLastVisitProps: ICellDictionary = {};
      const systemProperties = Object.keys(visitorCells);
      Object.keys(visitorData).forEach((key) => {
        if (lastVisitProperties.includes(key))
          newLastVisitProps[key] = visitorCells[key];
        else if (systemProperties.includes(key))
          newSystemProps[key] = visitorCells[key];
        else if (key === "visitorProperties") {
          setCustomProps(JSON.parse(visitorData[key].toString()));
        }
      });
      setSystemProps(newSystemProps);
      setLastVisitProps(newLastVisitProps);
    }
  }, [visitorData]);

  // modal handlers for viewing account
  const handleViewModal = (id: number) => {
    if (!isNaN(id)) {
      setSelected(id);
      setModalOpen(true);
    }
  };
  const handleModalClose = () => setModalOpen(false);

  // Build the component that displays system properties
  const systemPropsComponent = Object.keys(systemProps).map((key) => {
    if (!visitorData) return null;
    const property = systemProps[key];
    // format dates
    let value = visitorData[key];
    if (visitorCells[key].property_typeName === "Date")
      value = new Date(value as string).toISOString().split("T")[0];
    return (
      <DialogContentText key={`visitor_modal_${key}`}>
        <strong>
          {property.label}{" "}
          {property.helpText && (
            <Tooltip title={property.helpText}>
              <InfoIcon color="disabled" style={{ marginBottom: -7 }} />
            </Tooltip>
          )}
          :{" "}
        </strong>{" "}
        {/* Display a link if the current key is the account name */}
        {key === "accountName" ? (
          <Link
            component="button"
            color="secondary"
            aria-label="View account data"
            aria-haspopup="true"
            variant="body1"
            onClick={() => handleViewModal(Number(visitorData.accountID))}
          >
            {value ? value : "N/A"}
          </Link>
        ) : // otherwise, display the data corresponding to the current key
        value ? (
          value
        ) : (
          "N/A"
        )}
      </DialogContentText>
    );
  });

  // Build the component that displays last visit properties
  const lastVisitPropsComponent = Object.keys(lastVisitProps).map((key) => {
    if (!visitorData) return null;
    const property = lastVisitProps[key];
    // format dates
    let value = visitorData[key];
    if (visitorCells[key].property_typeName === "Date")
      value = new Date(value as string).toISOString().split("T")[0];
    return (
      <DialogContentText key={`visitor_modal_${key}`}>
        <strong>
          {property.label}{" "}
          {property.helpText && (
            <Tooltip title={property.helpText}>
              <InfoIcon color="disabled" style={{ marginBottom: -7 }} />
            </Tooltip>
          )}
          :{" "}
        </strong>{" "}
        {value ? value : "N/A"}
      </DialogContentText>
    );
  });

  // Build the component that displays custom properties
  const customPropertiesComponent = customProps && (
    <div>
      <Typography variant="subtitle2" color="textSecondary">
        Custom Dates
      </Typography>
      <div style={{ marginLeft: 16, marginTop: 8 }}>
        {Object.keys(customProps.customDates).map((key) => {
          const value = customProps.customDates[key];
          return (
            <DialogContentText key={`visitor_modal_${key}`}>
              <strong>{key}: </strong> {value ? value : "N/A"}
            </DialogContentText>
          );
        })}
      </div>
      {Object.keys(customProps).map((key) => {
        if (key === "customDates") return null;
        return (
          <div>
            <Typography variant="subtitle2" color="textSecondary">
              {keyMapping[key]}
            </Typography>
            <div style={{ marginLeft: 16, marginTop: 8 }}>
              {Object.keys(customProps[key]).map((property) => (
                <DialogContentText key={`visitor_modal_${key}`}>
                  <strong>{property}: </strong>{" "}
                  {customProps[key][property]
                    ? customProps[key][property]
                    : "N/A"}
                </DialogContentText>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );

  let currentComponent;
  switch (tab) {
    case 0:
      currentComponent = systemPropsComponent;
      break;
    case 1:
      currentComponent = lastVisitPropsComponent;
      break;
    case 2:
      currentComponent = customPropertiesComponent;
      break;
    default:
      currentComponent = (
        <Typography color="textSecondary" variant="body2">
          <i>Something went wrong.</i>
        </Typography>
      );
  }

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="visitor-modal-title"
      open={props.open}
      PaperProps={{ style: { minWidth: 400, width: "50vw" } }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DialogTitle onClose={props.onClose} id="visitor-modal-title">
            {visitorData &&
              (visitorData.visitorFullName
                ? visitorData.visitorFullName
                : visitorData.visitorFirstName +
                  " " +
                  visitorData.visitorLastName)}{" "}
            Visitor Properties
          </DialogTitle>
          <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(Number(newValue))}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Property category tabs"
            style={{
              marginTop: -16,
              marginBottom: 8,
            }}
          >
            <Tab
              label="System Properties"
              id="visitor-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
            <Tab
              label="Last Visit"
              id="visitor-tab-2"
              aria-controls="full-width-tabpanel-3"
            />
            <Tab
              label="Custom Properties"
              id="visitor-tab-3"
              aria-controls="full-width-tabpanel-3"
            />
          </Tabs>
          <DialogContent style={{ height: "45vh" }}>
            {currentComponent}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="secondary">
              Close
            </Button>
          </DialogActions>
          <AccountModal
            onClose={handleModalClose}
            open={modalOpen}
            accountID={selected}
          />
        </>
      )}
    </Dialog>
  );
};

export default VisitorModal;
