import { Button, Typography } from "@material-ui/core";
import { IPublishedHistory, IQueue } from "../../../../queries/types";
import { formatQueue } from "./PublishToTest";

interface IPublishingPage {
  queue: IQueue;
  testingItems: IPublishedHistory;
  onClose: () => void;
}

const PublishingPage = (props: IPublishingPage) => {
  const formattedQueue = formatQueue(props.queue) || [];
  const pendingItemCount = formattedQueue.length;

  if (pendingItemCount > 0) {
    return (
      <>
        <Typography>
          The items you selected are now live. There are {pendingItemCount} more
          items eligible to be published.
        </Typography>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            marginTop: 32,
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            style={{ width: 160, marginTop: 16, color: "white" }}
          >
            Publish ({pendingItemCount})
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ width: 160, marginTop: 16 }}
            onClick={props.onClose}
          >
            Close
          </Button>
        </div>
      </>
    );
  }
  return (
    <>
      <Typography>All of your changes are published to live.</Typography>
      <Button
        color="primary"
        variant="contained"
        style={{ width: 160, alignSelf: "end", marginTop: 16 }}
        onClick={props.onClose}
      >
        Close
      </Button>
    </>
  );
  // ? `The items you selected are now live. There are ${pendingItemCount} more items eligible to be published.`
  // : "All of your changes are published to live."}
};

export default PublishingPage;
