// Component for routes that require authentication
import React from "react";
import { Redirect, Route } from "react-router-dom";

/**
 * A route component that protects the route from unauthenticated users
 * @param {React.Component} component The component to be rendered when the given route is hit
 * @param {boolean} isAuthenticated Whether or not the current user is authenticated
 * @param {string} path The pathname associated with this route
 * @returns {Route | Redirect} A Route component if the user is authenticated and a Redirect to login if the user isn't authenticated
 */
const ProtectedRoute = ({ component, isAuthenticated, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/login" }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
