import React from "react";
import { ICriteriaData } from "../Components/Segments/CriteriaBuilder";
import { HeadCell, Order } from "../Components/Tables/TableHead";
import { mapAccountPropertiesToHeadCells } from "../Components/Tables/Account/accountCells";

export interface IFilter {
  propertyID: string;
  order: Order;
  property_typeName: "String" | "Flag" | "Date" | "Number";
}

interface IFilterCriteria {
  criteriaType: "segment" | "custom" | "none";
  segmentID?: number;
  criteria?: ICriteriaData;
}

export interface IAccountContext {
  currentFilter?: IFilter;
  lastFilter?: IFilter;
  ignoredNpsScoreThreshold: number;
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => void;
  updateFilter: (newFilter: IFilter) => void;
  columns: HeadCell[];
  setColumns: (newColumns: HeadCell[]) => void;
  rowsPerPage: number;
  updateRowsPerPage: (newRowsPerPage: number) => void;
  filterCriteria: IFilterCriteria;
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => void;
}
const defaultState: IAccountContext = {
  columns: mapAccountPropertiesToHeadCells([
    "accountLastSeen",
    "organizationAccountID",
    "accountName",
    "accountCreated",
  ]),
  setColumns: (newColumns: HeadCell[]) => null,
  updateFilter: (newFilter: IFilter) => null,
  rowsPerPage: 20,
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => null,
  ignoredNpsScoreThreshold: 365,
  updateRowsPerPage: (newRowsPerPage: number) => null,
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => null,
  filterCriteria: { criteriaType: "custom" },
};

export const AccountContext =
  React.createContext<IAccountContext>(defaultState);

/**
 * Provider component for handling global account state (browser storage handler)
 */
export const AccountProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [columns, setColumns] = React.useState<HeadCell[]>(() => {
    const localColumns = localStorage.getItem("accountColumns");
    try {
      return localColumns ? JSON.parse(localColumns) : defaultState.columns;
    } catch (err) {
      console.error(err);
      return defaultState.columns;
    }
  });
  const [currentFilter, setCurrentFilter] = React.useState<IFilter>(() => {
    const localFilter = localStorage.getItem("accountSortBy");
    try {
      if (localFilter) return JSON.parse(localFilter);
    } catch (err) {
      console.error(err);
    }
  });
  const [lastFilter, setLastFilter] = React.useState<IFilter | undefined>();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(() => {
    const localRowsPerPage = localStorage.getItem("accountRowsPerPage");
    return localRowsPerPage
      ? Number(localRowsPerPage)
      : defaultState.rowsPerPage;
  });
  const [filterCriteria, setFilterCriteria] = React.useState<IFilterCriteria>(
    () => {
      const localFilterCriteria = localStorage.getItem("accountFilterCriteria");
      try {
        if (localFilterCriteria) return JSON.parse(localFilterCriteria);
        return defaultState.filterCriteria;
      } catch (err) {
        return defaultState.filterCriteria;
      }
    }
  );

  const updateRowsPerPage = (newRowsPerPage: number) =>
    setRowsPerPage(newRowsPerPage);

  const updateFilter = (newFilter: IFilter) => {
    // save previous filter only if it's sorting by a different column than the new filter
    if (currentFilter && newFilter.propertyID !== currentFilter.propertyID) {
      setLastFilter(currentFilter);
    }
    // update the filter
    setCurrentFilter(newFilter);
  };

  const updateFilterCriteria = (newCriteria: IFilterCriteria | undefined) => {
    if (newCriteria) setFilterCriteria(newCriteria);
  };

  // Update local storage whenever there's a state change
  React.useEffect(() => {
    localStorage.setItem("accountColumns", JSON.stringify(columns));
  }, [columns]);

  React.useEffect(() => {
    localStorage.setItem("accountSortBy", JSON.stringify(currentFilter));
  }, [currentFilter]);

  React.useEffect(() => {
    localStorage.setItem("accountRowsPerPage", rowsPerPage.toString());
  }, [rowsPerPage]);

  React.useEffect(() => {
    localStorage.setItem(
      "accountFilterCriteria",
      JSON.stringify(filterCriteria)
    );
  });

  return (
    <AccountContext.Provider
      value={{
        ...defaultState,
        columns,
        setColumns,
        currentFilter,
        lastFilter,
        updateFilter,
        rowsPerPage,
        updateRowsPerPage,
        filterCriteria,
        updateFilterCriteria,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
