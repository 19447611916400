import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useUser } from "../../queries/user";
import { chooseOrg } from "../../queries/org";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { colors } from "../../config/Theme";
import { useMutation, useQueryClient } from "react-query";
import { IOrg, IOrgWithRestrictions, IUser } from "../../queries/types";
import Loading from "../Loading";
import { useApi } from "../../queries/util";
import { orgsRequest } from "../../queries/orgRequests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      minHeight: 50,
      padding: "0px 16px 18px 16px",
      alignItems: "end !important",
      width: "100%",
    },
    marginLeft: {
      marginLeft: 16,
    },
    orgIcon: {
      height: 30,
      width: 30,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.contrast.light,
    },
    orgIconContainer: {
      minWidth: 30,
      paddingRight: 15,
    },
  })
);

const OrgSelect = () => {
  const { isLoading: isLoadingUser, data: user } = useUser();
  const { isLoading: isLoadingOrgs, data: orgs } = useApi<IOrg[]>(orgsRequest);
  const [selectedOrg, setSelectedOrg] = React.useState<number | undefined>(
    user?.organizationID
  );
  const classes = useStyles();

  const queryClient = useQueryClient();
  const orgMutation = useMutation<IOrgWithRestrictions, Error, number>(
    chooseOrg,
    {
      /**
       * Attaches the selected org data to the user session when the mutation succeeds
       * @param {IOrgWithRestrictions} data The data returned by the mutation
       */
      onSuccess: (data: IOrgWithRestrictions) => {
        queryClient.setQueryData("user", (user: IUser | undefined) => ({
          ...(user as IUser),
          organizationID: data.organizationID,
          organizationName: data.organizationName,
          restrictions: data.restrictions,
        }));
      },
    }
  );

  const handleOrgSelect = (e: any) => {
    setSelectedOrg(e.target.value);
    orgMutation.mutate(e.target.value);
  };

  if (!isLoadingUser && !user) {
    queryClient.invalidateQueries("user");
  }
  if (isLoadingUser || isLoadingOrgs)
    return <Loading className={classes.form} />;

  return (
    <>
      {orgMutation.error && <Alert severity="error">{orgMutation.error}</Alert>}
      <FormControl className={classes.form}>
        {isLoadingUser || isLoadingOrgs ? (
          <Loading />
        ) : (
          <>
            <InputLabel id="org-select-label" className={classes.marginLeft}>
              Organization
            </InputLabel>
            <Select
              labelId="org-select-label"
              id="org-select"
              value={selectedOrg}
              onChange={handleOrgSelect}
              fullWidth
            >
              {orgs?.map((org, i) => (
                <MenuItem value={org.organizationID} key={org.organizationID}>
                  <ListItemIcon className={classes.orgIconContainer}>
                    <div
                      className={classes.orgIcon}
                      style={{ background: colors[i % colors.length] }}
                    >
                      <Typography variant="subtitle1">
                        {org.organizationName.substring(0, 1).toUpperCase()}
                      </Typography>
                    </div>
                  </ListItemIcon>
                  {org.organizationName}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </FormControl>
    </>
  );
};

export default OrgSelect;
