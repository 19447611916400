import { Button, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { IQueue } from "../../../../queries/types";
import ItemTable, { TableRow } from "../ItemTable";

interface IPublishToTest {
  queue: IQueue;
  next: () => void;
}

export interface IPublishRequest {
  surveys: number[];
  themes: number[];
}

export const formatQueue = (queue: IQueue) => {
  if (!queue) return;
  let newQueue: TableRow[] = [];
  newQueue = newQueue.concat(
    queue.surveys.map<TableRow>((survey) => ({
      name: survey.surveyName,
      type: "NPS Survey",
      lastModifiedDate: new Date(survey.lastModifiedDate)
        .toISOString()
        .split("T")[0],
      id: `survey_${survey.nps_surveyID.toString()}`,
    }))
  );
  newQueue = newQueue.concat(
    queue.themes.map<TableRow>((theme) => ({
      name: theme.themeName,
      type: "NPS Theme",
      lastModifiedDate: new Date(theme.lastModifiedDate)
        .toISOString()
        .split("T")[0],
      id: `theme_${theme.themeID.toString()}`,
    }))
  );
  return newQueue;
};

const publish = async (items: IPublishRequest, next: () => void) => {
  const response = await fetch("/api/v1/publishing/history", {
    method: "POST",
    body: JSON.stringify({ ...items, destination: "test" }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      console.log("success");
      next();
      break;
    case 422:
      console.log(await response.json());
      break;
    default:
      console.log("uh oh");
  }
};

const PublishToTest = (props: IPublishToTest) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    formatQueue(props.queue)?.map((item) => item.id) || []
  );

  const getButtonText = () => {
    if (
      !props.queue ||
      (props.queue.surveys.length === 0 && props.queue.themes.length === 0)
    ) {
      return "Next";
    }
    return "Publish to Test";
  };

  const handlePublish = async () => {
    const formattedQueue = formatQueue(props.queue);
    if (!formattedQueue) return;
    if (formattedQueue.length === 0) {
      return props.next();
    }
    // map all selected items to the complete item objects
    const itemsToPublish = selectedItems.map((id) => {
      const item = formattedQueue.find((item) => item.id === id);
      if (item) return { ...item, id: Number(item.id.split("_")[1]) };
      return null;
    });
    const formattedItems: IPublishRequest = {
      surveys: [],
      themes: [],
    };
    if (!itemsToPublish) return;
    for (let item of itemsToPublish) {
      if (!item) continue;
      if (item.type === "NPS Survey") formattedItems.surveys.push(item.id);
      else if (item.type === "NPS Theme") formattedItems.themes.push(item.id);
    }

    await publish(formattedItems, props.next);
  };

  const getPublishButton = () => (
    // Wrapped in a div so that the tooltip works when the button is disabled
    <div
      style={{
        display: "flex",
        width: 160,
        alignSelf: "end",
        marginTop: 16,
        marginBottom: 16,
      }}
    >
      <Button
        color={"primary"}
        disabled={selectedItems.length === 0}
        variant="contained"
        onClick={handlePublish}
      >
        {getButtonText()}
      </Button>
    </div>
  );

  return (
    <>
      <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
        Publish To Test
      </Typography>
      <div style={{ height: 400 }}>
        <ItemTable
          rows={formatQueue(props.queue)}
          onChange={(e) => setSelectedItems(e)}
          selectedItems={selectedItems}
        />
      </div>
      {selectedItems.length > 0 ? (
        getPublishButton()
      ) : (
        <Tooltip title="Select items to publish to test.">
          {getPublishButton()}
        </Tooltip>
      )}
    </>
  );
};

export default PublishToTest;
