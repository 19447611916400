import { parse } from "css";
import { IFont } from "../../../queries/types";

export function cssToObject(cssString: string, fonts: IFont[]): Record<string, any> {
  const parsed = parse(cssString);

  // Recursive function to convert parsed CSS AST into a JS object
  function convertRulesToObj(rules: any[]): Record<string, any> {
    const obj: Record<string, any> = {};

    for (const rule of rules) {
      if (rule.type === "rule") {
        obj[rule.selectors.join(", ")] = {};

        for (const declaration of rule.declarations) {
          if (declaration.type === "declaration") {
            obj[rule.selectors.join(", ")][declaration.property] =
              declaration.value;
          }
        }
      } else if (rule.type === "media") {
        obj[`@media ${rule.media}`] = convertRulesToObj(rule.rules);
      } else if (rule.type === "import") {
        if(!obj["@import"]) obj["@import"] = [];
        obj["@import"].push(rule.import);
      }
    }

    return obj;
  }
  if (!parsed.stylesheet) {
    throw new Error("Error parsing CSS");
  }
  const obj = convertRulesToObj(parsed.stylesheet.rules);
  if(fonts.length > 0) {
    obj["@import"] = [];
  }
  // ensure that the provided font embeds are imported
  for(const font of fonts) {
    const fontString = `url("${font.embedCode}")`;
    if(obj["@import"].indexOf(fontString) === -1) {
      obj["@import"].push(fontString);
    }
  }
  console.log(obj);
  return obj;
}
export default cssToObject;
