// material-ui imports
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Hidden, Typography } from "@material-ui/core";
import ResetPasswordForm from "../Components/Forms/ResetPasswordForm";

const spaceBetween = 25;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100vh",
      minWidth: "100vw",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        maxHeight: "calc(100vh - 100px)",
      },
    },
    appBar: {
      height: 100,
      width: "100vw",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    jumbo: {
      flex: 2,
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      transition: "flex 0.3s",
    },
    formRoot: {
      flex: 3,
      boxShadow: "-2px 0px 3px rgba(50, 50, 50, 0.1)",
      display: "flex",
      background: theme.palette.contrast.light,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100vw",
      transition: "flex 0.3s",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    buttonActive: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logoName: {
      marginTop: spaceBetween,
      flex: 1,
    },
  })
);

const ResetPassword = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      {/* Left side jumbo (hidden on mobile) */}
      <Hidden xsDown>
        <div className={classes.jumbo} style={{ flex: 2 }}>
          <svg height="100" width="100">
            <circle
              cx="50"
              cy="50"
              r="40"
              stroke="white"
              strokeWidth="8"
              fill="none"
            />
          </svg>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      {/* Top banner (hidden on desktop) */}
      <Hidden smUp>
        <div className={classes.appBar}>
          <Typography
            variant="h2"
            style={{ color: theme.palette.contrast.light }}
          >
            Zengage
          </Typography>
        </div>
      </Hidden>
      <div
        className={classes.formRoot}
        style={{
          flex: 3,
        }}
      >
        <div className={classes.column}>
          <Typography variant="h3" className={classes.logoName}>
            Reset Password
          </Typography>
          <ResetPasswordForm />
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
