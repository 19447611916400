import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Badge, createStyles, makeStyles, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ColumnEditor from "./ColumnEditor";
import { HeadCell } from "./TableHead";
import FilterModal from "./FilterModal";
import DialogTitle from "../DialogTitle";
import { IVisitorContext } from "../../Context/VisitorContext";
import ErrorSnackbar from "../ErrorSnackbar";
import { IAccountContext } from "../../Context/AccountContex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      color: theme.palette.contrast.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.contrast.light,
      },
      transition: "all 0.1s",
    },
    editColumnsModal: {
      minHeight: "80vh",
      minWidth: 400,
      maxWidth: 800,
      width: "70vw",
    },
    badge: {
      marginLeft: theme.spacing(0.5),
      marginTop: -theme.spacing(1.5),
    },
    clearFilterButton: {
      marginRight: theme.spacing(0.5),
    },
  })
);

interface ISettings {
  allColumns: { [key: string]: HeadCell };
  context: React.Context<IVisitorContext> | React.Context<IAccountContext>;
  type: "visitor" | "account" | "npsResponses";
}

/**
 * Component housing settings and export buttons for tables
 * @param {HeadCell dictionary} List of the current table's possible columns
 */
const Settings = (props: ISettings) => {
  const {
    ignoredNpsScoreThreshold,
    updateIgnoredNpsScoreThreshold,
    filterCriteria,
    updateFilterCriteria,
    columns,
    currentFilter,
    lastFilter,
  } = React.useContext(props.context);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [exportAnchorEl, setExportAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [changeDaysOpen, setChangeDaysOpen] = React.useState<boolean>(false);
  const [editColumnsOpen, setEditColumnsOpen] = React.useState<boolean>(false);
  const [daysToLookBack, setDaysToLookBack] = React.useState<number>(
    ignoredNpsScoreThreshold
  );
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);
  const [exportError, setExportError] = React.useState<string | null>(null);
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const openExport = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchorEl(e.currentTarget);
  };

  // Modal handlers
  const handleOpenDaysModal = () => {
    setAnchorEl(null);
    setChangeDaysOpen(true);
  };

  const handleCloseDaysModal = () => {
    setChangeDaysOpen(false);
  };

  const handleSubmitDaysModal = () => {
    updateIgnoredNpsScoreThreshold(daysToLookBack);
    handleCloseDaysModal();
  };

  const handleOpenColumnsModal = () => {
    setAnchorEl(null);
    setEditColumnsOpen(true);
  };

  const handleCloseColumnsModal = () => {
    setAnchorEl(null);
    setEditColumnsOpen(false);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const handleCloseExportPopover = () => {
    setExportAnchorEl(null);
  };

  // TODO: hook this up with a saved value
  const editDaysModal = (
    <Dialog
      open={changeDaysOpen}
      onClose={handleCloseDaysModal}
      aria-labelledby="edit-days-modal"
    >
      <DialogTitle onClose={handleCloseDaysModal} id="edit-days-modal">
        Edit ignored NPS scores
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Set the number of days to look back while calculating NPS scores.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="days"
          label="Days"
          type="number"
          value={daysToLookBack}
          fullWidth
          onChange={(e) => setDaysToLookBack(Number(e.target.value))}
        />
        <DialogContentText style={{ marginTop: 8 }}>
          Ignore NPS scores from more than {daysToLookBack} days ago.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDaysModal} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmitDaysModal} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const editColumnsModal = (
    <Dialog
      open={editColumnsOpen}
      onClose={handleCloseColumnsModal}
      aria-labelledby="edit-columns-modal"
      classes={{ paper: classes.editColumnsModal }}
    >
      <ColumnEditor
        allColumns={props.allColumns}
        context={props.context}
        onClose={handleCloseColumnsModal}
      />
    </Dialog>
  );

  const exportData = async () => {
    const response = await fetch("/api/v1/org/visitors/export", {
      method: "post",
      body: JSON.stringify({
        ignoredNpsScoreThreshold,
        filterCriteria: {
          ...filterCriteria,
          criteria: JSON.stringify(filterCriteria.criteria),
        },
        chosenProperties: columns.map((col) => ({
          visitorPropertyKey: col.id,
          isCustomProperty: col.isCustomProperty || false,
          property_typeName: col.property_typeName,
          customPropertyType: col.customPropertyType,
        })),
        sortBy: currentFilter,
        lastSortBy: lastFilter,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      if (response.body) {
        const url = window.URL.createObjectURL(
          new Blob([await response.blob()])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "visitors.csv");
        document.body.appendChild(link);
        link.click();
      }
      return response;
    } else if (response.status === 422) {
      setExportError((await response.json()).error);
    }
  };

  return (
    <div>
      <Tooltip title="Filter table data">
        <Button
          color="primary"
          aria-haspopup="true"
          onClick={() => setFilterModalOpen(true)}
        >
          Filter
          {filterCriteria.criteriaType !== "none" && (
            <Badge variant="dot" color="secondary" className={classes.badge} />
          )}
        </Button>
      </Tooltip>
      {filterCriteria.criteriaType !== "none" && (
        <Tooltip
          title="Clear current filter"
          className={classes.clearFilterButton}
        >
          <IconButton
            size="small"
            onClick={() =>
              updateFilterCriteria({ ...filterCriteria, criteriaType: "none" })
            }
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Button
        color="primary"
        aria-label="Open export options"
        aria-controls="export-popover"
        aria-haspopup="true"
        onClick={openExport}
      >
        Export
      </Button>
      <Popover
        id="export-popover"
        anchorEl={exportAnchorEl}
        open={Boolean(exportAnchorEl)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseExportPopover}
      >
        <List>
          <ListItem
            button
            onClick={() => {
              exportData();
              handleCloseExportPopover();
            }}
            className={classes.listItem}
          >
            Export current columns
          </ListItem>
          <ListItem
            button
            onClick={() => {
              exportData();
              handleCloseExportPopover();
            }}
            className={classes.listItem}
          >
            Export all columns
          </ListItem>
        </List>
      </Popover>
      <Tooltip title="Change table settings">
        <IconButton
          color="primary"
          aria-label="change table settings"
          aria-controls="visitor-table-settings"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="visitor-table-settings"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSettings}
      >
        <List>
          <ListItem disabled>Settings</ListItem>
          <ListItem
            button
            onClick={handleOpenColumnsModal}
            className={classes.listItem}
          >
            Edit columns
          </ListItem>
          {props.type === "visitor" && (
            <ListItem
              button
              onClick={handleOpenDaysModal}
              className={classes.listItem}
            >
              Edit ignored NPS scores
            </ListItem>
          )}
        </List>
      </Popover>

      {editDaysModal}
      {editColumnsModal}
      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        context={props.context}
        type={props.type}
      />
      <ErrorSnackbar
        snackbarOpen={Boolean(exportError)}
        handleSnackbarClose={() => setExportError(null)}
        error={exportError}
      />
    </div>
  );
};
export default Settings;
