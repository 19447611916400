export interface IRule {
  label: string;
  inputs: 0 | 1 | 2;
  inputType?: string;
}

// hardcoded rule list
const stringRules: IRule[] = [
  {
    label: "is not empty",
    inputs: 0,
  },
  { label: "is empty", inputs: 0 },
  { label: "contains", inputs: 1, inputType: "text" },
  { label: "starts with", inputs: 1, inputType: "text" },
  { label: "equals", inputs: 1, inputType: "text" },
  {
    label: "is in",
    inputs: 1,
    inputType: "multiline",
  },
];
const booleanRules: IRule[] = [
  {
    label: "is not empty",
    inputs: 0,
  },
  {
    label: "is empty",
    inputs: 0,
  },
  {
    label: "is true",
    inputs: 0,
  },
  {
    label: "is false",
    inputs: 0,
  },
];
const dateRules: IRule[] = [
  {
    label: "is not empty",
    inputs: 0,
  },
  {
    label: "is empty",
    inputs: 0,
  },
  {
    label: "on",
    inputs: 1,
    inputType: "date",
  },
  {
    label: "before",
    inputs: 1,
    inputType: "date",
  },
  {
    label: "after",
    inputs: 1,
    inputType: "date",
  },
  {
    label: "between",
    inputs: 2,
    inputType: "date",
  },
];
const numberRules: IRule[] = [
  {
    label: "is not empty",
    inputs: 0,
  },
  {
    label: "is empty",
    inputs: 0,
  },
  {
    label: "greater than",
    inputs: 1,
    inputType: "number",
  },
  {
    label: "greater than or equal to",
    inputs: 1,
    inputType: "number",
  },
  {
    label: "less than",
    inputs: 1,
    inputType: "number",
  },
  {
    label: "less than or equal to",
    inputs: 1,
    inputType: "number",
  },
  {
    label: "between",
    inputs: 2,
    inputType: "number",
  },
  {
    label: "equals",
    inputs: 1,
    inputType: "number",
  },
];

const rules = {
  strings: stringRules,
  booleans: booleanRules,
  dates: dateRules,
  numbers: numberRules,
};

export default rules;
