import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { npsChipColors } from "../../../config/Theme";
import { HeadCell } from "../TableHead";
import { Response } from "../../../queries/types";
import AccountModal from "../Account/AccountModal";
import ResponseModal from "./ResponseModal";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../../queries/util";
import { getResponsesRequest } from "../../../queries/npsRequests";
import { IFilter, ResponseContext } from "../../../Context/ResponseContext";
import ErrorSnackbar from "../../ErrorSnackbar";

interface ITag {
  npsTagName: string;
  nps_response_nps_tagID: number;
}

interface IResponseTableBody {
  cols: HeadCell[];
  page: number;
  rowsPerPage: number;
  classes: any;
  setLoading: (loading: boolean) => void;
  setNextPageDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITag {
  npsTagName: string;
  nps_response_nps_tagID: number;
}

const ResponseTableBody = (props: IResponseTableBody) => {
  const {
    currentFilter,
    lastFilter,
    rowsPerPage,
    filterCriteria,
    columns,
    lastNDays,
  } = React.useContext(ResponseContext);
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [selectedResponse, setSelectedResponse] = React.useState<{
    visitorID: number;
    nps_responseDate: string;
  }>({ visitorID: 0, nps_responseDate: "" });
  const [responseModalOpen, setResponseModalOpen] = React.useState(false);
  const [responseModalAutoFocus, setResponseModalAutoFocus] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [responses, setResponses] = React.useState<Response[]>();
  const [error, setError] = React.useState<string | null>(null);
  const [viewMoreIndices, setViewMoreIndices] = React.useState<number[]>([]);

  React.useEffect(() => props.setLoading(isLoading), [isLoading]);

  /**
   * Refetch response list whenever settings are changed
   */
  React.useEffect(() => {
    (async () => {
      try {
        // parse sorting data
        const sortBody: { sortBy: null | IFilter; lastSortBy: null | IFilter } =
          {
            sortBy: null,
            lastSortBy: null,
          };
        if (currentFilter) {
          const currentProp = props.cols.find(
            (col) => col.label === currentFilter.propertyID
          );
          if (currentProp) {
            sortBody.sortBy = {
              propertyID: currentProp.id,
              order: currentFilter.order,
              property_typeName: currentProp.property_typeName,
            };
          }
        }
        if (lastFilter) {
          const lastProp = props.cols.find(
            (col) => col.label === lastFilter.propertyID
          );
          if (lastProp) {
            sortBody.lastSortBy = {
              propertyID: lastProp.id,
              order: lastFilter.order,
              property_typeName: lastProp.property_typeName,
            };
          }
        }
        setIsLoading(true);
        const response = await fetch("/api/v1/nps/responses", {
          method: "post",
          body: JSON.stringify({
            rows: rowsPerPage,
            page: props.page,
            properties: columns.map((col) => ({
              responsePropertyKey: col.id,
              isCustomProperty: col.isCustomProperty || false,
              property_typeName: col.property_typeName,
              customPropertyType: col.customPropertyType,
            })),
            ...sortBody,
            filterCriteria: {
              ...filterCriteria,
              criteria: JSON.stringify(filterCriteria.criteria),
            },
            lastNDays: lastNDays,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        let json;
        switch (response.status) {
          case 422:
            json = await response.json();
            setError(json.error);
            console.log(json.error);
            break;
          case 200:
            json = await response.json();
            if (json.responses) {
              setResponses(json.responses);
            }
            break;
          default:
            setError("An unexpected error occured.");
        }
      } catch (err) {
        console.error(err);
        setError((err as Error).message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    lastNDays,
    columns,
    lastFilter,
    currentFilter,
    rowsPerPage,
    filterCriteria,
    props.page,
  ]);

  /**
   * Disable next page button if no extra resposne was given
   */
  React.useEffect(() => {
    if (responses && responses.length < rowsPerPage + 1)
      props.setNextPageDisabled(true);
    else props.setNextPageDisabled(false);
    setViewMoreIndices([]);
    console.log(responses);
  }, [responses]);

  // Modal handlers
  const handleOpenAccountModal = (id: number | undefined) => {
    if (id) {
      setSelected(id);
      setAccountModalOpen(true);
    }
  };
  const handleModalClose = () => setAccountModalOpen(false);
  const handleCloseResponseModal = () => {
    setResponseModalOpen(false);
  };

  const handleDeleteTag = async (
    rowIndex: number,
    tagIndex: number,
    tagID: number
  ) => {
    try {
      if (responses) {
        const clone = [...responses];
        const response = clone[rowIndex];
        if (response.npsTags) {
          let tags = JSON.parse(response.npsTags.toString());
          tags.splice(tagIndex, 1);
          clone[rowIndex].npsTags = JSON.stringify(tags);
          // delete the tag in the db
          const apiResponse = await fetch(
            `/api/v1/nps/response/tags/${tagID}`,
            {
              method: "delete",
            }
          );
          switch (apiResponse.status) {
            case 404:
              console.error("Unable to delete tag: Invalid tag id");
              break;
            case 200:
              setResponses(clone);
              break;
            default:
              console.log("Something went wrong.");
          }
        }
      }
    } catch (err) {
      console.error("Error deleting a tag");
    }
    // delete the tag on the server
  };

  const updateResponseTags = async (
    visitorID: number,
    nps_responseDate: string,
    newTags: ITag[]
  ) => {
    if (responses) {
      const newResponses = [...responses];
      const index = newResponses.findIndex(
        (response) =>
          response.visitorID === visitorID &&
          response.nps_responseDate === nps_responseDate
      );
      if (index > -1) {
        newResponses[index].npsTags = JSON.stringify(newTags);
        setResponses(newResponses);
      }
    }
  };

  const viewResponse = (
    visitorID: number | undefined,
    nps_responseDate: string | undefined,
    nps_response_nps_tagID: number | undefined,
    autoFocus?: true
  ) => {
    if (!visitorID || isNaN(visitorID) || !nps_responseDate) {
      return null;
    }
    if (autoFocus) setResponseModalAutoFocus(true);
    else setResponseModalAutoFocus(false);
    setSelectedResponse({ visitorID, nps_responseDate });
    setResponseModalOpen(true);
  };

  if (isLoading && !responses) {
    return (
      <>
        {[...new Array(props.rowsPerPage)].map((e: any, i) => (
          <TableRow key={`row_${i}`} hover className={props.classes.row}>
            {props.cols.map((col, j) => (
              <TableCell scope="row" align="left" key={`row_${i}_cell_${j}`}>
                <Skeleton></Skeleton>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      {/* Loop through all of the row data in the current page */}
      {responses?.map((row, i) => (
        <TableRow key={`row_${i}`} hover className={props.classes.row}>
          <TableCell
            scope="row"
            align="left"
            className={props.classes.tableCell}
          >
            <Button
              color="secondary"
              size="small"
              onClick={() =>
                viewResponse(
                  Number(row.visitorID),
                  row.nps_responseDate?.toString(),
                  Number(row.nps_response_nps_tagID)
                )
              }
              style={{ marginTop: 5 }}
            >
              View
            </Button>
          </TableCell>

          {props.cols.map((col, j) => {
            let tableBody;
            let formattedValue = row[col.id]
              ? row[col.id]?.toString()
              : "\u2014";
            if (col.property_typeName === "Date") {
              try {
                formattedValue = new Date(row[col.id] as string)
                  .toISOString()
                  .split("T")[0];
              } catch (err) {
                // console.error(err);
              }
            }
            // handles special cases such as displaying links to view visitor or account data
            switch (col.id) {
              case "npsRating":
                // skip null values
                if (row[col.id] === null) break;
                let background;
                let color;
                const rating = Number(row[col.id]);
                if (rating >= 9) {
                  background = npsChipColors.green;
                  color = npsChipColors.darkGreen;
                } else if (rating >= 7) {
                  background = npsChipColors.yellow;
                  color = npsChipColors.darkYellow;
                } else {
                  background = npsChipColors.red;
                  color = npsChipColors.darkRed;
                }

                tableBody = (
                  <Chip label={row[col.id]} style={{ background, color }} />
                );
                break;
              case "accountName":
                tableBody = (
                  <Link
                    component="button"
                    color="secondary"
                    variant="body1"
                    aria-label="View account detals"
                    aria-haspopup="true"
                    // TODO: map this to the right account
                    onClick={() =>
                      handleOpenAccountModal(Number(row.accountID))
                    }
                  >
                    <Typography noWrap>{row[col.id]?.toString()}</Typography>
                  </Link>
                );
                break;
              case "change":
                // skip null values
                if (row[col.id] === null) break;
                const change = Number(row[col.id]);
                let changeColor;
                const gtz = change > 0;
                if (gtz) changeColor = npsChipColors.darkGreen;
                else if (change < 0) changeColor = npsChipColors.darkRed;
                tableBody = (
                  <Typography style={{ color: changeColor }} noWrap>
                    {gtz ? `+${change}` : change}
                  </Typography>
                );
                break;
              case "npsTags":
                let tags: ITag[] = [];
                if (row.npsTags) {
                  try {
                    tags = JSON.parse(row.npsTags.toString());
                    const tagsToDisplay = viewMoreIndices.includes(i)
                      ? tags
                      : tags.slice(0, 3);
                    tableBody = (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className={props.classes.tagList}>
                          {tagsToDisplay.map((tag, tagIndex) => (
                            <>
                              <Chip
                                label={tag.npsTagName}
                                onDelete={() =>
                                  handleDeleteTag(
                                    i,
                                    tagIndex,
                                    tag.nps_response_nps_tagID
                                  )
                                }
                                key={tag.nps_response_nps_tagID}
                                style={{ marginRight: 8 }}
                                className={props.classes.tag}
                              />
                            </>
                          ))}
                        </div>
                        {tags.length > 3 && !viewMoreIndices.includes(i) && (
                          <Button
                            color="secondary"
                            aria-label="View all tags assigned to this response"
                            onClick={() =>
                              setViewMoreIndices((viewMoreIndices) =>
                                viewMoreIndices.concat([i])
                              )
                            }
                          >
                            More
                          </Button>
                        )}
                        <Tooltip title="Edit this response's tags">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              viewResponse(
                                Number(row.visitorID),
                                row.nps_responseDate?.toString(),
                                Number(row.nps_response_nps_tagID),
                                true
                              )
                            }
                            size="small"
                            style={{ padding: 2 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  } catch (err) {
                    tableBody = (
                      <Typography variant="body2" color="textSecondary">
                        <i>Something went wrong fetching tag data.</i>
                      </Typography>
                    );
                  }
                } else {
                  tableBody = (
                    <Tooltip title="Edit this response's tags">
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          viewResponse(
                            Number(row.visitorID),
                            row.nps_responseDate?.toString(),
                            Number(row.nps_response_nps_tagID),
                            true
                          )
                        }
                        size="small"
                        style={{ padding: 2 }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  );
                }

                break;
              default:
                tableBody = <Typography noWrap>{formattedValue}</Typography>;
            }
            return (
              <TableCell
                component={col.id === "accountName" ? "th" : "td"}
                scope="row"
                align="left"
                key={`row_${i}_cell_${j}`}
                className={props.classes.tableCell}
                style={
                  col.id === "tags" ? { minWidth: 100, maxWidth: 400 } : {}
                }
              >
                {tableBody}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
      <AccountModal
        onClose={handleModalClose}
        open={accountModalOpen}
        accountID={selected}
      />
      <ResponseModal
        onClose={handleCloseResponseModal}
        open={responseModalOpen}
        responseID={0}
        autoFocus={responseModalAutoFocus}
        visitorID={selectedResponse.visitorID}
        nps_responseDate={selectedResponse.nps_responseDate}
        updateResponseTags={updateResponseTags}
      />
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </>
  );
};

export default ResponseTableBody;
