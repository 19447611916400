import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  IDataWithUnparsedProperties,
  ITagWithoutAssignments,
  Response,
} from "../../../queries/types";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import npsResponseCells, { responseCells } from "./npsResponseCells";
import AccountModal from "../Account/AccountModal";
import DialogTitle from "../../DialogTitle";
import { useApi } from "../../../queries/util";
import {
  getResponseRequest,
  tagListRequest,
} from "../../../queries/npsRequests";
import {
  ICellDictionary,
  ICustomProperties,
  IDict,
} from "../Visitor/VisitorModal";
import visitorCells from "../Visitor/visitorCells";
import accountCells from "../Account/accountCells";
import { Tab, Tabs, Typography } from "@material-ui/core";

interface ITag {
  npsTagName: string;
  nps_response_nps_tagID: number;
}

const keyMapping: IDict = {
  customStrings: "Custom Strings",
  customNumbers: "Custom Numbers",
  customBooleans: "Custom Flags",
};

const getPropertyListComponent = (
  propertyList: ICellDictionary,
  responseData: IDataWithUnparsedProperties | undefined
) =>
  Object.keys(propertyList).map((key) => {
    if (!responseData) return null;
    const property = propertyList[key];
    // format dates
    let value = responseData[key];
    if (propertyList[key].property_typeName === "Date")
      value = new Date(value as string).toISOString().split("T")[0];
    return (
      <DialogContentText key={`nps_modal_${key}`}>
        <strong>
          {property.label}{" "}
          {property.helpText && (
            <Tooltip title={property.helpText}>
              <InfoIcon color="disabled" style={{ marginBottom: -7 }} />
            </Tooltip>
          )}
          :{" "}
        </strong>{" "}
        {value ? value : "N/A"}
      </DialogContentText>
    );
  });

const getCustomPropertyListComponent = (
  customProperties: ICustomProperties | undefined
) =>
  customProperties ? (
    <div>
      {customProperties.customDates && (
        <div>
          <Typography variant="subtitle2" color="textSecondary">
            Custom Dates
          </Typography>
          <div style={{ marginLeft: 16, marginTop: 8 }}>
            {Object.keys(customProperties.customDates).map((key) => {
              const value = customProperties.customDates[key];
              return (
                <DialogContentText key={`response_modal_${key}`}>
                  <strong>{key}: </strong> {value ? value : "N/A"}
                </DialogContentText>
              );
            })}
          </div>
        </div>
      )}
      {Object.keys(customProperties).map((key) => {
        if (key === "customDates") return null;
        return (
          <div>
            <Typography variant="subtitle2" color="textSecondary">
              {keyMapping[key]}
            </Typography>
            <div style={{ marginLeft: 16, marginTop: 8 }}>
              {customProperties[key] &&
                Object.keys(customProperties[key]).map((property) => (
                  <DialogContentText key={`visitor_modal_${property}`}>
                    <strong>{property}: </strong>{" "}
                    {customProperties[key][property]
                      ? customProperties[key][property]
                      : "N/A"}
                  </DialogContentText>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;

interface IResponseModal {
  onClose: () => void;
  open: boolean;
  responseID: number;
  autoFocus: boolean;
  visitorID: number;
  updateResponseTags: (
    visitorID: number,
    nps_responseDate: string,
    newTags: ITag[]
  ) => Promise<void>;
  nps_responseDate: string;
}

/**
 * A modal that shows a given response's data
 * @param {() => void} onClose method that is invoked when the modal is closed
 * @param {boolean} open Whether or not the modal is currently open
 * @param {number} responseID The responseID of the response who's details will be shown
 */
const ResponseModal = (props: IResponseModal) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [tab, setTab] = React.useState<number>(0);
  const {
    isLoading,
    data: responseData,
    error: responseError,
    refetch,
  } = useApi<IDataWithUnparsedProperties>(
    getResponseRequest(props.visitorID, props.nps_responseDate)
  );
  const [error, setError] = React.useState<string | null>(null);
  const [npsProperties, setNpsProperties] = React.useState<ICellDictionary>({});
  const [visitorProperties, setVisitorProperties] =
    React.useState<ICellDictionary>({});
  const [accountProperties, setAccountProperties] =
    React.useState<ICellDictionary>({});
  const [visitorCustomProperties, setVisitorCustomProperties] =
    React.useState<ICustomProperties>();
  const [accountCustomProperties, setAccountCustomProperties] =
    React.useState<ICustomProperties>();
  const [tags, setTags] = React.useState<ITag[]>([]);
  const [inputtedTags, setInputtedTags] = React.useState<string[]>([]);

  const {
    isLoading: isLoadingTagList,
    data: tagList,
    error: tagListError,
    refetch: refetchTagList,
  } = useApi<ITagWithoutAssignments[]>(tagListRequest);

  React.useEffect(() => {
    if (props.open) {
      console.log("refetch");
      setTab(0);
      refetch();
    }
  }, [props.open]);

  React.useEffect(() => {
    if (responseData) {
      const newVisitorProps: ICellDictionary = {};
      const newAccountProps: ICellDictionary = {};
      const newNpsProps: ICellDictionary = {};
      // assign each response property to the appropriate object
      Object.keys(responseData).forEach((key) => {
        if (responseCells.hasOwnProperty(key))
          newNpsProps[key] = responseCells[key];
        else if (accountCells.hasOwnProperty(key))
          newAccountProps[key] = accountCells[key];
        else if (visitorCells.hasOwnProperty(key))
          newVisitorProps[key] = visitorCells[key];
        else if (key === "visitorProperties")
          try {
            setVisitorCustomProperties(
              JSON.parse(responseData[key].toString())
            );
          } catch (err) {
            console.error(err);
          }
        else if (key === "accountProperties")
          try {
            setAccountCustomProperties(
              JSON.parse(responseData[key].toString())
            );
          } catch (err) {
            console.error(err);
          }
      });
      setVisitorProperties(newVisitorProps);
      setAccountProperties(newAccountProps);
      setNpsProperties(newNpsProps);
      if (responseData.npsTags) {
        try {
          setTags(JSON.parse(responseData.npsTags.toString()));
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [responseData]);

  React.useEffect(() => {
    // console.log(tags);
    setInputtedTags(tags.map((tag) => tag.npsTagName));
    props.updateResponseTags(props.visitorID, props.nps_responseDate, tags);
  }, [tags]);

  const handleModalClose = () => setModalOpen(false);

  const deleteTag = async (tag: ITag) => {
    const tagIndex = tags.indexOf(tag);
    // if somehow the provided tag isn't actually in the tag list, return
    if (tagIndex === -1) return false;
    const response = await fetch(
      `/api/v1/nps/response/tags/${tag.nps_response_nps_tagID}`,
      {
        method: "delete",
      }
    );
    switch (response.status) {
      case 404:
        console.log("Invalid tag id");
        break;
      case 200:
        let newTags = [...tags];
        newTags.splice(tagIndex, 1);
        console.log(newTags);
        setTags(newTags);
        break;
      default:
        console.log("Something went wrong.");
    }
  };

  const addTag = async (tagName: string) => {
    console.log(props.nps_responseDate);
    const response = await fetch("/api/v1/nps/response/tags", {
      method: "post",
      body: JSON.stringify({
        tagName,
        visitorID: props.visitorID,
        nps_responseDate: props.nps_responseDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    switch (response.status) {
      case 422:
        console.log("Please enter a valid tag name");
        break;
      case 200:
        const json = await response.json();
        console.log(json);
        setTags((tags) => tags.concat([json.newTag]));
    }
  };

  const handleTagsChange = async (newTags: string[]) => {
    // find difference between fetched tags and new tags
    for (let i = 0; i < Math.max(newTags.length, tags.length); i++) {
      if (i < newTags.length) {
        // a new tag was added
        if (!tags.find((tag) => tag.npsTagName === newTags[i]))
          await addTag(newTags[i]);
      }
      if (i < tags.length) {
        // a tag was deleted
        if (!newTags.includes(tags[i].npsTagName)) {
          await deleteTag(tags[i]);
        }
      }
    }
  };

  // Build the component that displays nps properties
  const npsPropsComponent = (
    <div>
      {getPropertyListComponent(npsProperties, responseData)}
      <Typography variant="subtitle2" color="textSecondary">
        Tags
      </Typography>
      <Autocomplete
        multiple
        id="response-tags"
        options={tagList?.map((tag) => tag.npsTagName) || []}
        getOptionDisabled={(tagName) => inputtedTags.includes(tagName)}
        freeSolo
        value={inputtedTags}
        onChange={(e, newValue) => handleTagsChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            aria-label="tag editor"
            placeholder="Tag"
            autoFocus={props.autoFocus}
          />
        )}
      />
    </div>
  );

  // Build the component that displays visitor properties
  const visitorPropsComponent = (
    <div>
      {getPropertyListComponent(visitorProperties, responseData)}{" "}
      <hr style={{ border: "1px solid rgba(0,0,0,0.1)", margin: "16px 0px" }} />
      {getCustomPropertyListComponent(visitorCustomProperties)}
    </div>
  );

  // Build the component that displays account properties
  const accountPropsComponent = (
    <div>
      {getPropertyListComponent(accountProperties, responseData)}
      <hr style={{ border: "1px solid rgba(0,0,0,0.1)", margin: "16px 0px" }} />
      {getCustomPropertyListComponent(accountCustomProperties)}
    </div>
  );

  let currentComponent;
  switch (tab) {
    case 0:
      currentComponent = npsPropsComponent;
      break;
    case 1:
      currentComponent = visitorPropsComponent;
      break;
    case 2:
      currentComponent = accountPropsComponent;
      break;
  }

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="response-modal-title"
      open={props.open}
      PaperProps={{ style: { minWidth: 400, width: "50vw" } }}
    >
      <DialogTitle onClose={props.onClose} id="response-modal-title">
        {responseData?.visitorFullName}'s NPS Response
      </DialogTitle>
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(Number(newValue))}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="Property category tabs"
        style={{
          marginTop: -16,
          marginBottom: 8,
        }}
      >
        <Tab
          label="Response Properties"
          id="response-tab-1"
          aria-controls="full-width-tabpanel-1"
        />
        <Tab
          label="Visitor Properties"
          id="response-tab-2"
          aria-controls="full-width-tabpanel-3"
        />
        <Tab
          label="Account Properties"
          id="response-tab-3"
          aria-controls="full-width-tabpanel-3"
        />
      </Tabs>

      <DialogContent style={{ height: "45vh" }}>
        {currentComponent}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
      <AccountModal
        onClose={handleModalClose}
        open={modalOpen}
        accountID={selected}
      />
    </Dialog>
  );
};

export default ResponseModal;
