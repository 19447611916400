import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomVisitorPropertyCard from "../../Components/Settings/CustomVisitorPropertyCard";
import { useApi } from "../../queries/util";
import { IVisitorProperty } from "../../queries/types";
import { visitorPropertiesRequest } from "../../queries/settingsRequests";
import Loading from "../../Components/Loading";
import ErrorSnackbar from "../../Components/ErrorSnackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      maxWidth: 1000,
    },
    pageTitle: {
      fontWeight: 400,
    },
    customPropertyContainer: {
      marginTop: theme.spacing(3),
    },
    cardTitle: {
      marginBottom: theme.spacing(2),
    },
    alternatingTableRow: {
      "&:nth-child(odd)": {
        background: theme.palette.background.default,
      },
      "& > *": {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      },
    },
    displayNameInput: {
      fontSize: "0.875rem",
      lineHeight: 1.43,
      fontWeight: 400,
    },
    examplesShownRow: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
      alignItems: "center",
      "& > *": {
        margin: `0px ${theme.spacing(0.5)}px`,
      },
      width: "100%",
    },
  })
);

const CustomVisitorProperties = () => {
  const {
    isLoading,
    data: customProperties,
    error: fetchError,
  } = useApi<IVisitorProperty[]>(visitorPropertiesRequest);
  const [error, setError] = React.useState<string | null>(null);
  const [dates, setDates] = React.useState<IVisitorProperty[] | null>(null);
  const [flags, setFlags] = React.useState<IVisitorProperty[] | null>(null);
  const [strings, setStrings] = React.useState<IVisitorProperty[] | null>(null);
  const [numbers, setNumbers] = React.useState<IVisitorProperty[] | null>(null);
  const classes = useStyles();

  /**
   * Group fetched properties by type whenever new properties are loaded
   */
  React.useEffect(() => {
    if (fetchError) setError(fetchError.message);
    if (customProperties) {
      const newDates = [];
      const newFlags = [];
      const newStrings = [];
      const newNumbers = [];
      for (let i = 0; i < customProperties.length; i++) {
        switch (customProperties[i].property_typeName) {
          case "Date":
            newDates.push(customProperties[i]);
            break;
          case "Flag":
            newFlags.push(customProperties[i]);
            break;
          case "String":
            newStrings.push(customProperties[i]);
            break;
          case "Number":
            newNumbers.push(customProperties[i]);
            break;
        }
      }
      setDates(newDates);
      setFlags(newFlags);
      setStrings(newStrings);
      setNumbers(newNumbers);
    }
  }, [fetchError, customProperties]);

  if (isLoading) return <Loading />;

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h4" component="h2">
        Visitor Custom Properties
      </Typography>
      <CustomVisitorPropertyCard
        classes={classes}
        title="Custom Strings"
        properties={strings}
      />
      <CustomVisitorPropertyCard
        classes={classes}
        title="Custom Dates"
        properties={dates}
      />
      <CustomVisitorPropertyCard
        classes={classes}
        title="Custom Flags"
        properties={flags}
      />
      <CustomVisitorPropertyCard
        classes={classes}
        title="Custom Numbers"
        properties={numbers}
      />
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default CustomVisitorProperties;
