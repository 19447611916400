import React from "react";
import { ICriteriaData } from "../Components/Segments/CriteriaBuilder";
import { HeadCell, Order } from "../Components/Tables/TableHead";
import { mapVisitorPropertiesToHeadCells } from "../Components/Tables/Visitor/visitorCells";

export interface IFilter {
  propertyID: string;
  order: Order;
  property_typeName: "String" | "Flag" | "Date" | "Number";
}

interface IFilterCriteria {
  criteriaType: "segment" | "custom" | "none";
  segmentID?: number;
  criteria?: ICriteriaData;
}

export interface IVisitorContext {
  ignoredNpsScoreThreshold: number;
  currentFilter?: IFilter;
  lastFilter?: IFilter;
  updateFilter: (newFilter: IFilter) => void;
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => void;
  columns: HeadCell[];
  setColumns: (newColumns: HeadCell[]) => void;
  rowsPerPage: number;
  updateRowsPerPage: (newRowsPerPage: number) => void;
  filterCriteria: IFilterCriteria;
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => void;
}
const defaultState: IVisitorContext = {
  columns: mapVisitorPropertiesToHeadCells([
    "visitorLastSeen",
    "visitorFullName",
    "visitorEmail",
    "accountName",
    "segments",
    "npsScore",
    "npsRating",
    "npsRatingDifference",
  ]),
  setColumns: (newColumns: HeadCell[]) => null,
  updateFilter: (newFilter: IFilter) => null,
  updateIgnoredNpsScoreThreshold: (newThreshold: number) => null,
  ignoredNpsScoreThreshold: 365,
  rowsPerPage: 20,
  updateRowsPerPage: (newRowsPerPage: number) => null,
  updateFilterCriteria: (newCriteria: IFilterCriteria | undefined) => null,
  filterCriteria: { criteriaType: "custom" },
};

export const VisitorContext =
  React.createContext<IVisitorContext>(defaultState);

/**
 * Provider component for handling global visitor state (browser storage handler)
 */
export const VisitorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [columns, setColumns] = React.useState<HeadCell[]>(() => {
    const localColumns = localStorage.getItem("visitorColumns");
    try {
      return localColumns ? JSON.parse(localColumns) : defaultState.columns;
    } catch (err) {
      console.error(err);
      return defaultState.columns;
    }
  });
  const [currentFilter, setCurrentFilter] = React.useState<IFilter>(() => {
    const localFilter = localStorage.getItem("visitorSortBy");
    try {
      if (localFilter) return JSON.parse(localFilter);
    } catch (err) {
      console.error(err);
    }
  });
  const [lastFilter, setLastFilter] = React.useState<IFilter | undefined>();
  const [ignoredNpsScoreThreshold, setIgnoredNpsScoreThreshold] =
    React.useState<number>(() => {
      const localIgnoredNpsScoreThreshold = localStorage.getItem(
        "visitorIgnoredNpsScoreThreshold"
      );
      return localIgnoredNpsScoreThreshold
        ? Number(localIgnoredNpsScoreThreshold)
        : defaultState.ignoredNpsScoreThreshold;
    });
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(() => {
    const localRowsPerPage = localStorage.getItem("visitorRowsPerPage");
    return localRowsPerPage
      ? Number(localRowsPerPage)
      : defaultState.rowsPerPage;
  });
  const [filterCriteria, setFilterCriteria] = React.useState<IFilterCriteria>(
    () => {
      const localFilterCriteria = localStorage.getItem("visitorFilterCriteria");
      try {
        if (localFilterCriteria) return JSON.parse(localFilterCriteria);
        return defaultState.filterCriteria;
      } catch (err) {
        return defaultState.filterCriteria;
      }
    }
  );

  const updateRowsPerPage = (newRowsPerPage: number) =>
    setRowsPerPage(newRowsPerPage);

  const updateFilter = (newFilter: IFilter) => {
    // save previous filter only if it's sorting by a different column than the new filter
    if (currentFilter && newFilter.propertyID !== currentFilter.propertyID) {
      setLastFilter(currentFilter);
    }
    // update the filter
    setCurrentFilter(newFilter);
  };

  const updateIgnoredNpsScoreThreshold = (newThreshold: number) =>
    setIgnoredNpsScoreThreshold(newThreshold);

  const updateFilterCriteria = (newCriteria: IFilterCriteria | undefined) => {
    if (newCriteria) setFilterCriteria(newCriteria);
  };

  // Update local storage whenever there's a state change
  React.useEffect(() => {
    localStorage.setItem("visitorColumns", JSON.stringify(columns));
  }, [columns]);

  React.useEffect(() => {
    localStorage.setItem("visitorSortBy", JSON.stringify(currentFilter));
  }, [currentFilter]);

  React.useEffect(() => {
    localStorage.setItem("visitorRowsPerPage", rowsPerPage.toString());
  }, [rowsPerPage]);

  React.useEffect(() => {
    localStorage.setItem(
      "visitorIgnoredNpsScoreThreshold",
      ignoredNpsScoreThreshold.toString()
    );
  }, [ignoredNpsScoreThreshold]);

  React.useEffect(() => {
    localStorage.setItem(
      "visitorFilterCriteria",
      JSON.stringify(filterCriteria)
    );
  });

  return (
    <VisitorContext.Provider
      value={{
        columns,
        setColumns,
        currentFilter,
        lastFilter,
        updateFilter,
        ignoredNpsScoreThreshold,
        updateIgnoredNpsScoreThreshold,
        rowsPerPage,
        updateRowsPerPage,
        filterCriteria,
        updateFilterCriteria,
      }}
    >
      {children}
    </VisitorContext.Provider>
  );
};
