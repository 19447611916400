import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import ViewPropertyModal from "./ViewPropertyModal";
import { IVisitorProperty } from "../../queries/types";
import Loading from "../Loading";
import VisitorPropertyEditor from "./VisitorPropertyEditor";

interface ICustomVisitorPropertyCard {
  classes: any;
  title: string;
  properties: IVisitorProperty[] | null;
}

const CustomVisitorPropertyCard = (props: ICustomVisitorPropertyCard) => {
  const { properties } = props;
  const { classes } = props;
  const [modalOpen, setModalOpen] = React.useState<number | null>(null);

  const closeModal = () => setModalOpen(null);

  /**
   * Opens the view modal
   * @param index The index of the property to view (To be implemented)
   */
  const updateViewModal = (index: number) => () => {
    setModalOpen(index);
  };

  return (
    <Card className={classes.customPropertyContainer}>
      <CardContent>
        <Typography variant="h6" className={classes.cardTitle}>
          {props.title}
        </Typography>
        {!properties ? (
          <Loading />
        ) : (
          <Table aria-label={`${props.title} table`}>
            <TableHead>
              <TableRow>
                <TableCell component="th">System Name</TableCell>
                <TableCell component="th">Display Name</TableCell>
                <TableCell component="th">Visible?</TableCell>
                <TableCell component="th">Examples</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties?.map((property, i) => (
                <React.Fragment key={property.visitor_propertyID}>
                  <VisitorPropertyEditor
                    classes={props.classes}
                    property={property}
                    setModalOpen={updateViewModal(i)}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
        <ViewPropertyModal
          onClose={closeModal}
          open={modalOpen !== null}
          classes={props.classes}
          type="visitor"
          // pass null as props if data hasn't been loaded yet or no property has been selected
          index={
            properties && modalOpen !== null
              ? properties[modalOpen].visitor_propertyID || null
              : null
          }
          systemName={
            properties && modalOpen !== null
              ? properties[modalOpen].visitorPropertyKey || null
              : null
          }
          displayName={
            properties && modalOpen !== null
              ? properties[modalOpen].visitorPropertyDisplay || null
              : null
          }
        />
      </CardContent>
    </Card>
  );
};

export default CustomVisitorPropertyCard;
