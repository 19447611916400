// import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useLayout } from "../config/Styles";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DashboardResponses from "../Components/NPS/DashbaordResponses";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Skeleton from "@material-ui/lab/Skeleton";
import Input from "@material-ui/core/Input";
import { ITheme } from "../config/Theme";
import { useApi } from "../queries/util";
import { getNpsScoreRequest } from "../queries/npsRequests";
import { IApi } from "../queries/types";
import {
  getAccountCountRequest,
  getVisitorCountRequest,
} from "../queries/orgRequests";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 1200,
    },
    card: {
      flex: 1,
      [theme.breakpoints.only("sm")]: {
        width: `calc(100vw - ${theme.drawerWidth + theme.spacing(6)}px)`,
      },
      [theme.breakpoints.down("xs")]: {
        width: `calc(100vw - ${theme.spacing(6)}px)`,
      },

      background: theme.palette.primary.main,
      color: "#fff",
    },
    cardTitle: {
      fontWeight: 400,
    },
    responses: {
      marginTop: theme.spacing(3),
    },
    cardInputContainer: {
      marginTop: theme.spacing(2),
    },
    cardInput: {
      color: theme.palette.primary.light,
      width: 100,
    },
  })
);

interface IDashboardCard {
  title: string;
  defaultDays: number;
  classes: ReturnType<typeof useStyles>;
  requestGetter: (days: number) => IApi;
}

const DashboardCard = (props: IDashboardCard) => {
  const [days, setDays] = React.useState<number>(props.defaultDays);
  const { isLoading, data, error } = useApi<number>(props.requestGetter(days));
  const uniqueId = `card-input-label-${Math.random()}`;

  return (
    <Card className={props.classes.card}>
      <CardContent>
        <Typography
          variant="subtitle2"
          className={props.classes.cardTitle}
          color="inherit"
        >
          {props.title}
        </Typography>
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <Typography
              variant="h4"
              component="h2"
              className={props.classes.cardTitle}
            >
              {isLoading ? (
                <>
                  {data} <CircularProgress color="inherit" size={24} />
                </>
              ) : (
                <>{data !== null ? data : "\u2014"}</>
              )}
            </Typography>
            <FormControl className={props.classes.cardInputContainer}>
              <Typography variant="subtitle2" color="inherit" id={uniqueId}>
                Look back
              </Typography>
              <Input
                id={`card-input-${props.title}`}
                aria-describedby={uniqueId}
                aria-label="Days to look back for calculating data"
                value={days}
                type="number"
                onChange={(e) => setDays(Number(e.target.value))}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography color="inherit">Days</Typography>
                  </InputAdornment>
                }
                className={props.classes.cardInput}
              />
            </FormControl>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  const classes = useStyles();
  const layout = useLayout();

  return (
    <div className={classes.root}>
      <div className={layout.evenRow}>
        <DashboardCard
          title="NPS Score"
          defaultDays={90}
          classes={classes}
          requestGetter={getNpsScoreRequest}
        />
        <DashboardCard
          title="Visitors"
          defaultDays={30}
          classes={classes}
          requestGetter={getVisitorCountRequest}
        />
        <DashboardCard
          title="Accounts"
          defaultDays={30}
          classes={classes}
          requestGetter={getAccountCountRequest}
        />
      </div>
      <div className={classes.responses}>
        <DashboardResponses />
      </div>
    </div>
  );
};

export default Dashboard;
