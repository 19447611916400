import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useLayout } from "../config/Styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { Link, useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { IThemeMetadata } from "../queries/types";
import Loading from "../Components/Loading";
import ErrorSnackbar from "../Components/ErrorSnackbar";
import { useApi } from "../queries/util";
import { themesRequest } from "../queries/npsRequests";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    title: {
      fontWeight: 400,
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
    container: {
      flex: 1,
      justifyContent: "center",
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, ${300 + theme.spacing(6)}px)`,
      margin: "auto",
    },
    center: {
      display: "flex",
      justifyContent: "center",
    },
    card: {
      flex: 1,
      width: 300,
      maxWidth: 300,
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexBasis: "auto",
      justifySelf: "flex-start",
    },
    contentOverride: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    swatch: {
      height: 148,
      display: "flex",
    },
    color: {
      flex: 1,
    },
    description: {
      padding: theme.spacing(2),
    },
    newThemeContainer: {
      height: 248,
      borderTop: `8px solid ${theme.palette.primary.main}`,
      // borderBottom: "2px solid rgba(0,0,0, 0.2)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    addButton: {
      fontSize: 100,
    },
  })
);

const Themes = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: themes,
    error: themeError,
  } = useApi<IThemeMetadata[]>(themesRequest);
  const [makingTheme, setMakingTheme] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const classes = useStyles();
  const layout = useLayout();
  const history = useHistory();

  React.useEffect(() => {
    if (themeError) setError(themeError.message);
  }, [themeError]);

  const createTheme = async () => {
    setMakingTheme(true);
    const response = await fetch("/api/v1/nps/themes", {
      method: "post",
    });
    if (response.status !== 200) {
      setMakingTheme(false);
      setError(
        "Something went wrong while trying to make a new theme. Please try again later."
      );
    } else {
      const json = await response.json();
      history.push(`/create-nps-theme/${json.themeID}`);
      queryClient.invalidateQueries("themes");
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Edit NPS Themes
      </Typography>
      <div className={classes.center}>
        {isLoading || makingTheme ? (
          <Loading />
        ) : (
          <div className={classes.container}>
            {themes?.map((theme, i) => (
              <Card className={classes.card} key={`theme${i}`}>
                <CardContent className={classes.contentOverride}>
                  <div
                    className={classes.swatch}
                    style={{ background: theme.backgroundColor }}
                  >
                    <div
                      className={classes.color}
                      style={{ background: theme.backgroundColor }}
                    ></div>
                    <div
                      className={classes.color}
                      style={{ background: theme.secondaryColor }}
                    ></div>
                    <div
                      className={classes.color}
                      style={{ background: theme.tertiaryColor }}
                    ></div>
                  </div>
                  <div className={classes.description}>
                    <div className={layout.betweenRow}>
                      <div>
                        <Typography variant="subtitle2">
                          {theme.themeName}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Last modified:{" "}
                          {
                            new Date(theme.lastModifiedDate)
                              .toISOString()
                              .split("T")[0]
                          }
                        </Typography>
                      </div>
                      <Tooltip title="Edit theme">
                        <Link to={`/edit-nps-theme/${theme.themeID}`}>
                          <IconButton
                            color="primary"
                            aria-label="Edit this theme"
                          >
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
            <Card className={classes.card}>
              <CardContent className={classes.contentOverride}>
                <div className={classes.newThemeContainer}>
                  <IconButton
                    color="primary"
                    aria-labelledby="create-label"
                    onClick={createTheme}
                  >
                    <AddIcon fontSize="inherit" className={classes.addButton} />
                  </IconButton>
                  <Typography variant="subtitle2" id="create-label">
                    Create a new theme
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </div>
  );
};

export default Themes;
