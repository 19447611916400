import { Button, Typography } from "@material-ui/core";
import { IPublishedHistory } from "../../../../queries/types";
import TestTable, { TableRow } from "../TestTable";
import React from "react";

interface IPublishToLive {
  items: IPublishedHistory;
  next: () => void;
}

interface IPublishToLiveRequest {
  published_nps_surveyIDs: number[];
  published_themeIDs: number[];
}

export const formatPublishedItems = (items: IPublishedHistory) => {
  if (!items) return;
  let newItems: TableRow[] = [];
  newItems = newItems.concat(
    items.surveys.map<TableRow>((survey) => ({
      name: survey.surveyName,
      type: "NPS Survey",
      lastPublishedDate: new Date(survey.publishDate)
        .toISOString()
        .split("T")[0],
      lastPublishedBy: survey.lastModifiedUserID.toString(),
      id: `survey_${survey.published_nps_surveyID.toString()}`,
    }))
  );
  newItems = newItems.concat(
    items.themes.map<TableRow>((theme) => ({
      name: theme.themeName,
      type: "NPS Theme",
      lastPublishedDate: new Date(theme.publishDate)
        .toISOString()
        .split("T")[0],
      lastPublishedBy: theme.lastModifiedUserID.toString(),
      id: `theme_${theme.published_themeID.toString()}`,
    }))
  );
  return newItems;
};

const publish = async (items: IPublishToLiveRequest, next: () => void) => {
  const response = await fetch("/api/v1/publishing/live", {
    method: "POST",
    body: JSON.stringify({ ...items }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  switch (response.status) {
    case 200:
      console.log("success");
      next();
      break;
    case 422:
      console.log(await response.json());
      break;
    default:
      console.log("uh oh");
  }
};

const PublishToLive = (props: IPublishToLive) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const handlePublish = async () => {
    const formattedHistory = formatPublishedItems(props.items);
    if (!formattedHistory) return;
    if (formattedHistory.length === 0) {
      return props.next();
    }
    // map all selected items to the complete item objects
    const itemsToPublish = selectedItems.map((id) => {
      const item = formattedHistory.find((item) => item.id === id);
      if (item) return { ...item, id: Number(item.id.split("_")[1]) };
      return null;
    });
    const formattedItems: IPublishToLiveRequest = {
      published_nps_surveyIDs: [],
      published_themeIDs: [],
    };
    if (!itemsToPublish) return;
    for (let item of itemsToPublish) {
      if (!item) continue;
      if (item.type === "NPS Survey")
        formattedItems.published_nps_surveyIDs.push(item.id);
      else if (item.type === "NPS Theme")
        formattedItems.published_themeIDs.push(item.id);
    }
    await publish(formattedItems, props.next);
  };

  return (
    <>
      <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
        Publish To Live
      </Typography>
      <Typography variant="body1">
        Your changes are ready to test. Log in to your web site and add
        ?EngagifyTest to the URL to test your changes.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 16 }}>
        <i>
          Your customers will not see these changes until you publish these
          changes to live.
        </i>
      </Typography>
      <div style={{ height: 400 }}>
        <TestTable
          rows={formatPublishedItems(props.items)}
          onChange={(e) => setSelectedItems(e)}
        />
      </div>
      <Button
        color="primary"
        variant="contained"
        style={{ width: 160, alignSelf: "end", marginTop: 16 }}
        onClick={handlePublish}
        disabled={selectedItems.length === 0}
      >
        Publish to Live
      </Button>
    </>
  );
};

export default PublishToLive;
