import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "../Components/Tables/Table";
import { IAccountProperty, IVisitorProperty } from "../queries/types";
import visitorCells from "../Components/Tables/Visitor/visitorCells";
import { drawerWidth } from "../config/Theme";
import { VisitorContext } from "../Context/VisitorContext";
import { useApi } from "../queries/util";
import {
  accountPropertiesRequest,
  visitorPropertiesRequest,
} from "../queries/settingsRequests";
import { IFormattedProps } from "../Components/Tables/ColumnEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
  })
);

const Visitors = () => {
  const {
    isLoading: isLoadingVisitorCustomProperties,
    data: visitorCustomProperties,
    error: visitorCustomPropertiesError,
  } = useApi<IVisitorProperty[]>(visitorPropertiesRequest);
  const {
    isLoading: isLoadingAccountCustomProperties,
    data: accountCustomProperties,
    error: accountCustomPropertiesError,
  } = useApi<IAccountProperty[]>(accountPropertiesRequest);
  const { columns } = React.useContext(VisitorContext);
  const [allColumns, setAllColumns] = React.useState<IFormattedProps>();
  const classes = useStyles();
  const theme = useTheme();

  /**
   * Format custom properties and add them to the list of all columns once they're done fetching
   */
  React.useEffect(() => {
    if (
      !isLoadingVisitorCustomProperties &&
      !isLoadingAccountCustomProperties
    ) {
      let newAllColumns = visitorCells;
      if (visitorCustomProperties) {
        let formattedVisitorProps: IFormattedProps = {};
        visitorCustomProperties.forEach(
          (property) =>
            (formattedVisitorProps[property.visitorPropertyKey] = {
              id: property.visitorPropertyKey,
              label: property.visitorPropertyDisplay,
              property_typeName: property.property_typeName,
              isCustomProperty: true,
              customPropertyType: "visitor",
            })
        );
        newAllColumns = { ...newAllColumns, ...formattedVisitorProps };
      }
      if (accountCustomProperties) {
        let formattedAccountProps: IFormattedProps = {};
        accountCustomProperties.forEach(
          (property) =>
            (formattedAccountProps[property.accountPropertyKey] = {
              id: property.accountPropertyKey,
              label: property.accountPropertyDisplay,
              property_typeName: property.property_typeName,
              isCustomProperty: true,
              customPropertyType: "account",
            })
        );
        newAllColumns = { ...newAllColumns, ...formattedAccountProps };
      }
      setAllColumns(newAllColumns);
    }
  }, [visitorCustomProperties, accountCustomProperties]);

  return (
    <div className={classes.root}>
      {allColumns && (
        <Table
          headCells={columns}
          allColumns={allColumns}
          title="Visitors"
          type="visitor"
          maxWidth={`calc(100vw - (${drawerWidth}px + ${theme.spacing(6)}px))`}
          context={VisitorContext}
        ></Table>
      )}
    </div>
  );
};

export default Visitors;
