import React from "react";
import moment, { Moment } from "moment-timezone";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import { useQueryClient } from "react-query";
import { defaultError, internalServerError } from "../../queries/errors";

const spaceBetween = 25;
const formWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    registrationErrorAlert: {
      minWidth: formWidth,
      justifyContent: "center",
      marginTop: spaceBetween,
    },
    registerForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    registerInput: {
      flex: 1,
      minWidth: formWidth,
      marginTop: spaceBetween,
    },
    registerButtonContainer: {
      marginTop: spaceBetween,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: formWidth,
    },
  })
);

const RegisterOrgForm = () => {
  const [today] = React.useState<Moment>(moment(new Date()));
  const timezoneGuess = moment.tz.guess();
  const [timezones] = React.useState<string[]>(() => {
    let names = [
      "America/Los_Angeles",
      "America/Denver",
      "America/Chicago",
      "America/New_York",
    ];
    if (!names.includes(timezoneGuess)) names = [timezoneGuess].concat(names);
    return names;
  });
  const [orgName, setOrgName] = React.useState<string>("");
  const [orgNameError, setOrgNameError] = React.useState<string>();
  const [selectedTimezone, setSelectedTimezone] = React.useState<string>(
    timezoneGuess
  );
  const [otherTimezone, setOtherTimezone] = React.useState<string>("");
  const [registrationError, setRegistrationError] = React.useState<string>();
  const classes = useStyles();
  const queryClient = useQueryClient();

  /**
   * Sends the inputted org data to the api server
   * @param e form submission event
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      organizationName: orgName,
      timeZoneName:
        selectedTimezone === "other" ? otherTimezone : selectedTimezone,
    };
    const response = await fetch("/api/v1/org/", {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let responseJSON;
    switch (response.status) {
      case 200:
        // removes the user data from the react-query cache, forcing it to fetch it again
        // QUESTION: Instead, should the create org endpoint return user data? This doesn't seem very restful,
        // but it would save the client a trip to the api server
        queryClient.invalidateQueries("user");
        break;
      case 422:
        // try and parse json from the response body
        try {
          responseJSON = await response.json();
          if (responseJSON.err) return setRegistrationError(responseJSON.err);
          // if no json can be parsed, the status code means that the user isn't logged in
        } catch (err) {
          return setRegistrationError("Please login first.");
        }
        break;
      case 500:
        return setRegistrationError(internalServerError);
      default:
        return setRegistrationError(defaultError);
    }
  };

  /**
   * Concatenates the timezone id and timezone name, i.e. America/Los_Angeles (PST)
   * @param {string} timezoneID The moment timezoneID (i.e. America/Los_Angeles)
   */
  const formatTimezone = (timezoneID: string) =>
    `${timezoneID} (${today.tz(timezoneID).format("z")})`;

  const handleOrgNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOrgName(e.target.value);
    setOrgNameError("");
  };

  const validateOrgName = () => {
    if (!orgName) setOrgNameError("Please enter a valid organization name.");
    else if (orgName.length > 254)
      setOrgNameError(
        "Please enter an organization name with less than 255 characters"
      );
  };

  /**
   * Checks whether or not the button should be disabled
   */
  const buttonDisabled = () => {
    if (orgNameError || !orgName) return true;
    if (!selectedTimezone) return true;
    else if (selectedTimezone === "other" && !otherTimezone) return true;
    return false;
  };

  /**
   * Validates the inputted data when the login button is clicked while it's disabled
   */
  const handleButtonContainerClick = () => {
    validateOrgName();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.registerForm}
      noValidate
      autoComplete="off"
    >
      {registrationError && (
        <Alert severity="error" className={classes.registrationErrorAlert}>
          {registrationError}
        </Alert>
      )}
      <TextField
        label="Organization Name"
        className={classes.registerInput}
        value={orgName}
        onBlur={(e) => validateOrgName()}
        error={orgNameError ? true : false}
        helperText={orgNameError}
        onChange={handleOrgNameChange}
      />
      <FormControl className={classes.registerInput}>
        <InputLabel htmlFor="timezone-select">Timezone</InputLabel>
        <Select
          native
          inputProps={{
            name: "timezone",
            id: "timezone-select",
          }}
          displayEmpty
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value as string)}
        >
          {timezones.map((timezone) => (
            <option key={`${timezone}_option`} value={timezone}>
              {formatTimezone(timezone)}
            </option>
          ))}
          <option value="other">Other</option>
        </Select>
      </FormControl>
      {selectedTimezone === "other" && (
        <Autocomplete
          id="other-timezones"
          options={moment.tz.names()}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Other Timezone"
              value={otherTimezone}
              onChange={(e) => setOtherTimezone(e.target.value)}
            />
          )}
          className={classes.registerInput}
        />
      )}
      <div className={classes.registerButtonContainer}>
        <div onClick={handleButtonContainerClick}>
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabled()}
            onClick={handleSubmit}
          >
            Register
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RegisterOrgForm;
