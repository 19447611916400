import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import DialogTitle from "../DialogTitle";
import CriteriaBuilder, { ICriteriaData } from "../Segments/CriteriaBuilder";
import { IVisitorContext } from "../../Context/VisitorContext";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { useApi } from "../../queries/util";
import { ISegmentMetadata } from "../../queries/types";
import { segmentsRequest } from "../../queries/npsRequests";
import { Skeleton } from "@material-ui/lab";
import { IAccountContext } from "../../Context/AccountContex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 600,
      maxWidth: 1200,
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    connector: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      borderLeft: `2px solid rgba(0,0,0,0.1)`,
    },
    segmentSelect: {
      marginBottom: theme.spacing(2),
      maxWidth: 200,
    },
    segmentSelectRoot: {
      minWidth: 100,
    },
    subItem: {
      marginLeft: theme.spacing(1.2),
      paddingLeft: theme.spacing(3),
      borderLeft: "2px solid rgba(0,0,0,0.1)",
    },
  })
);

interface IFilterModal {
  open: boolean;
  onClose: () => void;
  context: React.Context<IVisitorContext> | React.Context<IAccountContext>;
  type: "visitor" | "account" | "npsResponses";
}

// TODO: filter by segment
const FilterModal = (props: IFilterModal) => {
  const {
    isLoading,
    data: segments,
    error: segmentError,
  } = useApi<{
    segmentList: ISegmentMetadata[];
    visitorCount: { [key: string]: null };
  }>(segmentsRequest);
  const { filterCriteria, updateFilterCriteria } = React.useContext(
    props.context
  );
  const [criteria, setCriteria] = React.useState<ICriteriaData | undefined>(
    filterCriteria ? filterCriteria.criteria : undefined
  );
  const [criteriaType, setCriteriaType] = React.useState<string>(
    filterCriteria ? filterCriteria.criteriaType : "none"
  );
  const [segmentID, setSegmentID] = React.useState<string | undefined>(
    filterCriteria ? filterCriteria.segmentID?.toString() : undefined
  );
  const classes = useStyles();
  const location = useLocation();

  const typeText = props.type.charAt(0).toUpperCase() + props.type.slice(1);

  const accountMode = props.type === "account";

  let pageTitle: string;
  switch (location.pathname) {
    case "/accounts":
      pageTitle = "Accounts";
      break;
    case "/visitors":
      pageTitle = "Visitors";
      break;
    case "/nps-responses":
      pageTitle = "NPS Results";
      break;
    default:
      // TODO: redirect?
      pageTitle = "Error: Unknown page";
      break;
  }

  // Update criteria type if it ever gets out of sync with local storage
  React.useEffect(() => {
    if (filterCriteria && criteriaType !== filterCriteria.criteriaType) {
      setCriteriaType(filterCriteria.criteriaType);
    }
  }, [filterCriteria]);

  /**
   * Function for updating criteria data to be passed down to CriteriaBuilder component
   * @param {ICriteriaData} newCriteria The new criteria object
   */
  const onUpdateCriteria = (newCriteria: ICriteriaData) => {
    if (criteria !== newCriteria) setCriteria(newCriteria);
  };

  // saves the current filter data
  const applyFilter = () => {
    updateFilterCriteria({
      criteriaType: criteriaType as "segment" | "custom",
      segmentID: Number(segmentID),
      criteria,
    });
    props.onClose();
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCriteriaType((e.target as HTMLInputElement).value);

  // reverts filter modal state to match local storage
  const cancel = () => {
    setCriteria(filterCriteria ? filterCriteria.criteria : undefined);
    setCriteriaType(filterCriteria ? filterCriteria.criteriaType : "custom");
    setSegmentID(
      filterCriteria ? filterCriteria.segmentID?.toString() : undefined
    );
    props.onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle onClose={props.onClose}>Filter {pageTitle}</DialogTitle>
      <DialogContent className={classes.container}>
        <Typography color="textSecondary" variant="body2">
          Use the options below to specify which {typeText} to display.
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="criteria selection"
            name="criteria selection"
            value={criteriaType}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="none"
              control={<Radio />}
              label="No Filter"
            />
            {props.type !== "account" && (
              <>
                <FormControlLabel
                  value="segment"
                  control={<Radio />}
                  label="Filter By Segment"
                />
                <div className={classes.subItem}>
                  <FormControl className={classes.segmentSelect}>
                    <InputLabel id="segment-select-label">Segment</InputLabel>
                    {isLoading ? (
                      <Skeleton>
                        <Select></Select>
                      </Skeleton>
                    ) : (
                      <Select
                        labelId="segment-select-label"
                        id="segment-select"
                        value={segmentID}
                        onChange={(e) => setSegmentID(e.target.value as string)}
                        className={classes.segmentSelectRoot}
                      >
                        {segments &&
                          segments.segmentList.map((segment) => (
                            <MenuItem
                              value={segment.segmentID}
                              key={`segment_${segment.segmentID}`}
                            >
                              {segment.segmentName}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </>
            )}
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Filter By Custom Criteria"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.subItem}>
          <CriteriaBuilder
            criteria={criteria ? criteria : null}
            setLastSaved={() => null}
            showCard={false}
            onSave={onUpdateCriteria}
            disableAutoSave
            disableDefaultValue
            accountMode={accountMode}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={applyFilter}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
