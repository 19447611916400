import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    railContainer: {
      display: "flex",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      minWidth: 500,
    },
    railLabelContainer: {
      marginTop: -theme.spacing(2),
      display: "flex",
    },
    scoreLabel: {
      flex: 1,
      marginRight: 1,
      marginLeft: 1,
      textAlign: "center",
      paddingTop: theme.spacing(1),
    },
  })
);

interface IRatingCategorySlider {
  maxRating: number;
  minRating: number;
  detractorMax: number;
  promoterMin: number;
}

const RatingCategorySlider = (props: IRatingCategorySlider) => {
  const classes = useStyles();
  const theme = useTheme();
  const { maxRating, minRating, detractorMax, promoterMin } = props;

  /**
   *
   * @param start The range's minimum rating (i.e. smallest detractor score)
   * @param end The range's maximum rating (i.e. largest detractor score)
   * @returns The percent of the nps score preview that the given range occupies
   */
  const getRangeLabelSize = (start: number, end: number): string => {
    const percent = ((end - start) / (maxRating - minRating + 1)) * 100;
    return `${percent}%`;
  };

  return (
    <div>
      <div className={classes.railLabelContainer}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            width: getRangeLabelSize(minRating, detractorMax + 1),
            textAlign: "center",
          }}
        >
          Detractor
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            width: getRangeLabelSize(detractorMax + 1, promoterMin),
            textAlign: "center",
          }}
        >
          Passive
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            width: getRangeLabelSize(promoterMin, maxRating + 1),
            textAlign: "center",
          }}
        >
          Promoter
        </Typography>
      </div>
      <div className={classes.railContainer}>
        {[...Array(maxRating - minRating + 1)].map((e, i) => {
          const value = i + minRating;
          let color = theme.palette.warning.main;
          if (value <= detractorMax) color = theme.palette.error.main;
          else if (value >= promoterMin) color = theme.palette.secondary.main;
          return (
            <Typography
              className={classes.scoreLabel}
              style={{ borderTop: `3px solid ${color}99` }}
              key={`rail_label_${i}`}
            >
              {i + minRating}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default RatingCategorySlider;
