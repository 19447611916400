import React from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useLayout } from "../../config/Styles";
import { IOverviewData } from "../../queries/types";
import { ITheme } from "../../config/Theme";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../queries/util";
import { getOverviewRequest } from "../../queries/npsRequests";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    analyticsContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "baseline",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
      minWidth: 248,
    },
    cardTitleMargin: {
      marginBottom: theme.spacing(1),
    },
    dataText: {
      minWidth: theme.spacing(8),
    },
  })
);

interface IResponseOverview {
  timeframe: number;
}

const ResponseOverview = (props: IResponseOverview) => {
  const layout = useLayout();
  const theme = useTheme();
  const classes = useStyles();

  const [error, setError] = React.useState<string | null>(null);

  // api data
  const {
    isLoading: isLoadingOverview,
    data: overviewData,
    error: overviewError,
  } = useApi<IOverviewData>(getOverviewRequest(props.timeframe));

  React.useEffect(() => {
    if (overviewError) setError(overviewError.message);
  }, [overviewError]);

  return (
    <Card
      className={`${layout.flex1} ${layout.col}`}
      style={{ background: theme.palette.info.main, minWidth: 280 }}
    >
      <CardContent style={{ color: "#fff" }}>
        <Typography
          variant="subtitle2"
          color="inherit"
          className={classes.cardTitleMargin}
        >
          Overview
        </Typography>
        <div className={classes.analyticsContainer}>
          <Typography variant="h4">
            {isLoadingOverview ? (
              <Skeleton className={classes.dataText} />
            ) : (
              overviewData?.surveysShown
            )}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Surveys Shown
          </Typography>
        </div>
        <div className={classes.analyticsContainer}>
          <Typography variant="h4">
            {isLoadingOverview ? (
              <Skeleton className={classes.dataText} />
            ) : (
              overviewData?.numResponses
            )}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total Responses
          </Typography>
        </div>
        <div className={classes.analyticsContainer}>
          <Typography variant="h4">
            {isLoadingOverview ? (
              <Skeleton className={classes.dataText} />
            ) : (
              // calculate response rate
              overviewData &&
              `${
                Number(
                  (
                    overviewData.numResponses / overviewData.surveysShown
                  ).toFixed(2)
                ) * 100 || 0
              }%`
            )}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Response Rate
          </Typography>
        </div>
        <div className={classes.analyticsContainer}>
          <Typography variant="h4">
            {isLoadingOverview ? (
              <Skeleton className={classes.dataText} />
            ) : (
              overviewData?.numComments || 0
            )}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Comments
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ResponseOverview;
