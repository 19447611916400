import rules, { IRule } from "./rules";

export type TypeName = "String" | "Date" | "Flag" | "Number";

export interface IProperty {
  label: string;
  type: TypeName;
  rules: IRule[];
  group?: string;
  isCustomProperty?: boolean;
  customPropertyType?: "account" | "visitor";
  systemName?: string;
}

// hardcoded property list
const visitorProperties: IProperty[] = [
  {
    label: "Full Name",
    type: "String",
    rules: rules.strings,
    group: "System Properties",
  },
  {
    label: "First Name",
    type: "String",
    rules: rules.strings,
    group: "System Properties",
  },
  {
    label: "Last Name",
    type: "String",
    rules: rules.strings,
    group: "System Properties",
  },
  {
    label: "Email",
    type: "String",
    rules: rules.strings,
    group: "System Properties",
  },
  {
    label: "Created",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
  {
    label: "Last Seen",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
  {
    label: "First Seen",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
];

const accountProperties: IProperty[] = [
  {
    label: "Account Name",
    type: "String",
    rules: rules.strings,
    group: "System Properties",
  },
  {
    label: "# Visitors",
    type: "Number",
    rules: rules.numbers,
    group: "System Properties",
  },
  {
    label: "Last Seen",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
  {
    label: "Created",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
  {
    label: "First Seen",
    type: "Date",
    rules: rules.dates,
    group: "System Properties",
  },
  {
    label: "NPS Score",
    type: "Number",
    rules: rules.numbers,
    group: "NPS Data",
  },
  {
    label: "Average NPS Rating",
    type: "Number",
    rules: rules.numbers,
    group: "NPS Data",
  },
  {
    label: "Change (+/-)",
    type: "Number",
    rules: rules.numbers,
    group: "NPS Data",
  },
];

const browserProperties: IProperty[] = [
  {
    label: "Browser",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Browser Version",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Device Type",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Operating System",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Operating System Version",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Language",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "Country",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "User Agent",
    type: "String",
    rules: rules.strings,
  },
  // {
  //   label: "Page",
  //   type: "String",
  //   rules: rules.strings,
  // },
  // {
  //   label: "Domain",
  //   type: "String",
  //   rules: rules.strings,
  // },
  {
    label: "Do Not Track",
    type: "Flag",
    rules: rules.booleans,
  },
];

const npsResults: IProperty[] = [
  {
    label: "NPS Rating",
    type: "Number",
    rules: rules.numbers,
  },
  {
    label: "NPS Comment",
    type: "String",
    rules: rules.strings,
  },
  {
    label: "NPS Score",
    type: "Number",
    rules: rules.numbers,
  },
  {
    label: "Change (+/-)",
    type: "Number",
    rules: rules.numbers,
  },
  {
    label: "Most Recent NPS Survey Shown",
    type: "Date",
    rules: rules.dates,
  },
  {
    label: "Most Recent NPS Response",
    type: "Date",
    rules: rules.dates,
  },
  {
    label: "NPS Ask Me Later",
    type: "Date",
    rules: rules.dates,
  },
];

export interface IPropertyGroup {
  label: string;
  propertyGroup: IProperty[];
}

const properties: IPropertyGroup[] = [
  {
    label: "Visitor Properties",
    propertyGroup: visitorProperties,
  },
  {
    label: "Account Properties",
    propertyGroup: accountProperties,
  },
  {
    label: "Browser Properties",
    propertyGroup: browserProperties,
  },
  {
    label: "NPS Results",
    propertyGroup: npsResults,
  },
];

export default properties;
