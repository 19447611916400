import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DialogTitle from "../DialogTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      width: "60vw",
      minWidth: 400,
      maxWidth: 1000,
    },
  })
);

interface IDemoModal {
  open: boolean;
  onClose: () => void;
}

const DemoModal = (props: IDemoModal) => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="demo-modal-title"
      classes={{ paper: classes.modal }}
    >
      <DialogTitle id="demo-modal-title" onClose={props.onClose}>
        Schedule a Demo
      </DialogTitle>
      <DialogContent>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <div style={{ minWidth: 320, height: 580 }} data-auto-load="false">
          <iframe
            src="https://calendly.com/zengage-demo/demo"
            width="100%"
            height="100%"
            title="Caldendly Scheduler"
            className="calendly-inline-widget"
            style={{ border: 0 }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DemoModal;
