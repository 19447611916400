import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import RatingAvatar from "./RatingAvatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ITheme } from "../../config/Theme";
import { useLayout, useLinks } from "../../config/Styles";
import { Link } from "react-router-dom";
import { IComment } from "../../queries/types";
import Skeleton from "@material-ui/lab/Skeleton";
import { recentCommentsRequest } from "../../queries/npsRequests";
import ErrorSnackbar from "../ErrorSnackbar";
import { useApi } from "../../queries/util";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    list: {
      flex: 1,
      background: theme.palette.background.paper,
      [theme.breakpoints.up("sm")]: {
        maxWidth: `calc(100vw - ${theme.drawerWidth}px - 48px)`,
        width: "100%",
      },
      maxWidth: `calc(100vw - ${theme.spacing(6)}px)`,
    },
    listItem: {
      // set each list item to fill up the list's maxWidth
      width: Math.min(
        1200 - 206,
        document.body.clientWidth - theme.drawerWidth - 206
      ),
    },
    title: {
      color: theme.palette.contrast.main,
    },
  })
);

interface IResponse {
  rating: number;
  primary: string;
  secondary: string;
  classes: any;
  responseDate: string;
}
/**
 * Component that renders a single NPS response (rating, comment, and visitor fullname and account fullname)
 * @param {IResponse} props Response data and css class object
 */
const Response = (props: IResponse) => {
  let dateString = "";
  try {
    const responseDate = new Date(props.responseDate);
    const difference = new Date().getTime() - responseDate.getTime();
    const minutes = difference / 1000 / 60;
    const days = minutes / 60 / 24;
    // less than a minute ago
    if (minutes < 1) dateString = "just a moment ago";
    else if (minutes < 60) dateString = `${minutes.toFixed(0)} minutes ago`;
    else if (minutes / 60 < 24)
      dateString = `${(minutes / 60).toFixed(0)} hours ago`;
    else if (days < 7) dateString = `${days.toFixed(0)} days ago`;
    else if (days < 28) dateString = `${(days / 7).toFixed(0)} weeks ago`;
    else if (days <= 180) dateString = `${(days / 30).toFixed(0)} months ago`;
    else dateString = responseDate.toISOString().split("T")[0];
  } catch (err) {
    console.error(err);
  }

  return (
    <ListItem className={props.classes.listItem}>
      <ListItemAvatar>
        <RatingAvatar rating={props.rating} />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography>{props.primary}</Typography>}
        secondary={
          <Typography variant="body2" color="textSecondary">
            {props.secondary}
            <i style={{ marginLeft: 16 }}>{dateString}</i>
          </Typography>
        }
      />
    </ListItem>
  );
};

/**
 * Component that renders a single NPS response loading skeleton
 * @param props Css classes object
 */
const ResponseLoading = (props: { classes: any }) => (
  <ListItem className={props.classes.listItem}>
    <ListItemAvatar>
      <Skeleton>
        <RatingAvatar rating={1} />
      </Skeleton>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography noWrap>
          <Skeleton />
        </Typography>
      }
      secondary={<Skeleton />}
    />
  </ListItem>
);

/**
 * Component housing all responses on the dashboard page
 */
const DashboardResponses = () => {
  const classes = useStyles();
  const layout = useLayout();
  const links = useLinks();
  const {
    isLoading,
    data: responses,
    error: responseError,
  } = useApi<IComment[]>(recentCommentsRequest);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (responseError) setError(responseError.message);
  }, [responseError]);

  return (
    <Card className={classes.list}>
      <CardContent>
        <div className={layout.betweenRow}>
          <Typography variant="subtitle2" className={classes.title}>
            Recent NPS Comments:{" "}
          </Typography>
          <Link to="/nps-responses" className={links.root}>
            <Typography variant="subtitle2" color="primary">
              View All NPS Responses
            </Typography>
          </Link>
        </div>

        <List>
          {isLoading
            ? [...new Array(5)].map((a: any) => (
                <ResponseLoading classes={classes} />
              ))
            : responses?.map((response, i) => (
                <div key={`response_${i}`}>
                  <Response
                    rating={response.npsRating}
                    primary={response.npsComment}
                    secondary={`${response.visitorFullName}, ${response.accountName}`}
                    classes={classes}
                    responseDate={response.nps_responseDate}
                  />
                </div>
              ))}
          {!isLoading && (!responses || responses.length === 0) && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.listItem}
            >
              <i>No comments have been made recently</i>
            </Typography>
          )}
        </List>
      </CardContent>
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </Card>
  );
};

export default DashboardResponses;
