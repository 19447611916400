import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

/**
 * Error alert component
 */
const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

interface IErrorSnackbar {
  snackbarOpen: boolean;
  handleSnackbarClose: () => void;
  error: string | null;
}

const ErrorSnackbar = ({
  snackbarOpen,
  handleSnackbarClose,
  error,
}: IErrorSnackbar) => {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={30000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity="error" onClose={handleSnackbarClose}>
        {error}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
