interface IComment {
  [key: string]: string;
}
// dictionary for selector comments
const comments: IComment = {
  ".zengage-container": "Styles for the NPS container",
  "#zengage-backdrop": "Backdrop styles",
  ".zengage-text-header": "Text styles",
  ".zengage-button-primary":
    "Styles for the score buttons and the submit button " +
    "\n Paste the following in the .button-primary styles to" +
    "\n turn the buttons into one connected row:" +
    "\n margin: 0px;" +
    "\n border-radius: 0px;" +
    "\n padding: 4px 12px 4px 12px; \n",
  ".zengage-button-secondary":
    'Styles for the "ask me later" and "back" buttons',
};

export interface ICssObject {
  [key: string]: { [key: string]: any };
}

const jsonToCss = (json: ICssObject) => {
  let css = "";
  /*
  Steps:
    Loop over each selector:
      check if the current selector has a comment
        append the comment to the css string
      append the selector and an open block to the css string
      loop over each property
        use regex to change camel case to hyphen case (property name)
        append property name and property value 
  */
  for(const selector in json) {
    // check if the current selector has a comment and
    // append that comment to the css variable
    if (comments[selector]) css += ` /* ${comments[selector]} */`;
    if (selector === "@import") { 
      for(const font in json[selector]) {
        css = `@import ${json[selector][font]};\n` + css;
      }
      continue;
    }
    // open the block
    css += `\n${selector} {`;
    // check if the selector is a font import
    // loop over each of the selector's style properties
    Object.keys(json[selector]).forEach((property) => {
      // use regex to convert camelCase to kebab-case
      const escapedProperty = property.replace(/([a-z])([A-Z])/, "$1-$2");
      // add the kebab-case property (in lower case) and its corresponding
      // value to the css variable
      css += `\n  ${escapedProperty.toLowerCase()}: ${
        json[selector][property]
      };`;
    });
    // close the block
    css += `\n}\n`;
  }
  return css;
};

export default jsonToCss;
