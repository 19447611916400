import React from "react";
import validator from "email-validator";
// material-ui imports
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useMutation, useQueryClient } from "react-query";
import { login } from "../../queries/user";
import { ILoginData, IUser } from "../../queries/types";
import { Link } from "react-router-dom";

const spaceBetween = 25;
const formWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      flexDirection: "row",
      transition: "all 1s",
    },
    loginForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    loginInput: {
      marginTop: spaceBetween,
      minWidth: formWidth,
    },
    buttonContainer: {
      marginTop: spaceBetween,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: formWidth,
    },
    loginErrorAlert: {
      minWidth: formWidth,
      justifyContent: "center",
      marginTop: spaceBetween,
    },
  })
);

/**
 * Login component
 */
const LoginForm = () => {
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const classes = useStyles();

  const queryClient = useQueryClient();
  const loginMutation = useMutation<IUser, Error, ILoginData>(login, {
    onSuccess: (data: IUser) => {
      queryClient.setQueryData("user", data);
    },
  });

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  /**
   * Validates the inputted email
   * @returns {boolean} Whether or not the email is valid
   */
  const validateEmail = (): boolean => {
    if (!validator.validate(email)) {
      setEmailError("Please enter a valid email.");
      return false;
    }
    return true;
  };

  /**
   * Checks if the login button should be disabled or not
   */
  const loginDisabled = (): boolean => {
    if (!validateEmail()) return true;
    return false;
  };

  /**
   * Attempts to log the user in only if the inputted values are valid
   */
  const handleLoginSubmit = async (e: any) => {
    if (e) e.preventDefault();
    // if the form is correctly filled out
    if (!loginDisabled()) {
      // send the entered email / password to the api server
      await loginMutation.mutate({ email, password });
    }
  };

  /**
   * Submits the form if the user presses enter
   * @param e The keydown event
   */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (!loginDisabled()) handleLoginSubmit(null);
    }
  };

  /**
   * Validates all input fields in the case that the login button is disabled and the user clicks it anyway
   */
  const handleButtonContainerClick = () => {
    validateEmail();
  };

  return (
    <form
      onSubmit={handleLoginSubmit}
      className={classes.loginForm}
      noValidate
      autoComplete="off"
    >
      {loginMutation.error && (
        <Alert severity="error" className={classes.loginErrorAlert}>
          {loginMutation.error.message}
        </Alert>
      )}
      <TextField
        label="Email"
        className={classes.loginInput}
        value={email}
        onChange={handleEmailChange}
        onBlur={validateEmail}
        error={emailError ? true : false}
        helperText={emailError}
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Password"
        type={showPassword ? "text" : "password"}
        className={classes.loginInput}
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
        error={passwordError ? true : false}
        helperText={passwordError}
        name="password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.buttonContainer}>
        <div style={{ cursor: "pointer" }} onClick={handleButtonContainerClick}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginSubmit}
          >
            Login
          </Button>
        </div>
        <Link to="/forgotPassword">
          <Button color="primary">Forgot Password</Button>
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
