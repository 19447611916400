import { IApi } from "./types";

export const visitorPropertiesRequest: IApi = {
  url: "/api/v1/settings/visitorProperties/",
  defaultError:
    "Something went wrong while trying to fetch visitor custom properties. Please try again later.",
  tag: "visitorProperties",
  responseProp: "visitorProperties",
  useQueryParams: {
    refetchOnMount: true,
    // staleTime:
  },
};

export const accountPropertiesRequest: IApi = {
  url: "/api/v1/settings/accountProperties/",
  defaultError:
    "Something went wrong while trying to fetch account custom properties. Please try again later.",
  tag: "accountProperties",
  responseProp: "accountProperties",
  useQueryParams: {
    refetchOnMount: true,
  },
};

export const tagSettingsRequest: IApi = {
  url: "/api/v1/settings/tags",
  defaultError:
    "Something went wrong while trying to fetch tag data. Please try again later.",
  tag: "tagSettings",
  responseProp: "tags",
  useQueryParams: {
    refetchOnMount: true,
  },
}

export const getVisitorRequest = (id: number, limit: number): IApi => ({
  url: `/api/v1/settings/visitorProperties/${id}?limit=${limit}`,
  defaultError:
    "Something went wrong while trying to fetch a custom visitor property. Please try again later.",
  tag: ["visitorProperty", id],
  responseProp: "propertyData",
  useQueryParams: {
    enabled: false,
  },
});

export const getAccountRequest = (id: number, limit: number): IApi => ({
  url: `/api/v1/settings/accountProperties/${id}?limit=${limit}`,
  defaultError:
    "Something went wrong while trying to fetch a custom accountr property. Please try again later.",
  tag: ["accountProperty", id],
  responseProp: "propertyData",
  useQueryParams: {
    enabled: false,
  },
});
