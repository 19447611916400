import React from "react";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useLinks } from "../../config/Styles";
import { useUser } from "../../queries/user";
import DialogTitle from "../DialogTitle";
import { useQueryClient } from "react-query";
import Loading from "../Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100vw",
      minWidth: 400,
    },
    dataRow: {
      display: "flex",
      padding: `${theme.spacing(0.5)}px 0px`,
      alignItems: "baseline",
      "& > *": {
        flex: 2,
      },
    },
    editIcon: {
      marginLeft: theme.spacing(1),
    },
    block: {
      marginBottom: theme.spacing(1),
    },
    input: {
      maxWidth: 1000,
    },
    inputRoot: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineSpacing: "0.01071em",
    },
    inputContainer: {
      display: "flex",
    },
    doneButtonContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const useEditButton = (
  tooltipTitle: string,
  classes: any
): [boolean, JSX.Element] => {
  const [editing, setEditing] = React.useState<boolean>(false);
  const element = (
    <Tooltip
      title={editing ? "Save changes" : tooltipTitle}
      className={classes.editIcon}
    >
      <IconButton
        color="secondary"
        size="small"
        onClick={() => setEditing(!editing)}
      >
        {editing ? (
          <DoneIcon fontSize="small" />
        ) : (
          <EditIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
  return [editing, element];
};

interface IAccountModal {
  onClose: () => void;
  open: boolean;
}

const AccountModal = (props: IAccountModal) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const links = useLinks();
  const { isLoading, data: user, error: fetchUserError } = useUser();

  const [editingName, editNameButton] = useEditButton(
    "Edit full name",
    classes
  );
  const [editingEmail, editEmailButton] = useEditButton(
    "Edit emai address",
    classes
  );

  const [fullName, setFullName] = React.useState<string[]>([
    user?.firstName || "",
    user?.lastName || "",
  ]);

  const [email, setEmail] = React.useState<string>(user?.email || "");

  if (isLoading) return <Loading />;
  else if (!isLoading && !user) queryClient.invalidateQueries("user");

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle onClose={props.onClose}>Account Settings</DialogTitle>
      <DialogContent>
        <div className={classes.block}>
          <Typography variant="subtitle2">Personal Data</Typography>
          <div className={classes.dataRow}>
            <Typography variant="body2" style={{ flex: 1 }}>
              Full Name:{" "}
            </Typography>
            {editingName ? (
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  inputProps={{ className: classes.inputRoot }}
                  label="First name"
                  value={fullName[0]}
                  onChange={(e) => setFullName([e.target.value, fullName[1]])}
                />
                <TextField
                  className={classes.input}
                  inputProps={{ className: classes.inputRoot }}
                  label="Last name"
                  value={fullName[1]}
                  onChange={(e) => setFullName([fullName[0], e.target.value])}
                />
                <div className={classes.doneButtonContainer}>
                  {editNameButton}
                </div>
              </div>
            ) : (
              <Typography variant="body2">
                {`${fullName[0]} ${fullName[1]}`}
                {editNameButton}
              </Typography>
            )}
          </div>
          <div className={classes.dataRow}>
            <Typography variant="body2" style={{ flex: 1 }}>
              Email Address:{" "}
            </Typography>
            {editingEmail ? (
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  inputProps={{ className: classes.inputRoot }}
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className={classes.doneButtonContainer}>
                  {editEmailButton}
                </div>
              </div>
            ) : (
              <Typography variant="body2">
                {email} {editEmailButton}
              </Typography>
            )}
          </div>
          <Link to="/forgot-password" className={links.primary}>
            Change Password
          </Link>
        </div>
        <Typography variant="subtitle2">Organization Data</Typography>
        <div className={classes.dataRow}>
          <Typography variant="body2" style={{ flex: 1 }}>
            Restrictions:
          </Typography>
          <Typography variant="body2">{user?.restrictions}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountModal;
