import { IFollowUpQuestion } from "../../../queries/types";
import { IRangedQuestion } from "./SurveyAdvancedOptionsEditor";

/**
 * Formats the provided follow up questions to make them safe to save
 * @param {IRangedQuestion[]} followUpQuestions The follow up questions to format
 * @returns {IFollowUpQuestion[]} The provided follow up question array correctly formatted for saving
 */
export const formatFollowUpQuestions = (
  followUpQuestions: IRangedQuestion[]
): IFollowUpQuestion[] => {
  if (followUpQuestions.length === 1) {
    return [
      {
        minRating: followUpQuestions[0].scores[0],
        maxRating: followUpQuestions[0].scores[1],
        followUpQuestion: followUpQuestions[0].question,
      },
    ];
  }
  return followUpQuestions.map((question) => ({
    minRating: question.scores[0],
    maxRating: question.scores[1],
    followUpQuestion: question.question,
  }));
};

/**
 * Function that checks if any follow up questions hve rating ranges that overlap
 * @param {IRangedQuestion[]} followUpQuestions The list of follow up questions to check
 * @returns {IRangedQuestion[]} The provided follow up question list updated with any overlap errors encountered
 */
export const checkForOverlap = (
  followUpQuestions: IRangedQuestion[]
): IRangedQuestion[] => {
  // reset errors
  for (let i = 0; i < followUpQuestions.length; i++) {
    followUpQuestions[i].error = null;
  }
  // check for overlap
  for (let i = 0; i < followUpQuestions.length; i++) {
    for (let j = 0; j < followUpQuestions.length; j++) {
      if (j === i) continue;
      if (
        followUpQuestions[i].scores[0] <= followUpQuestions[j].scores[1] &&
        followUpQuestions[i].scores[1] >= followUpQuestions[j].scores[1]
      ) {
        followUpQuestions[i].error = "Score overlap";
        followUpQuestions[j].error = "Score overlap";
      }
    }
  }
  return followUpQuestions;
};

/**
 *
 * @param minRating The minimum nps rating
 * @param maxRating The maximum nps rating
 * @param usedRatings A list of which ratings are currently mapped to follow up questions
 * @returns
 */
export const checkForUnusedRatings = (
  minRating: number,
  maxRating: number,
  questions: IRangedQuestion[]
): string | null => {
  const usedScoreList: number[] = [];

  for (let question of questions) {
    for (let i = question.scores[0]; i <= question.scores[1]; i++) {
      usedScoreList.push(i);
    }
  }

  const unusedScoreList: number[] = [];
  for (let i = minRating; i <= maxRating; i++) {
    if (!usedScoreList.includes(i)) {
      unusedScoreList.push(i);
    }
  }
  if (unusedScoreList.length === 1) {
    return `The score of ${unusedScoreList[0]} is not mapped to a follow-up question.`;
  } else if (unusedScoreList.length > 1) {
    return "Multiple scores are not mapped to follow-up questions.";
  }
  return null;
};
