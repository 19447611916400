import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

export type Order = "asc" | "desc";

export type HeadCell = {
  id: string;
  label: string;
  property_typeName: "String" | "Flag" | "Number" | "Date";
  isCustomProperty?: boolean;
  customPropertyType?: "visitor" | "account";
  alignment?: "right" | "left";
  helpText?: string;
  placeholder?: any;
  sortBy?: Order;
};

interface IEnhancedTableHead {
  classes: any;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  onRequestSort: (e: any, property: string, propertyId: string) => void;
}

/**
 * Component for the header column of a table
 * @param {MuiStyles} classes styles passed from the table component
 * @param {Order} order The direction which the table is being sorted
 * @param {string} orderBy Which column the table is being ordered by
 * @param {HeadCell[]} headCells The table's columns
 * @param {(e: any, property: string) => void} onRequestSort Method called when the sorting property or order is changed
 */
const EnhancedTableHead = (props: IEnhancedTableHead) => {
  const createSortHandler = (propertyLabel: string, propertyId: string) => {
    return (event: React.MouseEvent<unknown>) => {
      props.onRequestSort(event, propertyLabel, propertyId);
    };
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" aria-label="View column"></TableCell>
        {props.headCells.map((headCell) => {
          // determine whether or not the current field is being used to sort the data
          const isCurrentlySorted = props.orderBy === headCell.label;
          return (
            <TableCell
              align="left"
              sortDirection={isCurrentlySorted ? props.order : false}
              key={headCell.id}
            >
              <TableSortLabel
                active={isCurrentlySorted}
                direction={isCurrentlySorted ? props.order : "asc"}
                onClick={createSortHandler(headCell.label, headCell.id)}
                className={props.classes.headCell}
              >
                {headCell.label}
                {headCell.helpText && (
                  <Tooltip title={headCell.helpText}>
                    <InfoIcon color="disabled" style={{ margin: "0px 5px" }} />
                  </Tooltip>
                )}
                {isCurrentlySorted ? (
                  <span className={props.classes.visuallyHidden}>
                    {props.order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
