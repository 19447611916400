import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";

/**
 * Text input hook for component reuse and simplification
 * @param {string} label The input's label
 * @param {string} defaultValue The input's default value (defaults to an empty string)
 * @param {material-ui useStyles} classes Class styles (NOTE: the textInput class must be defined)
 * @returns {[string, TextField]} Returns the input's current value and the input JSX element
 */
export const useTextInput = (
  classes: any,
  label: string,
  defaultValue?: string
): [string, any] => {
  const [value, setValue] = React.useState<string>(defaultValue || "");
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (value.length === 0)
      setError(`Please enter a valid ${label.toLowerCase()}`);
    else {
      if (error) setError(null);
    }
  }, [value]);

  const input = (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type="text"
      label={label}
      error={Boolean(error)}
      helperText={error}
      className={classes.textInput}
      fullWidth
      multiline
    />
  );

  return [value, input];
};

/**
 * Factory for generating text inputs with common styles
 * @param {material-ui useStyles} classes useStyles Class styles (NOTE: the textInput class must be defined)
 * @returns {(label: string, defaultValue?: string) => [string, TextField]} A textInput hook
 */
export const textInputFactory = (classes: any) => (
  label: string,
  defaultValue?: string
) => useTextInput(classes, label, defaultValue);

/**
 * Number input hook for component reuse
 * @param {material-ui useStyles} classes Class styles (
 * THE FOLLOWING MUST BE DEFINED: classes.numberInputContainer, classes.numberInputLabel, classes.numberInput
 * )
 * @param {string} label The input's label
 * @param {string} unit The unit to be displayed at the end of the input
 * @param {number | undefined} defaultValue? The input's default value (if any)
 */
export const useNumberInput = (
  classes: any,
  label: string,
  unit: string,
  defaultValue?: number
): [number, any] => {
  // set the input's value to the default value if one was provided
  const [value, setValue] = React.useState<number | null>(
    defaultValue === undefined ? null : defaultValue
  );
  const uniqueId = `number-input-label-${Math.random()}`;
  const input = (
    <FormControl className={classes.numberInputContainer}>
      <Typography className={classes.numberInputLabel} id={uniqueId}>
        {label}
      </Typography>
      <Input
        id={`number-input-${Math.random()}`}
        aria-describedby={uniqueId}
        value={value}
        type="number"
        onChange={(e) => setValue(Number(e.target.value))}
        endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
        className={classes.numberInput}
      />
    </FormControl>
  );
  return [Number(value), input];
};
