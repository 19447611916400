import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import { useApi } from "../../queries/util";
import { getTagsRequest } from "../../queries/npsRequests";
import { ITagTableData } from "../../queries/types";
import ErrorSnackbar from "../ErrorSnackbar";
import Loading from "../Loading";
import { Typography } from "@material-ui/core";

interface ITags {
  tag: string;
  number: number;
  percent: string;
}

// dummy tag data
export const tags: ITags[] = [
  {
    tag: "Need more reports",
    number: 152,
    percent: "23%",
  },
  {
    tag: "Great customer svc",
    number: 109,
    percent: "17%",
  },
  {
    tag: "Fast Response",
    number: 90,
    percent: "14%",
  },
  {
    tag: "Too many bugs",
    number: 54,
    percent: "8%",
  },
  {
    tag: "Too expensive",
    number: 40,
    percent: "6%",
  },
  {
    tag: "Too slow",
    number: 9,
    percent: "1%",
  },
  {
    tag: "Test Tag",
    number: 9,
    percent: "1%",
  },
  {
    tag: "Test Tag 2",
    number: 9,
    percent: "1%",
  },
];

interface ITagTable {
  classes: any;
  days: number;
}

/**
 * Component for displaying a table containing tag data
 * @param {ITagTable} props css classes object
 */
const TagTable = (props: ITagTable) => {
  const {
    isLoading,
    data: tagData,
    error: tagsError,
  } = useApi<ITagTableData[]>(getTagsRequest(props.days));
  const [error, setError] = React.useState<string | null>(null);

  const columnNames = ["npsTagName", "numTagAssignments", "percent"];
  const segments = Object.keys(
    tagData && tagData.length > 0 ? tagData[0] : {}
  ).filter((columnnName) => !columnNames.includes(columnnName));

  /**
   * Display any api call errors
   */
  React.useEffect(() => {
    if (tagsError) setError(tagsError.message);
  }, [tagsError]);

  return isLoading ? (
    <Loading />
  ) : (
    <Table aria-label="tags-table">
      {/* display the first 6 tags */}
      {tagData && tagData.length > 0 ? (
        <>
          <TableHead>
            <TableRow>
              <TableCell component="th">Tag</TableCell>
              <TableCell component="th">#</TableCell>
              <TableCell component="th">%</TableCell>
              {segments.map((segment) => (
                <TableCell component="th">{segment}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tagData?.slice(0, 6).map((tag, i) => (
              <TableRow
                className={props.classes.alternatingTable}
                key={`${tag.npsTagName}_${i}`}
              >
                <TableCell>{tag.npsTagName}</TableCell>
                <TableCell>{tag.numTagAssignments}</TableCell>
                <TableCell>{tag.percent}</TableCell>
                {segments.map((segment) => (
                  <TableCell>{tag[segment]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ alignSelf: "center" }}
        >
          <i>No tags were assigned in the past {props.days} days</i>
        </Typography>
      )}
      <ErrorSnackbar
        snackbarOpen={Boolean(error)}
        handleSnackbarClose={() => setError(null)}
        error={error}
      />
    </Table>
  );
};

export default TagTable;
