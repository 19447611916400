import React from "react";
import {
  TableCell,
  TableRow,
  TextField,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { IFont } from "../../../queries/types";

interface FontListProps {
  fonts: IFont[];
  editingFont: number | null;
  setEditingFont: (index: number | null) => void;
  handleChangeFontName: (newName: string, index: number) => void;
  handleChangeEmbedCode: (newCode: string, index: number) => void;
  updateFont: (index: number) => Promise<void>;
  deleteFont: (index: number) => Promise<void>;
  classes: any;
}

const FontList: React.FC<FontListProps> = ({
  fonts,
  editingFont,
  setEditingFont,
  handleChangeFontName,
  handleChangeEmbedCode,
  updateFont,
  deleteFont,
  classes,
}) => {
  return (
    <>
      {fonts.map((font, i) => (
        <TableRow key={font.fontID} className={classes.tableRow}>
          <TableCell className={classes.tableCell} style={{ minWidth: 198 }}>
            {editingFont === i ? (
              <TextField
                value={font.fontName}
                onChange={(e) => handleChangeFontName(e.target.value, i)}
                aria-label="edit font name"
                fullWidth
              />
            ) : (
              font.fontName
            )}
          </TableCell>
          <TableCell
            className={classes.tableCell}
            classes={{ root: classes.tableCell }}
            style={{ minWidth: 198 }}
          >
            {editingFont === i ? (
              <TextField
                value={font.embedCode}
                onChange={(e) => handleChangeEmbedCode(e.target.value, i)}
                aria-label="edit font embed link"
                multiline
                fullWidth
              />
            ) : (
              font.embedCode
            )}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <div className={classes.iconButtons}>
              {editingFont === i ? (
                <Button
                  color="secondary"
                  onClick={() => updateFont(i)}
                  style={{ margin: "6px 0px" }}
                >
                  Save Font
                </Button>
              ) : (
                <>
                  <IconButton
                    color="secondary"
                    onClick={() => setEditingFont(i)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => deleteFont(i)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default FontList;
