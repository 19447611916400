import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { npsChipColors } from "../../../config/Theme";
import { HeadCell } from "../TableHead";
import { Account } from "../../../queries/types";
import AccountModal from "./AccountModal";
import { Skeleton } from "@material-ui/lab";
import { AccountContext, IFilter } from "../../../Context/AccountContex";

interface IAccountTableBody {
  cols: HeadCell[];
  page: number;
  rowsPerPage: number;
  classes: any;
  setLoading: (loading: boolean) => void;
  setNextPageDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountTableBody = (props: IAccountTableBody) => {
  const { currentFilter, lastFilter, rowsPerPage, filterCriteria, columns } =
    React.useContext(AccountContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [accounts, setAccounts] = React.useState<Account[]>();
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => props.setLoading(isLoading), [isLoading]);

  /**
   * Refetch account list whenever settings are changed
   */
  React.useEffect(() => {
    (async () => {
      try {
        // parse sorting data
        const sortBody: { sortBy: null | IFilter; lastSortBy: null | IFilter } =
          {
            sortBy: null,
            lastSortBy: null,
          };
        if (currentFilter) {
          const currentProp = props.cols.find(
            (col) => col.label === currentFilter.propertyID
          );
          if (currentProp) {
            sortBody.sortBy = {
              propertyID: currentProp.id,
              order: currentFilter.order,
              property_typeName: currentProp.property_typeName,
            };
          }
        }
        if (lastFilter) {
          const lastProp = props.cols.find(
            (col) => col.label === lastFilter.propertyID
          );
          if (lastProp) {
            sortBody.lastSortBy = {
              propertyID: lastProp.id,
              order: lastFilter.order,
              property_typeName: lastProp.property_typeName,
            };
          }
        }
        setIsLoading(true);
        const response = await fetch("/api/v1/org/accounts", {
          method: "post",
          body: JSON.stringify({
            rows: props.rowsPerPage,
            page: props.page,
            properties: props.cols.map((col) => ({
              accountPropertyKey: col.id,
              isCustomProperty: col.isCustomProperty || false,
              property_typeName: col.property_typeName,
              customPropertyType: col.customPropertyType,
            })),
            ...sortBody,
            filterCriteria: {
              ...filterCriteria,
              criteria: JSON.stringify(filterCriteria.criteria),
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        let json;
        switch (response.status) {
          case 422:
            json = await response.json();
            setError(json.error);
            break;
          case 200:
            json = await response.json();
            if (json.accounts) {
              console.log(json.accounts);
              setAccounts(json.accounts);
            }
            break;
          default:
            setError(
              "An unexpected error occured while trying to fetch visitor data. Please try again later."
            );
        }
      } catch (err: any) {
        console.error(err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    columns,
    lastFilter,
    currentFilter,
    rowsPerPage,
    filterCriteria,
    props.page,
  ]);

  /**
   * Disable the next page button if no extra account was received
   */
  React.useEffect(() => {
    if (accounts && accounts.length < rowsPerPage + 1)
      props.setNextPageDisabled(true);
    else props.setNextPageDisabled(false);
  }, [accounts]);

  const handleViewModal = (id: number) => {
    setSelected(id);
    setModalOpen(true);
  };
  const handleModalClose = () => setModalOpen(false);

  // display skeleton if still loading
  if (isLoading || !accounts) {
    return (
      <>
        {[...new Array(props.rowsPerPage)].map((e: any, i) => (
          <TableRow key={`row_${i}`} hover className={props.classes.row}>
            {props.cols.map((col, j) => (
              <TableCell
                scope="row"
                align="left"
                key={`row_${i}_cell_${j}`}
                className={props.classes.tableCell}
              >
                <Skeleton className={props.classes.tableCell}></Skeleton>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      {/* Loop through all of the row data in the current page */}
      {accounts.map((row, i) => (
        <TableRow key={`row_${i}`} hover className={props.classes.row}>
          <Tooltip title="View account data">
            <Button
              color="secondary"
              onClick={() => handleViewModal(row.accountID)}
              size="small"
            >
              View
            </Button>
          </Tooltip>
          {columns.map((col, j) => {
            let formattedValue = row[col.id]
              ? row[col.id]?.toString()
              : "\u2014";
            if (col.property_typeName === "Date") {
              try {
                formattedValue = new Date(row[col.id] as string)
                  .toISOString()
                  .split("T")[0];
              } catch (err) {
                // console.error(err);
              }
            }
            let tableBody = <Typography noWrap>{formattedValue}</Typography>;
            switch (col.id) {
              case "npsRating":
              case "previousNpsRating":
                // skip null values
                if (row[col.id] === null) break;
                let background;
                let color;
                const rating = Number(row[col.id]);
                if (rating >= 9) {
                  background = npsChipColors.green;
                  color = npsChipColors.darkGreen;
                } else if (rating >= 7) {
                  background = npsChipColors.yellow;
                  color = npsChipColors.darkYellow;
                } else {
                  background = npsChipColors.red;
                  color = npsChipColors.darkRed;
                }

                tableBody = (
                  <Chip label={row[col.id]} style={{ background, color }} />
                );
                break;
              case "npsScoreDifference":
              case "npsRatingDifference":
                // skip null values
                if (row[col.id] === null) break;
                const change = Number(row[col.id]);
                let changeColor;
                const gtz = change > 0;
                if (gtz) changeColor = npsChipColors.darkGreen;
                else if (change < 0) changeColor = npsChipColors.darkRed;
                tableBody = (
                  <Typography variant="body2" style={{ color: changeColor }}>
                    {gtz ? `+${change}` : change}
                  </Typography>
                );
                break;
            }
            return (
              <TableCell
                component={col.id === "accountName" ? "th" : "td"}
                scope="row"
                align="left"
                key={`row_${i}_cell_${j}`}
                className={props.classes.tableCell}
              >
                {tableBody}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
      <AccountModal
        onClose={handleModalClose}
        open={modalOpen}
        accountID={selected}
      />
    </>
  );
};

export default AccountTableBody;
